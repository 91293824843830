export default {
    1: "GeneralError",

    //data errors between 5000 to 5500
    5000: "InvalidModel",
    5010: "InvalidPasswordConfirmation",
    5060: "InvalidCredentials",
    5020: "InvalidLanguageCode",
    5030: "WrondDatesRange",
    5040: "InvalidMeterCount",
    5050: "InvalidEmail",
    5070: "InvalidResetToken",

    //////////////////////////////////////
    5450: "DataCollectorRegistrationError",
    5451: "DataCollectorAuthenicationError",
    5452: "DataCollectorLogError",

    //user account errors between 10000 and 10500
    10000: "LoginError",
    10002: "UserDoesNotExistsInCache",
    10010: "ParsingPhoneNumberError",
    10020: "InvalidPhoneNumber",
    10030: "ConsumerNotFound",
    10040: "ConsumerRegistered",
    10050: "ConsumerMunicipalDoesNotExists",
    10060: "ConsumerMetersNotFound",
    10070: "ConsumerRegistrationDetailsNotFoundInRedis",
    10080: "EmailInUseAlready",
    10090: "CannotCreateUser",
    10100: "WrongRegistrationCode",
    10110: "UnspportedClientType",
    10120: "PasswordNotMeetMinimalRequirements",
    10130: "UserNotRegistered",
    10140: "UnsupportedPhoneNumberType",
    10150: "CannotFindUserWithProvidedEmail",
    10160: "NoDataAvailable",
    10170: "CantRetrieveAccountNumber",
    10180: "PasswordExpired",
    10190: "ResetPasswordTokenExpired",
    10200: "UnableToResetPassword",
    10210: "PasswordIsEqualToOneOfPrevious",
    10220: "InactiveUser",
    10230: "RegistrationCodeWasNotValidated",
    10240: "AccountBlocked",
    /////////////////////////////////////////////

    10404: "DataNotFound",

    ////////////////////////////////////////////

    401: "UnauthorizedRequest",

    500: "InternalServerError"
}