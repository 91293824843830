import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import AlertTemplate from 'react-alert-template-basic';
import {BrowserRouter} from "react-router-dom";

const alertOptions = {
    offset: 30,
    position: 'top center',
    time: 5000,
    type: 'error',
    transition: 'scale',
    icon: <img src="images/logo_new.svg" />
};

class Root extends Component {
    render() {
        return (
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        )
    }
}


ReactDOM.render(<Root/>, document.getElementById('root'));
registerServiceWorker();
