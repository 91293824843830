import React from 'react';
import './PrivacyPolicy.css';
import images from "../../images";
import '../../App.css';
import {Desktop, Mobile} from "../../App";

export default class PrivacyPolicy extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Mobile>
                    {matches => {
                        if (matches)
                            return this._renderMobile();
                        else
                            return this._renderDesktop();
                    }}
                </Mobile>
            </div>
        );
    }

    _renderMobile() {
        return (
            <div style={{padding: 10}}>
                <button style={{float: 'right', backgroundColor: 'transparent'}} onClick={() => this.props.onClose()}>
                    <img src={images.icClose}/>
                </button>
                <p className={"privacyPolicyTitle"}>Privacy Policy</p>
                <div className={"applicationPrivacyStatement"} style={{marginTop: 30}}>Application Privacy Statement</div>
                <div style={{float: 'inherit', marginTop: 30}}>
                <textarea disabled style={{border: 0}} className={"privacyText"}>
                    {`
Effective as of 03.01.17


Master Meter, Inc. ("Master Meter"), owns this application named My Water Advisor (the "Application"). This Application Privacy Statement ("Privacy Statement") discloses Master Meter's privacy practices concerning the Application. The Privacy Statement is subject to and incorporated into the My Water Advisor Application End User License Agreement and Terms of Use. Master Meter desires to provide consumers with transparency and control over being tracked as they traverse the Internet. We do not track Internet behavior; rather we facilitate, through the Application, your ability to exert your control and choice whether or not to be tracked on your device. In order to help you exert your control and choice, we need to collect the information described in the section entitled Data Collected by Us.
This privacy policy has been compiled to better serve those who are concerned with how their 'Personally identifiable information' (PII) is being used online. PII, as used in US privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website. Please read this policy carefully to understand our policies and practices regarding your information and how we treat it. If you do not agree with our policies and practices, do not download, register with, or use this Application. By downloading, registering with, or using this Application, you agree to this privacy policy. This policy may change from time to time (see – link to “changes to our privacy policy”). Your continued use of this Application after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.

What this Privacy Statement Covers

This Privacy Policy tells you about Master Meter, Inc.'s policies and practices for the collection, use and disclosure of information we collect about you through My Water Advisor, including the website www.mywateradvisor.com. By using this Application, our Site and Services, you agree to the terms of this Privacy Policy and you consent to the processing of information about you as described here.

What personal information do we collect from the people that visit this Application?

Data Collected by Us. The Application collects the following information from your device: IP address (anonymized), device identifiers, mobile device operation system, information about your device's capabilities, browser type and browser identifier. In some instances it is possible that the tracking companies may use identification mechanisms that the Application is unable to collect. In that event, the tracking company may send the Application your unique tracking ID, in the form of a text string, which Master Meter does not process or use. Rather, this data is stored within the Application and is only passed back to the tracking company in the event that you execute an opt-out choice. When this is completed, the particular data is then removed from the Application memory. Master Meter also collects anonymous data from your device in order to know how many times the Application has been installed and opened, and how many opt-outs have been executed. This helps us analyze the Application so we can improve its functionality.

Collection and Use of Personal Information

Personal information is data that can be used to identify or contact a single person. You may be asked to provide your personal information anytime you use this Application or are in contact with Master Meter. Master Meter and its affiliates may share this personal information with each other and use it consistent with this Privacy Policy. They may also combine it with other information to provide and improve our products, services, content, and advertising. You are not required to provide the personal information that we have requested, but, if you chose not to do so, in many cases we will not be able to provide you with our products or services or respond to any queries you may have.

What personal information do we collect from the people that visit our blog, website or app?

When ordering or registering on our site, as appropriate, you may be asked to enter your email address, phone number or other details to help you with your experience.

Information you provide to us.

When you register, we ask you for one of the following pieces of information:
•	Account Number; or
•	Cell Phone number;

Collection and Use of Non-Personal Information

We also collect data in a form that does not, on its own, permit direct association with any specific individual. We may collect, use, transfer, and disclose non-personal information for any purpose. The following are some examples of non-personal information that we collect and how we may use it:
•	We may collect information such as occupation, language, zip code, area code, unique device identifier, referrer URL, location, and the time zone where an Apple product is used so that we can better understand customer behavior and improve our products, services, and advertising.
•	We may collect information regarding customer activities on our website. This information is aggregated and used to help us provide more useful information to our customers and to understand which parts of our website, products, and services are of most interest. Aggregated data is considered non-personal information for the purposes of this Privacy Policy.
•	We may collect and store details of how you use our services, including search queries. This information may be used to improve the relevancy of results provided by our services. Except in limited instances to ensure quality of our services over the Internet, such information will not be associated with your IP address.

Do we use 'cookies'?

We do not use cookies for tracking purposes
You can choose to have your device or computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser (like Internet Explorer) settings. Each browser is a little different, so look at your browser's Help menu to learn the correct way to modify your cookies.
If you disable cookies, some features will be disabled that make your site experience more efficient and some of our services may not function properly.

Storage and Usage of Data

All the collected data is stored locally, meaning on the device, and is only sent by us to the tracking company at your direction to alert that that you have chosen to opt-out of being tracked by them. When you close the Application, Master Meter clears all the data, except for a record of companies that you have elected to opt-out from. The only exception to this practice is for IDs that can be reset by you, such as IDFA (Identifier For Advertising), which is a cross app/publisher, in iOS. In those cases, Master Meter stores the ID locally on the device in an anonymous format. Each time you open the Application, Master Meter checks to see if you reset your ID on your device. If the ID has been reset, then the Application will indicate to you that you will need to opt-out again.

Sharing Your Information. Except as disclosed in this Privacy Statement, Master Meter does not rent, sell or share the information you have allowed us to collect with third parties without your permission... We may share your information without your permission (i) pursuant to judicial or other government subpoenas, warrants, or orders or otherwise to comply with law; (ii) where Master Meter believes the rights, property or an individual's safety or security is at risk; (iii) if Master Meter finds that your actions violate our Terms of Use Agreement; or (iv) where otherwise required by law.

Retention of Information

Subject to the disclosures in this Privacy Statement and applicable law, we will retain your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.

Children's Privacy

Master Meter is committed to protecting the safety and privacy of young people using the Internet. Our services, products and websites are not directed at children under the age of 13 and we do not knowingly collect personal information from children under the age of 13.

COPPA (Children Online Privacy Protection Act)

When it comes to the collection of personal information from children under 13, the Children's Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, the nation's consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children's privacy and safety online.

We do not specifically market to children under 13.

California Online Privacy Protection Act

CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law's reach stretches well beyond California to require a person or company in the United States (and conceivably the world) that operates websites collecting personally identifiable information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals with whom it is being shared, and to comply with this policy. - See more at: http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf

According to CalOPPA we agree to the following:

Users can visit our site anonymously

Once this privacy policy is created, we will add a link to it on our home page, or as a minimum on the first significant page after entering our website.

Our Privacy Policy link includes the word 'Privacy', and can be easily be found on the page specified above.

Users will be notified of any privacy policy changes:
•	On our Privacy Policy Page
Users are able to change their personal information:
•	By logging in to their account

Our Commitment to Security

Master Meter has appropriate physical, administrative and technical safeguards procedures in place, which are designed to protect and safeguard your data. Of course, although Master Meter uses standard industry practices to protect and safeguard your data, we cannot guarantee that your communications with Master Meter or your data will never be unlawfully intercepted, or that your data will never be unlawfully accessed by unauthorized third parties.

While Master Meter has implemented these security measures to secure your personal information from accidental loss and from unauthorized use, access, alteration and disclosure, the safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to our Application, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.

Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted through our Application. Any transmission of personal information is at your own risk. We are not responsible for any circumvention of any privacy settings or security measures we provide.

How do we protect visitor information?

Our website is scanned on a regular basis for security holes and known vulnerabilities in order to make your visit to our site as safe as possible.

We do not use an SSL certificate
•	We do not need an SSL because:
We do not send/receive sensitive information, only alerts for water leaks, Electronic correspondence from the Utility, and customer initiated settings and preference modifications.

Third Party Disclosure

We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. Master Meter may disclose aggregated information about users, and information that does not identify any individual or device without restriction

Third party links

We do not include or offer third party products or services on our website.

How does our site handle do not track signals?

We honor do not track signals and do not track, plant cookies, or use advertising when a Do Not Track (DNT) browser mechanism is in place.

Does our site allow third party behavioral tracking?

It's also important to note that we do not allow third party behavioral tracking

Changes to the Privacy Statement

Master Meter reserves the right to modify the Privacy Statement as business needs require. If we decide to change the Privacy Statement, we will post those changes here so that you will always know what information we gather, how we might use that information and whether we will disclose it to anyone. All changes will be posted on www.mywateradvisor.com and on the Application at the time they take effect. Contact Information.

Contacting Us

If there are any questions regarding this privacy policy you may contact us using the information below.
www.mastermeter.com
Mansfield
USA
TX

MM rev 01.17`}
                </textarea>
                </div>
            </div>
        );
    }


    _renderDesktop() {
        return (
            <div style={{height: 500, padding: 10}}>
                <button style={{float: 'right', backgroundColor: 'transparent'}} onClick={() => this.props.onClose()}>
                    <img src={images.icClose}/>
                </button>
                <p className={"privacyPolicyTitle"}>Privacy Policy</p>
                <div className={"applicationPrivacyStatement"} style={{marginTop: 30}}>Application Privacy Statement</div>
                <div style={{float: 'inherit'}}>
                <textarea disabled style={{border: 0}} className={"termsText"}>
                    {`Effective as of 03.01.17


Master Meter, Inc. ("Master Meter"), owns this application named My Water Advisor (the "Application"). This Application Privacy Statement ("Privacy Statement") discloses Master Meter's privacy practices concerning the Application. The Privacy Statement is subject to and incorporated into the My Water Advisor Application End User License Agreement and Terms of Use. Master Meter desires to provide consumers with transparency and control over being tracked as they traverse the Internet. We do not track Internet behavior; rather we facilitate, through the Application, your ability to exert your control and choice whether or not to be tracked on your device. In order to help you exert your control and choice, we need to collect the information described in the section entitled Data Collected by Us.
This privacy policy has been compiled to better serve those who are concerned with how their 'Personally identifiable information' (PII) is being used online. PII, as used in US privacy law and information security, is information that can be used on its own or with other information to identify, contact, or locate a single person, or to identify an individual in context. Please read our privacy policy carefully to get a clear understanding of how we collect, use, protect or otherwise handle your Personally Identifiable Information in accordance with our website. Please read this policy carefully to understand our policies and practices regarding your information and how we treat it. If you do not agree with our policies and practices, do not download, register with, or use this Application. By downloading, registering with, or using this Application, you agree to this privacy policy. This policy may change from time to time (see – link to “changes to our privacy policy”). Your continued use of this Application after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.

What this Privacy Statement Covers

This Privacy Policy tells you about Master Meter, Inc.'s policies and practices for the collection, use and disclosure of information we collect about you through My Water Advisor, including the website www.mywateradvisor.com. By using this Application, our Site and Services, you agree to the terms of this Privacy Policy and you consent to the processing of information about you as described here.

What personal information do we collect from the people that visit this Application?

Data Collected by Us. The Application collects the following information from your device: IP address (anonymized), device identifiers, mobile device operation system, information about your device's capabilities, browser type and browser identifier. In some instances it is possible that the tracking companies may use identification mechanisms that the Application is unable to collect. In that event, the tracking company may send the Application your unique tracking ID, in the form of a text string, which Master Meter does not process or use. Rather, this data is stored within the Application and is only passed back to the tracking company in the event that you execute an opt-out choice. When this is completed, the particular data is then removed from the Application memory. Master Meter also collects anonymous data from your device in order to know how many times the Application has been installed and opened, and how many opt-outs have been executed. This helps us analyze the Application so we can improve its functionality.

Collection and Use of Personal Information

Personal information is data that can be used to identify or contact a single person. You may be asked to provide your personal information anytime you use this Application or are in contact with Master Meter. Master Meter and its affiliates may share this personal information with each other and use it consistent with this Privacy Policy. They may also combine it with other information to provide and improve our products, services, content, and advertising. You are not required to provide the personal information that we have requested, but, if you chose not to do so, in many cases we will not be able to provide you with our products or services or respond to any queries you may have.

What personal information do we collect from the people that visit our blog, website or app?

When ordering or registering on our site, as appropriate, you may be asked to enter your email address, phone number or other details to help you with your experience.

Information you provide to us.

When you register, we ask you for one of the following pieces of information:
•	Account Number; or
•	Cell Phone number;

Collection and Use of Non-Personal Information

We also collect data in a form that does not, on its own, permit direct association with any specific individual. We may collect, use, transfer, and disclose non-personal information for any purpose. The following are some examples of non-personal information that we collect and how we may use it:
•	We may collect information such as occupation, language, zip code, area code, unique device identifier, referrer URL, location, and the time zone where an Apple product is used so that we can better understand customer behavior and improve our products, services, and advertising.
•	We may collect information regarding customer activities on our website. This information is aggregated and used to help us provide more useful information to our customers and to understand which parts of our website, products, and services are of most interest. Aggregated data is considered non-personal information for the purposes of this Privacy Policy.
•	We may collect and store details of how you use our services, including search queries. This information may be used to improve the relevancy of results provided by our services. Except in limited instances to ensure quality of our services over the Internet, such information will not be associated with your IP address.

Do we use 'cookies'?

We do not use cookies for tracking purposes
You can choose to have your device or computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser (like Internet Explorer) settings. Each browser is a little different, so look at your browser's Help menu to learn the correct way to modify your cookies.
If you disable cookies, some features will be disabled that make your site experience more efficient and some of our services may not function properly.

Storage and Usage of Data

All the collected data is stored locally, meaning on the device, and is only sent by us to the tracking company at your direction to alert that that you have chosen to opt-out of being tracked by them. When you close the Application, Master Meter clears all the data, except for a record of companies that you have elected to opt-out from. The only exception to this practice is for IDs that can be reset by you, such as IDFA (Identifier For Advertising), which is a cross app/publisher, in iOS. In those cases, Master Meter stores the ID locally on the device in an anonymous format. Each time you open the Application, Master Meter checks to see if you reset your ID on your device. If the ID has been reset, then the Application will indicate to you that you will need to opt-out again.

Sharing Your Information. Except as disclosed in this Privacy Statement, Master Meter does not rent, sell or share the information you have allowed us to collect with third parties without your permission... We may share your information without your permission (i) pursuant to judicial or other government subpoenas, warrants, or orders or otherwise to comply with law; (ii) where Master Meter believes the rights, property or an individual's safety or security is at risk; (iii) if Master Meter finds that your actions violate our Terms of Use Agreement; or (iv) where otherwise required by law.

Retention of Information

Subject to the disclosures in this Privacy Statement and applicable law, we will retain your information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.

Children's Privacy

Master Meter is committed to protecting the safety and privacy of young people using the Internet. Our services, products and websites are not directed at children under the age of 13 and we do not knowingly collect personal information from children under the age of 13.

COPPA (Children Online Privacy Protection Act)

When it comes to the collection of personal information from children under 13, the Children's Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, the nation's consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children's privacy and safety online.

We do not specifically market to children under 13.

California Online Privacy Protection Act

CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law's reach stretches well beyond California to require a person or company in the United States (and conceivably the world) that operates websites collecting personally identifiable information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals with whom it is being shared, and to comply with this policy. - See more at: http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf

According to CalOPPA we agree to the following:

Users can visit our site anonymously

Once this privacy policy is created, we will add a link to it on our home page, or as a minimum on the first significant page after entering our website.

Our Privacy Policy link includes the word 'Privacy', and can be easily be found on the page specified above.

Users will be notified of any privacy policy changes:
•	On our Privacy Policy Page
Users are able to change their personal information:
•	By logging in to their account

Our Commitment to Security

Master Meter has appropriate physical, administrative and technical safeguards procedures in place, which are designed to protect and safeguard your data. Of course, although Master Meter uses standard industry practices to protect and safeguard your data, we cannot guarantee that your communications with Master Meter or your data will never be unlawfully intercepted, or that your data will never be unlawfully accessed by unauthorized third parties.

While Master Meter has implemented these security measures to secure your personal information from accidental loss and from unauthorized use, access, alteration and disclosure, the safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to our Application, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.

Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted through our Application. Any transmission of personal information is at your own risk. We are not responsible for any circumvention of any privacy settings or security measures we provide.

How do we protect visitor information?

Our website is scanned on a regular basis for security holes and known vulnerabilities in order to make your visit to our site as safe as possible.

We do not use an SSL certificate
•	We do not need an SSL because:
We do not send/receive sensitive information, only alerts for water leaks, Electronic correspondence from the Utility, and customer initiated settings and preference modifications.

Third Party Disclosure

We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information. Master Meter may disclose aggregated information about users, and information that does not identify any individual or device without restriction

Third party links

We do not include or offer third party products or services on our website.

How does our site handle do not track signals?

We honor do not track signals and do not track, plant cookies, or use advertising when a Do Not Track (DNT) browser mechanism is in place.

Does our site allow third party behavioral tracking?

It's also important to note that we do not allow third party behavioral tracking

Changes to the Privacy Statement

Master Meter reserves the right to modify the Privacy Statement as business needs require. If we decide to change the Privacy Statement, we will post those changes here so that you will always know what information we gather, how we might use that information and whether we will disclose it to anyone. All changes will be posted on www.mywateradvisor.com and on the Application at the time they take effect. Contact Information.

Contacting Us

If there are any questions regarding this privacy policy you may contact us using the information below.
www.mastermeter.com
Mansfield
USA
TX

MM rev 01.17`}
                </textarea>
                </div>
            </div>
        );
    }
}