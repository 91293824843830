import React from 'react';
import './Settings.css';
import {Desktop, Mobile} from "../../App";
import Toggle from 'react-toggle';
import * as api from "../../api";
import {AlertType} from "../../utils";
import DataManager from "../../managers/DataManager";
import images from "../../images";
import globals from "../../globals";
import {Link} from "react-router-dom";

export default class Settings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            alertSettings: [[false, false], [false, false], [false, false]],
            user: DataManager.sharedInstance.user,
            // user: {firstName: 'Steve', lastName: 'Davis', accountNumber: '1234567'},
            meters: DataManager.sharedInstance.meters
        };

        this.toggleSwitch = this.toggleSwitch.bind(this);
    }

    componentWillMount() {
        api.getMeters((res, err) => {
            if (err) {
                console.log('Unknown error has occurred.');
            } else {
                if (res.error) {
                    console.log(res.error);
                } else {
                    DataManager.sharedInstance.meters = this.meters;
                    this.setState({meters: res});
                }
            }
        });

        api.getAlertSettings((res, err) => {
            //this.setState({isLoading: false});
            if (err) {
                console.log('Unknown error has occurred.');
            } else {
                if (res.error) {
                    console.log(res.error);
                } else {
                    if (res.length > 0) {
                        let alertSettings = this.state.alertSettings;
                        res.map(({alertTypeId, mediaTypeId}) => {
                            switch (alertTypeId) {
                                case AlertType.SUSPECTED_LEAK: {
                                    alertSettings[0][mediaTypeId === 1 ? 0 : 1] = true;
                                    break;
                                }
                                case AlertType.MONTHLY_CONS_LIMIT: {
                                    alertSettings[1][mediaTypeId === 1 ? 0 : 1] = true;
                                    break;
                                }
                                case AlertType.DAILY_USAGE_VACATION: {
                                    alertSettings[2][mediaTypeId === 1 ? 0 : 1] = true;
                                    break;
                                }
                            }
                        });

                        this.setState({alertSettings});
                    }
                }
            }
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        console.log('here');
        this.setState({user: DataManager.sharedInstance.user});
    }

    render() {
        return (
            <div>
                <Mobile>
                    {matches => {
                        if (matches)
                            return this._renderMobile();
                        else
                            return this._renderDesktop();
                    }}
                </Mobile>
            </div>
        );
    }

    _renderDesktop() {
        return (
            <div style={{display: 'flex'}}>
                <div style={{
                    display: 'flex',
                    flex: 5,
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    padding: 40
                }}>
                    <div className={"title"}>Set Real-Time Alerts according to your preferences</div>
                    <div style={{marginTop: 30, display: 'flex', flexDirection: 'row', width: '100%'}}>
                        <div style={{flexDirection: 'column', display: 'flex', flex: 3}}>
                            <div className={"alertTitle"} style={{paddingTop: 4, paddingLeft: 20}}>Alerts</div>
                            <div className={"alertType"} style={{marginTop: 20, paddingLeft: 20}}>Suspected Leak</div>
                            <div className={"alertType"} style={{marginTop: 8, paddingLeft: 20}}>Billing Cycle Consumption Limit</div>
                            <div className={"alertType"} style={{marginTop: 8, paddingLeft: 20}}>Daily Usage During Vacation
                            </div>
                        </div>
                        <div style={{flexDirection: 'column', display: 'flex', flex: 1}}>
                            <div className={"alertTitle"} style={{paddingTop: 4}}>Email alert</div>
                            <div style={{marginTop: 16}}>
                                <Toggle icons={false} checked={this.state.alertSettings[0][0]}
                                        onChange={() => this.toggleSwitch(0, 0)}/>
                            </div>
                            <div style={{marginTop: 0}}>
                                <Toggle icons={false} checked={this.state.alertSettings[1][0]}
                                        onChange={() => this.toggleSwitch(1, 0)}/>
                            </div>
                            <div style={{marginTop: 0}}>
                                <Toggle icons={false} checked={this.state.alertSettings[2][0]}
                                        onChange={() => this.toggleSwitch(2, 0)}/>
                            </div>
                        </div>
                        <div style={{flexDirection: 'column', display: 'flex', flex: 1}}>
                            <div className={"alertTitle"} style={{paddingTop: 4}}>SMS alert</div>
                            <div style={{marginTop: 16}}>
                                <Toggle icons={false} checked={this.state.alertSettings[0][1]}
                                        onChange={() => this.toggleSwitch(0, 1)} disabled={!this.state?.user?.phoneNumber?.phoneNumber} />
                            </div>
                            <div style={{marginTop: 0}}>
                                <Toggle icons={false} checked={this.state.alertSettings[1][1]}
                                        onChange={() => this.toggleSwitch(1, 1)} disabled={!this.state?.user?.phoneNumber?.phoneNumber} />
                            </div>
                            <div style={{marginTop: 0}}>
                                <Toggle icons={false} checked={this.state.alertSettings[2][1]}
                                        onChange={() => this.toggleSwitch(2, 1)} style={{marginTop: 8}} disabled={!this.state?.user?.phoneNumber?.phoneNumber} />
                            </div>
                        </div>
                    </div>
                    <p className={"thinSep"} style={{marginTop: 30}}/>
                    <div style={{marginTop: 20}}>
                        <div style={{flexDirection: 'row', display: 'flex', paddingLeft: 20}}>
                            <img src={images.icBigGauge}/>
                            <div className={"myWaterMeters"} style={{marginLeft: 20}}>My Water Meters</div>
                        </div>
                        <div style={{marginTop: 10, paddingLeft: 20, height: this.state.meters ? 45 * this.state.meters.length : 45}}>
                            {this.state.meters && this.state.meters.map(item => {
                                console.log('this.state.meters', this.state.meters);
                                return <div
                                    style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <p className={"waterMeterItem"}>{item.meterSn}</p>
                                    <p className={"waterMeterItem"}
                                    >{item.fullAddress ? item.fullAddress : globals.METER_DEFAULT_ADDRESS}</p>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
                <div style={{
                    display: 'flex',
                    flex: 4,
                    marginTop: 80,
                    padding: 20,
                }}>
                    <div className={"accountBox"}
                         style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{display: 'flex', flexDirection: 'row', padding: 20}}>
                            <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
                                <div className={"accountKey"}>Name</div>
                                <div className={"accountKey"} style={{marginTop: 8}}>Account Number</div>
                                <div className={"accountKey"} style={{marginTop: 8}}>Phone Number</div>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', flex: 2}}>
                                <div
                                    className={"accountValue"}>{this.state.user.firstName} {this.state.user.lastName}</div>
                                <div className={"accountValue"}
                                     style={{marginTop: 8}}>{this.state.user.accountNumber}</div>
                                <div className={"accountValue"}
                                     style={{marginTop: 8}}>{this.state.user.phoneNumber ? `${this.state.user.phoneNumber.countryCode}-${this.state.user.phoneNumber.phoneNumber}` : ''}</div>
                            </div>
                            <div style={{display: 'flex', flexDirection: 'column', flex: 1, marginLeft: 20}}>
                                <div className={"accountKey"} style={{opacity: 0}}>a</div>
                                <div className={"accountKey"} style={{opacity: 0, marginTop: 8}}>a</div>
                                <a onClick={this.openChangePhone.bind(this)} style={{marginTop: 8}}>Edit</a>
                            </div>
                        </div>
                        <button style={{
                            marginTop: 20,
                            backgroundColor: 'transparent',
                            alignSelf: 'flex-start',
                            marginLeft: -8
                        }}>
                            <img src={images.btnChangePassword} className={"btnPassword"}
                                 onClick={this.openChangePass.bind(this)}/>
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    _renderMobile() {
        return (
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <h1 className={"title"} style={{fontSize: 17, flexWrap: 'wrap'}}>Set Real-Time Alerts according to your
                    preferences</h1>
                <div style={{marginTop: 10, display: 'flex', flexDirection: 'row', width: '100%'}}>
                    <div style={{flexDirection: 'column', display: 'flex', flex: 1}}>
                        <div className={"alertTitle"} style={{paddingTop: 4, paddingLeft: 20, fontSize: 14}}>Alerts
                        </div>
                        <div className={"alertType"} style={{marginTop: 20, paddingLeft: 20, fontSize: 14}}>Suspected
                            Leak
                        </div>
                        <div className={"alertType"} style={{marginTop: 8, paddingLeft: 20, fontSize: 14}}>Monthly
                            Consumption
                            Limit
                        </div>
                        <div className={"alertType"} style={{marginTop: 8, paddingLeft: 20, fontSize: 14}}>Daily Usage
                            Vacation
                        </div>
                    </div>
                    <div style={{flexDirection: 'column', display: 'flex', flex: 1}}>
                        <div className={"alertTitle"} style={{paddingTop: 4, fontSize: 14}}>Email alert</div>
                        <div style={{marginTop: 16}}>
                            <Toggle icons={false} checked={this.state.alertSettings[0][0]}
                                    onChange={() => this.toggleSwitch(0, 0)}/>
                        </div>
                        <div style={{marginTop: 16}}>
                            <Toggle icons={false} checked={this.state.alertSettings[1][0]}
                                    onChange={() => this.toggleSwitch(1, 0)}/>
                        </div>
                        <div style={{marginTop: 16}}>
                            <Toggle icons={false} checked={this.state.alertSettings[2][0]}
                                    onChange={() => this.toggleSwitch(2, 0)}/>
                        </div>
                    </div>
                    <div style={{flexDirection: 'column', display: 'flex', flex: 1}}>
                        <div className={"alertTitle"} style={{paddingTop: 4, fontSize: 14}}>SMS alert</div>
                        <div style={{marginTop: 16}}>
                            <Toggle icons={false} checked={this.state.alertSettings[0][1]}
                                    onChange={() => this.toggleSwitch(0, 1)}/>
                        </div>
                        <div style={{marginTop: 16}}>
                            <Toggle icons={false} checked={this.state.alertSettings[1][1]}
                                    onChange={() => this.toggleSwitch(1, 1)}/>
                        </div>
                        <div style={{marginTop: 16}}>
                            <Toggle icons={false} checked={this.state.alertSettings[2][1]}
                                    onChange={() => this.toggleSwitch(2, 1)} style={{marginTop: 8}}/>
                        </div>
                    </div>
                </div>
                <p className={"thinSep"} style={{marginTop: 10}}/>
                <div style={{marginTop: 0}}>
                    <div style={{flexDirection: 'row', display: 'flex', paddingLeft: 20}}>
                        <img src={images.icBigGauge}/>
                        <div className="myWaterMeters" style={{marginLeft: 20}}>My Water Meters</div>
                    </div>
                    <div style={{marginTop: 10, paddingLeft: 20}}>
                        {this.state.meters.map(item => {
                            return <div
                                style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <div className="waterMeterItem">{item.meterSn}</div>
                                <div className="waterMeterItem"
                                >{item.fullAddress ? item.fullAddress : globals.METER_DEFAULT_ADDRESS}</div>
                            </div>
                        })}
                    </div>
                </div>
                <div className="accountBox"
                     style={{display: 'flex', flexDirection: 'column'}}>
                    <div style={{display: 'flex', flexDirection: 'row', padding: 20}}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div className={"accountKeyMobile"}>Name</div>
                            <div className={"accountKeyMobile"} style={{marginTop: 8}}>Account Number</div>
                            <div className={"accountKeyMobile"} style={{marginTop: 8}}>Phone Number</div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', marginLeft: 10}}>
                            <div
                                className={"accountValue"}>{this.state.user.firstName} {this.state.user.lastName}</div>
                            <div className={"accountValue"}
                                 style={{marginTop: 8}}>{this.state.user.accountNumber}</div>
                            <div className={"accountValue"}
                                 style={{marginTop: 8}}>{this.state.user.phoneNumber ? `${this.state.user.phoneNumber.countryCode}-${this.state.user.phoneNumber.phoneNumber}` : ''}</div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                            <div className={"accountKeyMobile"} style={{opacity: 0}}>a</div>
                            <div className={"accountKeyMobile"} style={{opacity: 0, marginTop: 8}}>a</div>
                            <a onClick={this.openChangePhone.bind(this)} style={{marginTop: 8}}>Edit</a>
                        </div>
                    </div>
                    <button style={{
                        backgroundColor: 'transparent',
                        alignSelf: 'center',
                        marginTop: -25
                    }}>
                        <img src={images.btnChangePassword} className={"btnPassword"}
                             onClick={this.openChangePass.bind(this)}/>
                    </button>
                </div>
            </div>
        );
    }

    openChangePhone = () => {
        this.props.onOpenChangePhone();
    };

    openChangePass = () => {
        this.props.onOpenChangePass();
    };

    toggleSwitch = (sectionIndex, index) => {
        const value = !this.state.alertSettings[sectionIndex][index];
        const alertSettings = this.state.alertSettings;
        alertSettings[sectionIndex][index] = value;
        this.setState({alertSettings});

        let alertTypeId;
        switch (sectionIndex) {
            case 0:
                alertTypeId = AlertType.SUSPECTED_LEAK;
                break;
            case 1:
                alertTypeId = AlertType.MONTHLY_CONS_LIMIT;
                break;
            case 2:
                alertTypeId = AlertType.DAILY_USAGE_VACATION;
                break;
        }

        const mediaTypeId = index === 0 ? 1 : 3;
        if (value) {
            api.setAlert(alertTypeId, mediaTypeId, (res, err) => {

            });
        } else {
            api.unsetAlert(alertTypeId, mediaTypeId, (res, err) => {

            });
        }

    };
}
