import React from 'react';
import images from '../../images';
import '../../App.css';
import './RegisterAccount.css';
import Input from 'react-validation/build/input';
import Form from 'react-validation/build/form';
import Button from 'react-validation/build/button';
import * as validators from '../../validators';
import * as api from '../../api';
import DataManager from '../../managers/DataManager';
import { Desktop, Mobile } from '../../App';
import Popup from "reactjs-popup";
import AppPopup from "../../components/AppPopup";

class RegisterAccount extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fullName: '',
            account: '',
            errorMessage: null
        };
    }

    render() {
        return (
            <div>
                <Mobile>
                    {matches => {
                        if (matches) return this._renderMobile();
                        else return this._renderDesktop();
                    }}
                </Mobile>
            </div>
        );
    }

    _renderMobile() {
        return (
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginLeft: 20,
                    marginRight: 20,
                    marginTop: -36
                }}
            >
                <h1 className={'title'} style={{ fontSize: 17 }}>
                    Join My Water Advisor
                </h1>
                <Form method={'post'} onSubmit={this.onSubmit.bind(this)}>
                    <div style={{ margin: 10 }}>
                        <h1
                            className="inputTitleMobile"
                            style={{ marginLeft: 20, marginRight: 20 }}
                        >
                            ACCOUNT NUMBER AS IT APPEARS ON YOUR BILL
                        </h1>
                        <Input
                            validations={[validators.required]}
                            placeholder="Account number"
                            type="text"
                            className="rectangle rectangleMobile"
                            name="account"
                            onChange={event =>
                                this.setState({ account: event.target.value })
                            }
                            value={this.state.account}
                            style={{
                                paddingLeft: 10,
                                paddingRight: 10,
                                display: 'flex',
                                fontSize: 16,
                                marginLeft: 20,
                                marginRight: 20,
                                marginTop: 20
                            }}
                        />
                    </div>
                    <div style={{ margin: 10, marginTop: 20 }}>
                        <h1
                            className="inputTitleMobile"
                            style={{ marginLeft: 20, marginRight: 20 }}
                        >
                            FULL NAME AS IT APPEARS ON YOUR BILL
                        </h1>
                        <Input
                            validations={[validators.required]}
                            placeholder="Your full name"
                            type="text"
                            className="rectangle rectangleMobile"
                            name="fullName"
                            value={this.state.fullName}
                            onChange={event =>
                                this.setState({ fullName: event.target.value })
                            }
                            style={{
                                paddingLeft: 10,
                                paddingRight: 10,
                                display: 'flex',
                                fontSize: 16,
                                marginLeft: 20,
                                marginRight: 20
                            }}
                        />
                    </div>
                    <div
                        style={{
                            margin: 10,
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <Button
                            type="submit"
                            style={{
                                border: 0,
                                backgroundColor: 'transparent'
                            }}
                        >
                            <img
                                src={images.btnContinue}
                                className="btnContinue"
                            />
                        </Button>
                    </div>
                </Form>
                <Popup
                    contentStyle={{borderRadius: 5}}
                    open={this.state.errorMessage}
                    onClose={this.closeErrorPopup.bind(this)}
                    lockScroll={true}
                >
                    <AppPopup onClose={this.closeErrorPopup.bind(this)} message={this.state.errorMessage}/>
                </Popup>
            </div>
        );
    }

    _renderDesktop() {
        return (
            <div
                style={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: 20
                }}
            >
                <h1 className={'title'}>Join My Water Advisor</h1>
                <Form method={'post'} onSubmit={this.onSubmit.bind(this)}>
                    <div style={{ marginTop: 20 }}>
                        <h1 className={'inputTitle'}>
                            ACCOUNT NUMBER AS IT APPEARS ON YOUR BILL
                        </h1>
                        <Input
                            validations={[validators.required]}
                            placeholder="Account number"
                            type="text"
                            className="rectangle"
                            name="account"
                            value={this.state.account}
                            onChange={event =>
                                this.setState({ account: event.target.value })
                            }
                            style={{
                                paddingLeft: 10,
                                paddingRight: 10,
                                display: 'flex',
                                fontSize: 16
                            }}
                        />
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <h1 className={'inputTitle'}>
                            FULL NAME AS IT APPEARS ON YOUR BILL
                        </h1>
                        <Input
                            validations={[validators.required]}
                            placeholder="Your full name"
                            type="text"
                            className="rectangle"
                            name="fullName"
                            value={this.state.fullName}
                            onChange={event =>
                                this.setState({ fullName: event.target.value })
                            }
                            style={{
                                paddingLeft: 10,
                                paddingRight: 10,
                                display: 'flex',
                                fontSize: 16
                            }}
                        />
                    </div>
                    <div
                        style={{
                            marginTop: 20,
                            display: 'flex',
                            justifyContent: 'center'
                        }}
                    >
                        <Button
                            type="submit"
                            style={{
                                border: 0,
                                backgroundColor: 'transparent'
                            }}
                        >
                            <img
                                src={images.btnContinue}
                                className="btnContinue"
                            />
                        </Button>
                    </div>
                </Form>
                <Popup
                    contentStyle={{borderRadius: 5}}
                    open={this.state.errorMessage}
                    onClose={this.closeErrorPopup.bind(this)}
                    lockScroll={true}
                >
                    <AppPopup onClose={this.closeErrorPopup.bind(this)} message={this.state.errorMessage}/>
                </Popup>
            </div>
        );
    }

    closeErrorPopup() {
        this.setState({errorMessage: null});
    }

    onSubmit = event => {
        event.preventDefault();

        //this.setState({isLoading: true});
        api.registerAccount(
            this.state.fullName,
            this.state.account,
            (response, error) => {
                //this.setState({isLoading: false});
                if (error) {
                    this.setState({errorMessage: 'Unknown error has occurred.'});
                } else {
                    if (response.error) {
                        //Error
                        if (response.code === 10030) {
                            this.props.history.push(
                                '/register/account/not_found'
                            );
                        } else {
                            this.setState({errorMessage: response.error});
                        }
                    } else {
                        DataManager.sharedInstance.sessionId =
                            response.sessionId;
                        this.props.history.push('/register/finish');
                    }
                }
            }
        );
    };
}

export default (RegisterAccount)