import React from 'react';
import images from '../../images';
import './LandingPage.css';
import { Link } from 'react-router-dom';
import globals from '../../globals';
import {Mobile, MobileLand} from '../../App';

export default class LandingPage extends React.Component {
    constructor(props) {
        super(props);
        this.isLandscape = false;
    }

    componentWillMount() {
        this.props.toggleHeader(false);
    }

    render() {
        return (
            <div>
                <MobileLand>
                    {matches => {
                        if (matches) {
                            this.isLandscape = true;
                            return this._renderMobile();
                        } else {
                            return <Mobile>
                                {matches => {
                                    if (matches) {
                                        this.isLandscape = false;
                                        return this._renderMobile();
                                    } else return this._renderDesktop();
                                }}
                            </Mobile>
                        }
                    }}
                </MobileLand>
            </div>
        );
    }

    _renderMobile() {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    marginTop: 30
                }}
            >
                <img
                    style={{ position: 'absolute', left: 0, right: 0, top: 0 }}
                    src={images.lpTopImageMob}
                    className="lpBgTopMob"
                />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        position: 'absolute'
                    }}
                >
                    <img
                        className="logoMWAHeaderMob"
                        src={images.logo}
                        width={50}
                        height={50}
                    />
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                        <div className="headerTitleLabel" style={{margin: 0, padding: 0}}>My Water Advisor <div style={{display: 'inline', fontSize: 16, position: 'relative', top: -7}}>®</div></div>
                        <div
                            className="headerSubtitleLabelMob"
                            style={{ margin: 0, marginTop: 4, padding: 0 }}
                        >
                            Online access to Your World of Water™
                        </div>
                    </div>
                </div>
                <img
                    src={images.lpImageMob}
                    className="lpBigImageMob"
                    style={{ marginTop: 100, zIndex: 1 }}
                />
                <div className="lpMonitorLabelMob" style={{ color: '#3c63d7' }}>
                    Monitor your water consumption with real time data, alerts
                    and analytics
                </div>
                <div
                    style={{
                        marginLeft: -10,
                        marginTop: this.isLandscape ? 140 :  -20,
                        flexDirection: 'row',
                        display: 'flex',
                        alignItems: 'center',
                        alignSelf: 'center',
                        zIndex: 1
                    }}
                >
                    <Link
                        to={'/register'}
                        style={{
                            backgroundColor: 'transparent',
                            alignItems: 'center'
                        }}
                    >
                        <img className="lpButton" src={images.btnJoinMob} />
                    </Link>
                    <Link
                        to={'/login'}
                        style={{
                            backgroundColor: 'transparent',
                            alignItems: 'center',
                            marginTop: -20
                        }}
                    >
                        <img
                            className="lpButton"
                            src={images.btnLoginWhiteMob}
                        />
                    </Link>
                </div>
                <div
                    style={{
                        flexDirection: 'column',
                        display: 'flex',
                        alignItems: 'center'
                    }}
                >
                    <img
                        src={images.icEmpowerment}
                        className="lpImage"
                        style={{ width: 30, height: 30 }}
                    />
                    <div className="lpButtonTitle" style={{ marginTop: 10 }}>
                        Empowerment
                    </div>
                    <div className="lpButtonSubTitle" style={{ marginTop: 10 }}>
                        Knowledge of how and when we use water empower’s
                        conservation
                    </div>
                </div>
                <div
                    style={{
                        flexDirection: 'column',
                        alignItems: 'center',
                        display: 'flex',
                        marginTop: 20
                    }}
                >
                    <img
                        src={images.icClarity}
                        className="lpImage"
                        style={{ width: 30, height: 30 }}
                    />
                    <div className="lpButtonTitle" style={{ marginTop: 10 }}>
                        Clarity
                    </div>
                    <div className="lpButtonSubTitle" style={{ marginTop: 10 }}>
                        Better understand how much water is used for gardening
                        or other activities
                    </div>
                </div>
                <div
                    style={{
                        flexDirection: 'column',
                        alignItems: 'center',
                        display: 'flex',
                        marginTop: 20
                    }}
                >
                    <img
                        src={images.icContext}
                        className="lpImage"
                        style={{ width: 30, height: 30 }}
                    />
                    <div className="lpButtonTitle" style={{ marginTop: 10 }}>
                        Context
                    </div>
                    <div className="lpButtonSubTitle" style={{ marginTop: 10 }}>
                        Compare ones usage against similar households for a
                        more effective conservation program
                    </div>
                </div>
                <div
                    style={{
                        flexDirection: 'column',
                        alignItems: 'center',
                        display: 'flex',
                        marginTop: 20
                    }}
                >
                    <img
                        src={images.icSafety}
                        className="lpImage"
                        style={{ width: 30, height: 30 }}
                    />
                    <div className="lpButtonTitle" style={{ marginTop: 10 }}>
                        Safety
                    </div>
                    <div className="lpButtonSubTitle" style={{ marginTop: 10 }}>
                        Monitor unexpected or unauthorized usage while away on
                        vacation
                    </div>
                </div>
                <div
                    style={{
                        flexDirection: 'column',
                        alignItems: 'center',
                        display: 'flex',
                        marginTop: 20
                    }}
                >
                    <img
                        src={images.icClarity}
                        className="lpImage"
                        style={{ width: 30, height: 30 }}
                    />
                    <div className="lpButtonTitle" style={{ marginTop: 10 }}>
                        Clarity
                    </div>
                    <div className="lpButtonSubTitle" style={{ marginTop: 10 }}>
                        Better understand how much water is used for gardening
                        or other activities
                    </div>
                </div>
                <div
                    style={{
                        flexDirection: 'column',
                        alignItems: 'center',
                        display: 'flex',
                        marginTop: 20
                    }}
                >
                    <img
                        src={images.icContext}
                        className="lpImage"
                        style={{ width: 30, height: 30 }}
                    />
                    <div className="lpButtonTitle" style={{ marginTop: 10 }}>
                        Context
                    </div>
                    <div className="lpButtonSubTitle" style={{ marginTop: 10 }}>
                        Compare ones usage against similar households for a
                        more effective conservation program
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignSelf: 'center',
                        marginTop: 60
                    }}
                    className="alsoAvailableMob"
                >
                    Also Available for IOS and Android
                </div>
                <div
                    style={{
                        flexDirection: 'row',
                        display: 'flex',
                        alignSelf: 'center',
                        alignItems: 'center'
                    }}
                >
                    <button
                        style={{
                            backgroundColor: 'transparent',
                            alignItems: 'center'
                        }}
                        onClick={() => window.open(globals.IOS_LINK, '_blank')}
                    >
                        <img className="lpButtonMob" src={images.btnAppStore} />
                    </button>
                    <button
                        style={{
                            backgroundColor: 'transparent',
                            alignItems: 'center'
                        }}
                        onClick={() =>
                            window.open(globals.ANDROID_LINK, '_blank')
                        }
                    >
                        <img
                            className="lpButtonMob"
                            src={images.btnGooglePlay}
                        />
                    </button>
                </div>
                <div style={{ display: 'flex', marginBottom: 100, zIndex: -1 }}>
                    <img
                        src={images.bgWaveMob}
                        style={{ position: 'absolute', width: '100%' }}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignSelf: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: 400
                    }}
                >
                    <div style={{ justifyContent: 'center' }}>
                        <img
                            className="logoMWAHeader"
                            src={images.logo}
                            style={{ width: 80, height: 80 }}
                        />
                    </div>
                    <div className="headerTitleLabel" style={{margin: 0, padding: 0}}>My Water Advisor <div style={{display: 'inline', fontSize: 16, position: 'relative', top: -7}}>®</div></div>
                    <div
                        className="headerSubtitleLabel"
                        style={{ margin: 0, marginTop: 8, padding: 0 }}
                    >
                        Online access to Your World of Water™
                    </div>
                </div>
            </div>
        );
    }

    _renderDesktop() {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    flex: 1,
                    marginTop: 50
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', width: '50%' }}>
                        <img
                            style={{ position: 'absolute' }}
                            src={images.lpTopImage}
                            className="lpBgTop"
                        />
                        <div
                            style={{
                                position: 'absolute',
                                display: 'flex',
                                flexDirection: 'row',
                                marginTop: 40,
                                marginLeft: 100
                            }}
                        >
                            <div style={{ justifyContent: 'center' }}>
                                <img
                                    className="logoMWAHeader"
                                    src={images.logo}
                                    width={50}
                                    height={50}
                                />
                            </div>
                            <div style={{marginLeft: 10}}>
                                <div className="headerTitleLabel" style={{margin: 0, padding: 0}}>My Water Advisor <div style={{display: 'inline', fontSize: 16, position: 'relative', top: -7}}>®</div></div>
                                <div
                                    className="headerSubtitleLabel"
                                    style={{
                                        margin: 0,
                                        marginTop: 4,
                                        padding: 0
                                    }}
                                >
                                    Online access to Your World of Water™
                                </div>
                            </div>
                        </div>
                        <img
                            src={images.lpImage}
                            className="lpBigImage"
                            style={{
                                marginTop: 120,
                                marginLeft: 80,
                                position: 'absolute',
                                zIndex: 1
                            }}
                        />
                    </div>
                    <div
                        style={{
                            flexDirection: 'column',
                            display: 'flex',
                            width: '50%',
                            marginTop: 200,
                            zIndex: 1
                        }}
                    >
                        <div
                            className="lpMonitorLabel"
                            style={{ color: '#3c63d7'}}
                        >
                            Monitor your water consumption with real time data,
                            alerts and analytics
                        </div>
                        <div
                            style={{
                                flexDirection: 'row',
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: 30,
                                marginLeft: -20
                            }}
                        >
                            <Link
                                to={'/register'}
                                style={{
                                    backgroundColor: 'transparent',
                                    alignItems: 'center'
                                }}
                            >
                                <img
                                    className="lpButton"
                                    src={images.btnJoin}
                                />
                            </Link>
                            <Link
                                to={'/login'}
                                style={{
                                    backgroundColor: 'transparent',
                                    alignItems: 'center',
                                    marginTop: -20
                                }}
                            >
                                <img
                                    className="lpButton"
                                    src={images.btnLoginWhite}
                                />
                            </Link>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    <img
                        src={images.bgWave}
                        style={{ position: 'absolute', width: '100%' }}
                    />
                </div>
                <div
                    style={{
                        flexDirection: 'row',
                        display: 'flex',
                        marginTop: 500,
                        alignSelf: 'center',
                        alignItems: 'center'
                    }}
                >
                    <div
                        style={{
                            flexDirection: 'column',
                            alignItems: 'center',
                            display: 'flex'
                        }}
                    >
                        <img
                            src={images.icEmpowerment}
                            className="lpImage"
                            style={{ width: 40, height: 40 }}
                        />
                        <div
                            className="lpButtonTitle"
                            style={{ marginTop: 10 }}
                        >
                            Empowerment
                        </div>
                        <div
                            className="lpButtonSubTitle"
                            style={{ marginTop: 10 }}
                        >
                            Knowledge of how and when we use water empower’s
                            conservation
                        </div>
                    </div>
                    <div
                        style={{
                            flexDirection: 'column',
                            alignItems: 'center',
                            display: 'flex',
                            marginLeft: 20
                        }}
                    >
                        <img
                            src={images.icFlexibility}
                            className="lpImage"
                            style={{ width: 40, height: 40 }}
                        />
                        <div
                            className="lpButtonTitle"
                            style={{ marginTop: 10 }}
                        >
                            Flexibility
                        </div>
                        <div
                            className="lpButtonSubTitle"
                            style={{ marginTop: 10 }}
                        >
                            Apps available for both Apple iOS and Android, and
                            cloud-based browser desktop access
                        </div>
                    </div>
                    <div
                        style={{
                            flexDirection: 'column',
                            alignItems: 'center',
                            display: 'flex',
                            marginLeft: 20
                        }}
                    >
                        <img
                            src={images.icCustomerService}
                            className="lpImage"
                            style={{ width: 40, height: 40 }}
                        />
                        <div
                            className="lpButtonTitle"
                            style={{ marginTop: 10 }}
                        >
                            Customer service
                        </div>
                        <div
                            className="lpButtonSubTitle"
                            style={{ marginTop: 10 }}
                        >
                            Set budget alerts to end month-end high billing
                            surprises
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        flexDirection: 'row',
                        display: 'flex',
                        alignSelf: 'center',
                        alignItems: 'center',
                        marginTop: 60
                    }}
                >
                    <div
                        style={{
                            flexDirection: 'column',
                            alignItems: 'center',
                            display: 'flex'
                        }}
                    >
                        <img
                            src={images.icSafety}
                            className="lpImage"
                            style={{ width: 40, height: 40 }}
                        />
                        <div
                            className="lpButtonTitle"
                            style={{ marginTop: 10 }}
                        >
                            Safety
                        </div>
                        <div
                            className="lpButtonSubTitle"
                            style={{ marginTop: 10 }}
                        >
                            Monitor unexpected or unauthorized usage while away
                            on vacation
                        </div>
                    </div>
                    <div
                        style={{
                            flexDirection: 'column',
                            alignItems: 'center',
                            display: 'flex',
                            marginLeft: 20
                        }}
                    >
                        <img
                            src={images.icClarity}
                            className="lpImage"
                            style={{ width: 40, height: 40 }}
                        />
                        <div
                            className="lpButtonTitle"
                            style={{ marginTop: 10 }}
                        >
                            Clarity
                        </div>
                        <div
                            className="lpButtonSubTitle"
                            style={{ marginTop: 10 }}
                        >
                            Better understand how much water is used for
                            gardening or other activities
                        </div>
                    </div>
                    <div
                        style={{
                            flexDirection: 'column',
                            alignItems: 'center',
                            display: 'flex',
                            marginLeft: 20
                        }}
                    >
                        <img
                            src={images.icContext}
                            className="lpImage"
                            style={{ width: 40, height: 40 }}
                        />
                        <div
                            className="lpButtonTitle"
                            style={{ marginTop: 10 }}
                        >
                            Context
                        </div>
                        <div
                            className="lpButtonSubTitle"
                            style={{ marginTop: 10 }}
                        >
                            Compare ones usage against similar households for
                            a more effective conservation program
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: 'flex',
                        zIndex: -1,
                        flexDirection: 'column'
                    }}
                >
                    <img
                        src={images.bgWaveBottom}
                        style={{ position: 'absolute', width: '100%' }}
                    />
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignSelf: 'center',
                        marginTop: 200
                    }}
                    className="alsoAvailable"
                >
                    Also Available for IOS and Android
                </div>
                <div
                    style={{
                        flexDirection: 'row',
                        display: 'flex',
                        marginTop: 20,
                        alignSelf: 'center'
                    }}
                >
                    <button
                        style={{
                            backgroundColor: 'transparent',
                            alignItems: 'center'
                        }}
                        onClick={() => window.open(globals.IOS_LINK, '_blank')}
                    >
                        <img className="lpButton" src={images.btnAppStore} />
                    </button>
                    <button
                        style={{
                            backgroundColor: 'transparent',
                            alignItems: 'center'
                        }}
                        onClick={() =>
                            window.open(globals.ANDROID_LINK, '_blank')
                        }
                    >
                        <img className="lpButton" src={images.btnGooglePlay} />
                    </button>
                </div>
                <div
                    style={{
                        display: 'flex',
                        alignSelf: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        marginTop: 400
                    }}
                >
                    <div style={{ justifyContent: 'center' }}>
                        <img
                            className="logoMWAHeader"
                            src={images.logo}
                            style={{ width: 80, height: 80 }}
                        />
                    </div>
                    <div className="headerTitleLabel" style={{margin: 0, padding: 0}}>My Water Advisor <div style={{display: 'inline', fontSize: 16, position: 'relative', top: -7}}>®</div></div>
                    <div
                        className="headerSubtitleLabel"
                        style={{ margin: 0, marginTop: 8, padding: 0 }}
                    >
                        Online access to Your World of Water™
                    </div>
                </div>
            </div>
        );
    }

    componentWillUnmount() {
        this.props.toggleHeader(true);
    }
}
