import React from 'react';
import './AppPopup.css';
import images from '../images';
import { Desktop, Mobile } from '../App';

export default class AppPopup extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <Mobile>
                    {matches => {
                        if (matches) return this._renderMobile();
                        else return this._renderDesktop();
                    }}
                </Mobile>
            </div>
        );
    }

    _renderMobile() {
        return (
            <div style={{ padding: 10 }}>
                <button
                    style={{ float: 'right', backgroundColor: 'transparent' }}
                    onClick={() => this.props.onClose()}
                >
                    <img src={images.icClose} />
                </button>
                <p className={'title'}>My Water Advisor</p>
                <p className={'message'}>{this.props.message}</p>
            </div>
        );
    }

    _renderDesktop() {
        return (
            <div style={{ padding: 10 }}>
                <button
                    style={{ float: 'right', backgroundColor: 'transparent' }}
                    onClick={() => this.props.onClose()}
                >
                    <img src={images.icClose} />
                </button>
                <div className={'title'}>My Water Advisor</div>
                <p className={'message'}>{this.props.message}</p>
            </div>
        );
    }
}
