import React from 'react';
import globals from "../../globals";
import * as api from "../../api";
import './RegisterNotFound.css';
import {Link} from "react-router-dom";
import images from "../../images";
import {Desktop, Mobile} from "../../App";

export default class RegisterNotFound extends React.Component {
    constructor(props) {
        super(props);

        this.type = props.match.params.type;

        this.state = {
            utilities: []
        }
    }

    render() {
        return (
            <div>
                <Mobile>
                    {matches => {
                        if (matches)
                            return this._renderMobile();
                        else
                            return this._renderDesktop();
                    }}
                </Mobile>
            </div>
        );
    }

    _renderMobile() {
        return (
            <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginRight: 20,
                marginLeft: 20,
                marginTop: -36
            }}>
                <h1 className={"titleMobile"}>Join My Water Advisor</h1>
                <div style={{margin: 20}} align="center">
                    <h3 className="accountNotFound">{this.type === 'phone' ? 'Phone number' : 'Account'} was not
                        found</h3>
                    <h3 className="tryAnother" style={{marginLeft: 30, marginRight: 30}}>Please try
                        another {this.type === 'phone' ? 'phone number' : 'account'} or registering with <Link
                            to={this.type === 'phone' ? '/register/account' : '/register/phone'}>{this.type === 'phone' ? 'account' : 'phone number'}</Link>
                    </h3>
                    <button style={{backgroundColor: 'transparent'}} onClick={() => this.props.history.goBack()}>
                        <img src={images.btnBack}/>
                    </button>
                </div>
                <p style={{marginTop: 0, justifyContent: 'center'}} className="Need-help-Call-your-utility-office">Need
                    help? Call your utility office</p>
                <p style={{
                    marginTop: 4,
                    justifyContent: 'center',
                    marginLeft: 20,
                    marginRight: 20,
                    textAlign: 'center'
                }}
                   className="Your-water-utility-name-can-be-found-in-the-monthl-mobile">Your water utility name can be
                    found
                    in the monthly water bill</p>
                <input placeholder="Search Utility by Name" type="text" className="Rectangle"
                       style={{paddingLeft: 10, paddingRight: 10, width: 300, marginTop: 20}}
                       onChange={this.onChangeUtilityName.bind(this)}>
                </input>
                <div style={{marginTop: 10, width: 320}}>
                    {this.state.utilities.map(item => {
                        return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <p style={{color: globals.COLOR.DUSK, fontSize: 16}}>{item.description}</p>
                            <p style={{
                                color: globals.COLOR.AZURE,
                                fontSize: 15,
                                marginLeft: 10
                            }}>{item.phoneNumber}</p>
                        </div>
                    })}
                </div>
            </div>);
    }

    _renderDesktop() {
        return (
            <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 20}}>
                <h1 className={"title"}>Join My Water Advisor</h1>
                <div style={{marginTop: 20}} align="center">
                    <h3 className="accountNotFound">{this.type === 'phone' ? 'Phone number' : 'Account'} was not
                        found</h3>
                    <h3 className="tryAnother">Please try
                        another {this.type === 'phone' ? 'phone number' : 'account'} or registering with <Link
                            to={this.type === 'phone' ? '/register/account' : '/register/phone'}>{this.type === 'phone' ? 'account' : 'phone number'}</Link>
                    </h3>
                    <button style={{backgroundColor: 'transparent'}} onClick={() => this.props.history.goBack()}>
                        <img src={images.btnBack}/>
                    </button>
                </div>
                <p style={{marginTop: 20, justifyContent: 'center'}} className="Need-help-Call-your-utility-office">Need
                    help? Call your utility office</p>
                <p style={{marginTop: 4, justifyContent: 'center'}}
                   className="Your-water-utility-name-can-be-found-in-the-monthl">Your water utility name can be found
                    in the monthly water bill</p>
                <input placeholder="Search Utility by Name" type="text" className="Rectangle"
                       style={{paddingLeft: 10, paddingRight: 10, width: 300}}
                       onChange={this.onChangeUtilityName.bind(this)}>
                </input>
                <div style={{marginTop: 10, width: 320}}>
                    {this.state.utilities.map(item => {
                        return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <p style={{color: globals.COLOR.DUSK, fontSize: 16}}>{item.description}</p>
                            <p style={{
                                color: globals.COLOR.AZURE,
                                fontSize: 15,
                                marginLeft: 10
                            }}>{item.phoneNumber}</p>
                        </div>
                    })}
                </div>
            </div>);
    }

    onChangeUtilityName = (event) => {
        const term = event.target.value;
        if (term.length >= 2) {
            api.searchUtilities(term, (res, err) => {
                if (!err) {
                    this.setState({utilities: res});
                }
            })
        } else if (term.length === 0) {
            this.setState({utilities: []});
        }
    };
}