import React from 'react';
import * as api from "../../api";
import DataManager from "../../managers/DataManager";
import * as validators from "../../validators";
import images from "../../images";
import Input from 'react-validation/build/input';
import Form from 'react-validation/build/form';
import Button from 'react-validation/build/button';
import {Desktop, Mobile} from "../../App";
import Popup from "reactjs-popup";
import AppPopup from "../../components/AppPopup";

class EnterCodePassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            code: '',
            errorMessage: null
        }
    }

    render() {
        return (
            <div>
                <Mobile>
                    {matches => {
                        if (matches)
                            return this._renderMobile();
                        else
                            return this._renderDesktop();
                    }}
                </Mobile>
            </div>
        );
    }

    _renderMobile() {
        return (
            <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 20, marginRight: 20}}>
                <h2 className={"mainTitleMob"} style={{fontSize: 17}}>Change Password</h2>
                <h4 className={"subTitleMob"} style={{fontSize: 17}}>Enter the code that was sent via email.</h4>
                <Form method={"post"} onSubmit={this.onSubmit.bind(this)}>
                    <Input validators={[validators.required]} placeholder="Code" type="tel" name="code"
                           onChange={(event) => this.setState({code: event.target.value})}
                           className="codeInput rectangle rectangleMobile" value={this.state.code}
                           style={{
                               paddingLeft: 10,
                               paddingRight: 10,
                               display: 'flex',
                               border: 0,
                               backgroundColor: 'transparent'
                           }}>
                    </Input>
                    <div style={{marginTop: 20, display: 'flex', justifyContent: 'center'}}>
                        <Button type="submit" style={{border: 0, backgroundColor: 'transparent'}}>
                            <img src={this.state.code === '' ? images.btnContinueGrey : images.btnContinue} className="btn_continue"/>
                        </Button>
                    </div>
                </Form>
                <Popup
                    contentStyle={{borderRadius: 5}}
                    open={this.state.errorMessage}
                    onClose={this.closeErrorPopup.bind(this)}
                    lockScroll={true}
                >
                    <AppPopup onClose={this.closeErrorPopup.bind(this)} message={this.state.errorMessage}/>
                </Popup>
            </div>
        );
    }

    _renderDesktop() {
        return (
            <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <h1 className={"title"}>Change Password</h1>
                <h3 className="enterCode">Enter the code that was sent via email.</h3>
                <h3 className="phone" style={{display: 'flex', justifyContent: 'center'}}>{this.state.phone}</h3>
                <Form method={"post"} onSubmit={this.onSubmit.bind(this)}>
                    <div style={{marginTop: 20, display: 'flex', alignItems: 'center'}}>
                        <Input validators={[validators.required]} placeholder="Code" type="tel" name="code"
                               onChange={(event) => this.setState({code: event.target.value})}
                               className="codeInput rectangle" value={this.state.code}
                               style={{
                                   paddingLeft: 10,
                                   paddingRight: 10,
                                   display: 'flex',
                                   border: 0,
                                   backgroundColor: 'transparent'
                               }}>
                        </Input>
                    </div>
                    <div style={{marginTop: 20, display: 'flex', justifyContent: 'center'}}>
                        <Button type="submit" style={{border: 0, backgroundColor: 'transparent'}}>
                            <img src={this.state.code === '' ? images.btnContinueGrey : images.btnContinue} className="btn_continue"/>
                        </Button>
                    </div>
                </Form>
                <Popup
                    contentStyle={{borderRadius: 5}}
                    open={this.state.errorMessage}
                    onClose={this.closeErrorPopup.bind(this)}
                    lockScroll={true}
                >
                    <AppPopup onClose={this.closeErrorPopup.bind(this)} message={this.state.errorMessage}/>
                </Popup>
            </div>
        );
    }

    closeErrorPopup() {
        this.setState({errorMessage: null});
    }

    onSubmit = (event) => {
        event.preventDefault();

        //this.setState({isLoading: true});
        if (this.state.code !== '') {
            api.validateRecoveryCode(this.state.code, (res, error) => {
                console.log(res, error);
                //this.setState({isLoading: false});
                if (error) {
                    this.setState({errorMessage: 'Unknown error has occurred.'});
                } else {
                    if (res.error) {
                        this.setState({errorMessage: res.error});
                    } else {
                        this.props.history.push(`/password/change/${this.state.code}`);
                    }
                }
            });
        }
    }
}

export default (EnterCodePassword)