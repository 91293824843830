import React from 'react';
import globals from "../globals";

export default class Tabs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedIndex: 0
        }
    }

    render() {
        const {items, style} = this.props;
        return (
            <div style={[style, {flexDirection: 'row', display: 'flex', paddingLeft: 100}]}>
                {items.map((item, index) => {
                    return (
                        <button onClick={() => this._onChange(index)} style={{display: 'flex', width: '100%', flexDirection: 'column', justifyContent: 'center'}}>
                            <div style={{display: 'flex', fontSize: 14, color: this.state.selectedIndex === index ? globals.COLOR.AZURE : globals.COLOR.COOL_GRAY}}>{item}</div>
                            <div style={{display: 'flex', width: '100%', marginTop: 10, height: 1, backgroundColor: this.state.selectedIndex === index ? globals.COLOR.AZURE : globals.COLOR.COOL_GRAY}}/>
                        </button>
                    );
                })}
            </div>
        );
    }

    _onChange = (index) => {
        if (index === this.state.selectedIndex) return;
        this.setState({selectedIndex: index});
        this.props.onTabSelected(index);
    };
}