import React from "react";
import DatePicker from "react-date-picker";
import "./Home.css";
import utils from "../../utils";
import * as api from "../../api";
import DataManager from "../../managers/DataManager";
import moment from "moment";
import { Desktop, Mobile } from "../../App";
import Popup from "reactjs-popup";
import MonthlyLimitPopup from "./MonthlyLimitPopup";
import images from "../../images";
import DefineVacationPopup from "./DefineVacationPopup";
import Tabs from "../../components/Tabs";
import globals from "../../globals";
import MeterChart from "../../components/MeterChart";
import MeterSelection from "../../components/MeterSelection";
import CalendarRangePicker from "../../components/CalendarRangePicker";
import * as FileSaver from "file-saver";
import PersistenceManager from "../../managers/PersistenceManager";
import { ALERTS_INTERVAL } from "../../const";
import AppPopup from "../../components/AppPopup";

import "./DatePicker.css";

const MONTH_COUNT = 12;

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.today = new Date();

    this.state = {
      isLoading: false,
      startDate: moment(this.today).subtract(MONTH_COUNT, "months"), //1
      endDate: this.today,
      dailyStartDate: moment().subtract(31, "days"),
      dailyEndDate: moment(),
      monthlyDefaultStartDate: moment(this.today).subtract(MONTH_COUNT, "months"),
      monthlyDefaultEndDate: this.today,
      dailyDefaultStartDate: moment(this.today).subtract(1, "months"),
      dailyDefaultEndDate: this.today,
      currentMeter: null,
      thisMonthUsage: "", //'',
      forecastUsage: "", //'',
      monthlyLimit: "",
      units: "", //'',
      alert: undefined,
      openMonthlyLimit: false,
      openDefineVacation: false,
      openMeterSelection: false,
      openCalendarPicker: false,
      selectedChartTypeIndex: 0,
      selectedChartTimeTypeIndex: 0,
      dailyConsumptions: [],
      monthlyConsumptions: [],
      monthlyConsumptionsForNeighborhood: [],
      hourlyConsumptions: [],
      yearsConsumptions: [],
      neighborConsumptions: [],
      chartShape: "bar",
      errorMessage: null,
      isDefault: true,
      billingCycles: [
        { type: "current", billingCycleStart: "", billingCycleEnd: "" },
        {
          type: "previous",
          billingCycleStart: "",
          billingCycleEnd: "",
        },
      ],
      chartTypes: [],
      isCalendarDateSelected: false,
    };

    this.meters = [];

    // this.chartTypes = [
    //     // 'WATER CONSUMPTION',
    //     // 'ME VS. SIMILAR HOUSEHOLDS',
    //     // 'COMPARE TO PREVIOUS YEAR'
    // ];

    this.chartTimeTypes = ["Billing Month", "Daily", "Hourly"];
  }

  getClassName = () => {
    return this.state.monthlyLimit
      ? this.state.monthlyLimit < this.state.forecastUsage
        ? "endOfMonthForecastValue OverLimit"
        : "endOfMonthForecastValue Limit"
      : "endOfMonthForecastValue";
  };

  _renderDesktop() {
    let { billingCycleStart, billingCycleEnd } = this.state.billingCycles[0];

    const startDate = moment(billingCycleStart).format("MM-DD-YYYY");
    const endDate = moment(billingCycleEnd).subtract(1, "days").format("MM-DD-YYYY");

    console.log(
      `billingCycleStart ${billingCycleStart} billingCycleEnd ${billingCycleEnd} DIFF ${moment(
        billingCycleStart
      ).diff(moment.now(), "months")}`
    );

    let billingCycleText =
      billingCycleStart && billingCycleEnd
        ? `Billing Cycle Date: ${startDate} - ${endDate}`
        : "";

    let meterDesc = "";
    if (!this.state.currentMeter) {
      meterDesc = `All Water Meters (${this.meters.length})`;
    } else {
      const meter = this.state.currentMeter;
      meterDesc = utils.getMeterDesc(meter);
    }

    let chartData;
    switch (this.state.selectedChartTypeIndex) {
      case 0: {
        //My
        switch (this.state.selectedChartTimeTypeIndex) {
          case 0:
            //Month
            chartData = this.state.monthlyConsumptions;
            break;
          case 1:
            //Day
            chartData = this.state.dailyConsumptions;
            break;
          case 2:
            //Hourly
            chartData = this.state.hourlyConsumptions;
            break;
        }
        break;
      }
      case 1: {
        //Neighbor
        chartData = this.state.monthlyConsumptionsForNeighborhood;
        break;
      }
      case 2: {
        //Year
        chartData = this.state.monthlyConsumptionsForNeighborhood;
        break;
      }
    }

    console.log("RERENDER");

    return (
      <div className="desktopHomeContent">
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 20,
              width: "50%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                padding: 20,
                paddingLeft: 100,
                alignItems: "center",
              }}
            >
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                  flex: 1,
                }}
              >
                <div className="myWaterUsage">
                  Water Usage for Billing Cycle
                </div>
              </div>
              <div
                className="waterUsageSep"
                style={{ marginLeft: 20, marginRight: 20 }}
              />
              <button
                disabled={this.meters.length < 2}
                style={{
                  flexDirection: "column",
                  display: "flex",
                  alignItems: "flex-start",
                  flex: 1,
                }}
                onClick={() =>
                  this.setState(
                    { openMeterSelection: true },
                    () => (document.body.style.overflow = "hidden")
                  )
                }
              >
                <div
                  className="waterMeterTitle"
                  style={{
                    alignSelf: "flex-start",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Water Meter
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    marginTop: 4,
                  }}
                >
                  <img src={images.icLocation} />
                  <div
                    className="waterMeterValue"
                    style={{
                      alignSelf: "flex-start",
                      display: "flex",
                      marginLeft: 7,
                      justifyContent: "center",
                    }}
                  >
                    {meterDesc}
                  </div>
                </div>
              </button>
            </div>

            <div
              className="waterUsageSepHor"
              style={{ marginLeft: 40, marginRight: 40 }}
            />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 20,
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingLeft: 100,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div className="thisMonthLabel">Current Billing Cycle</div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div
                      className={
                        this.state.monthlyLimit &&
                        this.state.thisMonthUsage > this.state.monthlyLimit
                          ? "monthlyLimitValueLabel"
                          : this.state.thisMonthUsage !== "No Data"
                          ? "thisMonthValueGreen"
                          : "thisMonthValue"
                      }
                    >
                      {this.state.thisMonthUsage}
                    </div>
                    {this.state.thisMonthUsage !== "No Data" && (
                      <div
                        className="thisMonthUnits"
                        style={{
                          marginLeft: 8,
                          alignSelf: "flex-end",
                          marginBottom: 8,
                        }}
                      >
                        {this.state.units.toLowerCase()}
                      </div>
                    )}
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginLeft: 60,
                  }}
                >
                  <div className="endOfMonthForecastLabel">
                    End of Billing Cycle Forecast
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <div className={this.getClassName()}>
                      {this.state.forecastUsage}
                    </div>
                    <div
                      className="endOfMonthForecastUnits"
                      style={{
                        marginLeft: 8,
                        alignSelf: "flex-end",
                        marginBottom: 8,
                      }}
                    >
                      {this.state.units.toLowerCase()}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {this.state.monthlyLimit ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  paddingLeft: 100,
                  marginTop: 15,
                }}
              >
                <div className="monthlyLimitLabel">Billing Cycle Limit:</div>
                <div className="monthlyLimitValue" style={{ marginLeft: 8 }}>
                  {this.state.monthlyLimit}
                </div>
                {this.state.currentMeter === null ? (
                  <div
                    className="monthlyLimitLabel"
                    style={{ fontSize: 13, marginLeft: 8 }}
                  >
                    (Please select a specific meter to update billing cycle
                    limit)
                  </div>
                ) : (
                  <a
                    style={{ fontSize: 13, marginLeft: 8 }}
                    onClick={this.openMonthlyLimit.bind(this)}
                  >
                    Update
                  </a>
                )}
              </div>
            ) : this.state.currentMeter === null ? (
              <div
                className="monthlyLimitLabel"
                style={{ fontSize: 13, marginLeft: 100 }}
              >
                (Please select a specific meter to update billing cycle limit)
              </div>
            ) : (
              <a
                style={{
                  paddingLeft: 100,
                  fontSize: 13,
                  marginTop: 20,
                }}
                onClick={this.openMonthlyLimit.bind(this)}
              >
                Set Billing Cycle Limit
              </a>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: 100,
                paddingBottom: 20,
                paddingTop: 20,
              }}
            >
              <div className="monthlyLimitLabel">{billingCycleText}</div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "50%",
              padding: 50,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#f9f9f9",
                alignItems: "center",
                paddingTop: 20,
                paddingBottom: 20,
              }}
            >
              {console.log(this.state?.vacations)}
              <div
                className="getInformed"
                style={{ width: "90%", textAlign: "center" }}
              >
                Get informed about water consumption while away from home
              </div>
              {this.state.vacation ? (
                <div
                  style={{
                    marginTop: 10,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {this.state?.currentMeterVacation && (
                    <div className="vacationDates">
                      {utils.toVacationDate(
                        this.state.currentMeterVacation.startDate
                      )}{" "}
                      -{" "}
                      {utils.toVacationDate(
                        this.state.currentMeterVacation.endDate
                      )}
                    </div>
                  )}
                  {this.state?.currentMeterVacation ? (
                    <div>
                      <a
                        style={{
                          fontSize: 13,
                          margin: 10,
                        }}
                        onClick={this.openDefineVacation.bind(this)}
                      >
                        Update
                      </a>
                      <a
                        style={{
                          fontSize: 13,
                          margin: 10,
                        }}
                        onClick={this.handleCancelVacation.bind(this)}
                      >
                        Cancel vacation
                      </a>
                    </div>
                  ) : (
                    <>
                      {this.state?.currentMeter && (
                        <button
                          style={{
                            border: 0,
                            backgroundColor: "transparent",
                          }}
                          onClick={this.openDefineVacation.bind(this)}
                        >
                          <img
                            src={images.btnDefineDays}
                            className="btnDefineDays"
                          />
                        </button>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <>
                  {this.state?.currentMeter && (
                    <button
                      style={{
                        border: 0,
                        backgroundColor: "transparent",
                      }}
                      onClick={this.openDefineVacation.bind(this)}
                    >
                      <img
                        src={images.btnDefineDays}
                        className="btnDefineDays"
                      />
                    </button>
                  )}
                </>
              )}
              {!this.state?.currentMeter && (
                <div
                  className="getInformedSmall"
                  style={{ width: "90%", textAlign: "center" }}
                >
                  Please select a specific meter to update the usage during
                  vacation alert
                </div>
              )}
              {!this.state?.currentMeter && this.state.vacation ? (
                <div
                  className="vacationDatesSmall"
                  style={{ textAlign: "center", width: "100%", padding: 15 }}
                >
                  {utils.toVacationDate(this.state.vacation.startDate)} -{" "}
                  {utils.toVacationDate(this.state.vacation.endDate)}
                  <br />
                  Water Meter:{" "}
                  {utils.findMeter(
                    this.state.vacation?.meterCount,
                    this.meters
                  )}
                </div>
              ) : (
                <></>
              )}
              {!this.state?.currentMeter &&
              this.state?.vacations?.length > 1 ? (
                <div
                  className="getInformedSmall"
                  style={{ width: "90%", textAlign: "center" }}
                >
                  Additional vacation dates exist, select a specific meter to
                  show
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {this.state.alerts &&
          this.state.alerts.map((item) => {
            return (
              <div
                key={JSON.stringify(item)}
                style={{
                  display: "flex",
                  backgroundColor: globals.COLOR.BLUSH,
                  padding: 10,
                  marginBottom: 4,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginLeft: 16,
                    }}
                  >
                    <img
                      src={utils.getSmallIconForAlertType(item.alertTypeId)}
                      style={{
                        width: 40,
                        height: 40,
                        resizeMode: "contain",
                      }}
                    />
                    <div style={{ marginLeft: 20, flex: 3 }}>
                      <div className="alertTypeLabel">{item.alertTypeName}</div>
                      <div className="alertDescription">{`${item.meterSn}, ${
                        item.address
                          ? item.address
                          : globals.METER_DEFAULT_ADDRESS
                      }`}</div>
                    </div>
                  </div>
                  <div
                    style={{
                      flexDirection: "column",
                      display: "flex",
                      alignItems: "flex-end",
                    }}
                  >
                    <div
                      style={{
                        alignItems: "flex-end",
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div className="alertDate">
                        {utils.toAlertDate(item.alertTime)}
                      </div>
                      <button
                        style={{ border: 0, backgroundColor: "transparent" }}
                        onClick={() => this.onOpenAlert(item)}
                      >
                        <img
                          src={images.btnSeeDetails}
                          className="btnSave"
                          style={{ width: 140, marginRight: -16 }}
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        <div
          style={{
            flexDirection: "row",
            display: "flex",
            width: "100%",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          {this.state.chartTypes.map((item, index) => {
            return (
              <button
                key={index.toString()}
                onClick={() => this.onChartTypeChange(index)}
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "stretch",
                  padding: 0,
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    fontWeight: "bold",
                    fontSize: 14,
                    color:
                      this.state.selectedChartTypeIndex === index
                        ? globals.COLOR.AZURE
                        : globals.COLOR.COOL_GRAY,
                  }}
                >
                  {item}
                </div>
                <div
                  style={{
                    marginTop: 10,
                    height: 2,
                    backgroundColor:
                      this.state.selectedChartTypeIndex === index
                        ? globals.COLOR.AZURE
                        : globals.COLOR.COOL_GRAY,
                  }}
                />
              </button>
            );
          })}
        </div>
        <div
          className="chartBg"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {this.state.selectedChartTypeIndex === 0 && (
            <div
              style={{
                // flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  width: "30%",
                  height: 50,
                }}
                className="chartTypesBg"
              >
                {this.chartTimeTypes.map((item, index) => {
                  return (
                    <button
                      key={index.toString()}
                      style={{
                        width: "100%",
                        height: "100%",
                        padding: 0,
                        position: "relative",
                      }}
                      onClick={() => this.onChartTimeChange(index)}
                    >
                      <div
                        className={
                          this.state.selectedChartTimeTypeIndex === index
                            ? "chartTypesBg"
                            : ""
                        }
                        style={{
                          display: "flex",
                          position: "absolute",
                          top: -3,
                          left: -3,
                          right: -3,
                          bottom: -3,
                          fontSize: 14,
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          color:
                            this.state.selectedChartTimeTypeIndex === index
                              ? "#fff"
                              : globals.COLOR.BROWNISH_GRAY,
                          backgroundColor:
                            this.state.selectedChartTimeTypeIndex === index
                              ? globals.COLOR.VIBRANT_BLUE
                              : "transparent",
                        }}
                      >
                        {item}
                      </div>
                    </button>
                  );
                })}
              </div>
              <div
                style={{
                  display: "flex",
                  // flexDirection: "row",
                  marginLeft: 24,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "50%",
                    marginLeft: 20,
                    marginRight: 20,
                  }}
                >
                  <div className="fromTo">
                    {this.state.selectedChartTimeTypeIndex !== 2
                      ? "From"
                      : "\n"}
                  </div>
                  {this.state.selectedChartTimeTypeIndex === 0 ? (
                    <DatePicker
                      locale="en"
                      onChange={(startDate) => {
                        this.closeCalendarPicker(
                          startDate,
                          !this.state.selectedChartTimeTypeIndex
                            ? moment(this.state.endDate).diff(
                                startDate,
                                "months"
                              ) > MONTH_COUNT
                              ? moment(startDate).add(MONTH_COUNT, "month")
                              : moment(this.state.endDate).diff(
                                  startDate,
                                  "months"
                                ) < 0
                              ? startDate
                              : this.state.endDate
                            : moment(this.state.dailyEndDate).diff(
                                startDate,
                                "days"
                              ) > 30
                            ? moment(startDate).add(31, "days")
                            : moment(this.state.dailyEndDate).diff(
                                startDate,
                                "days"
                              ) < 0
                            ? startDate
                            : this.state.dailyEndDate
                        );
                      }}
                      closeCalendar={false}
                      clearIcon={null}
                      maxDetail={
                        !this.state.selectedChartTimeTypeIndex
                          ? "year"
                          : "month"
                      }
                      format={
                        !this.state.selectedChartTimeTypeIndex
                          ? "yyyy,MMM"
                          : "yyyy,MMM, d"
                      }
                      value={utils.toDateFromString(
                        !this.state.selectedChartTimeTypeIndex
                          ? this.state.startDate
                          : this.state.dailyStartDate
                      )}
                      calendarIcon={<img src={images.icSmallCalendar} />}
                      maxDate={
                        this.state.billingCycles[0].billingCycleStart !== "" &&
                        moment(
                          this.state.billingCycles[0].billingCycleStart
                        ).diff(moment.now()) <= 0
                          ? new Date(
                              this.state.billingCycles[0].billingCycleStart
                            )
                          : new Date()
                      }
                      minDetail={"year"}
                    />
                  ) : (
                    <button
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginLeft: -8,
                        marginTop: 8,
                      }}
                      onClick={this.onChooseDate.bind(this)}
                    >
                      <img src={images.icSmallCalendar} />
                      <div
                        placeholder="Choose date"
                        disabled={true}
                        type="text"
                        className="chooseDateInput"
                        style={{
                          marginLeft: 8,
                          border: 0,
                          paddingLeft: 10,
                          paddingRight: 10,
                          flexShrink: 0,
                          fontSize: 13,
                        }}
                      >
                        {this.state.selectedChartTimeTypeIndex === 0
                          ? utils.toMonthDate(this.state.startDate)
                          : this.state.selectedChartTimeTypeIndex === 1
                          ? !this.state.isCalendarDateSelected
                            ? utils.toVacationDate(
                                this.state.billingCycles[0]?.billingCycleStart
                              )
                            : utils.toVacationDate(this.state.startDate)
                          : utils.toVacationDate(this.state.endDate)}
                      </div>
                    </button>
                  )}
                </div>
                {this.state.selectedChartTimeTypeIndex !== 2 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "50%",
                    }}
                  >
                    <div className="fromTo">To</div>
                    {this.state.selectedChartTimeTypeIndex === 0 ? (
                      <DatePicker
                        locale="en"
                        onChange={(endDate) => {
                          this.closeCalendarPicker(
                            !this.state.selectedChartTimeTypeIndex
                              ? moment(endDate).diff(
                                  this.state.startDate,
                                  "months"
                                ) > MONTH_COUNT
                                ? moment(endDate).subtract(MONTH_COUNT, "month")
                                : this.state.startDate
                              : moment(endDate).diff(
                                  this.state.dailyStartDate,
                                  "days"
                                ) > 30
                              ? moment(endDate).subtract(31, "days")
                              : this.state.dailyStartDate,
                            endDate
                          );
                        }}
                        clearIcon={null}
                        maxDetail={
                          !this.state.selectedChartTimeTypeIndex
                            ? "year"
                            : "month"
                        }
                        format={
                          !this.state.selectedChartTimeTypeIndex
                            ? "yyyy,MMM"
                            : "yyyy,MMM, d"
                        }
                        value={utils.toDateFromString(
                          !this.state.selectedChartTimeTypeIndex
                            ? this.state.endDate
                            : this.state.dailyEndDate
                        )}
                        calendarIcon={<img src={images.icSmallCalendar} />}
                        // maxDate={new Date()}
                        maxDate={
                          this.state.billingCycles[0].billingCycleStart !==
                            "" &&
                          moment(
                            this.state.billingCycles[0].billingCycleStart
                          ).diff(moment.now()) <= 0
                            ? new Date(
                                this.state.billingCycles[0].billingCycleStart
                              )
                            : new Date()
                        }
                        minDate={utils.toDateFromString(
                          !this.state.selectedChartTimeTypeIndex
                            ? this.state.startDate
                            : this.state.dailyStartDate
                        )}
                        minDetail={"year"}
                      />
                    ) : (
                      <button
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginLeft: -8,
                          marginTop: 8,
                        }}
                        onClick={this.onChooseDate.bind(this)}
                      >
                        <img src={images.icSmallCalendar} />
                        <div
                          placeholder="Choose date"
                          disabled={true}
                          type="text"
                          className="chooseDateInput"
                          style={{
                            marginLeft: 8,
                            border: 0,
                            paddingLeft: 10,
                            paddingRight: 10,
                            flexShrink: 0,
                            fontSize: 13,
                          }}
                        >
                          {this.state.selectedChartTimeTypeIndex === 0
                            ? utils.toMonthDate(this.state.endDate)
                            : utils.toVacationDate(this.state.endDate)}
                        </div>
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          {this.state.selectedChartTypeIndex === 0 && (
            <div
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                marginTop: 20,
                marginLeft: 30,
                marginRight: 30,
              }}
              className="chartActions"
            >
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                }}
              >
                <button onClick={() => this.setState({ chartShape: "bar" })}>
                  <img
                    src={
                      this.state.chartShape === "bar"
                        ? images.icBarChartSelected
                        : images.icBarChart
                    }
                  />
                </button>
                <button onClick={() => this.setState({ chartShape: "table" })}>
                  <img
                    style={{ width: 20, height: 20 }}
                    src={
                      this.state.chartShape === "table"
                        ? images.icTableChartSelected
                        : images.icTableChart
                    }
                  />
                </button>
              </div>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                }}
              >
                <button onClick={() => this.onPrint()}>
                  <img src={images.icPrinter} />
                </button>
                <button onClick={() => this.onExcel()}>
                  <img src={images.icExcel} />
                </button>
              </div>
            </div>
          )}
          <MeterChart
            selectedMeter={this.state.currentMeter}
            units={this.state.units}
            startDate={
              this.state.selectedChartTimeTypeIndex
                ? this.state.dailyStartDate
                : this.state.startDate
            }
            endDate={
              this.state.selectedChartTimeTypeIndex
                ? this.state.dailyEndDate
                : this.state.endDate
            }
            ref={(el) => (this.chartRef = el)}
            neighborData={this.state.neighborConsumptions}
            type={this.state.selectedChartTypeIndex}
            timeType={this.state.selectedChartTimeTypeIndex}
            data={chartData}
            yearsConsumptions={this.state.yearsConsumptions}
            style={{ marginTop: 16 }}
            chartShape={this.state.chartShape}
            presentationMode={this.state.presentationMode}
          />
        </div>

        <Popup
          contentStyle={{ borderRadius: 5, width: "30%" }}
          closeOnDocumentClick={false}
          open={this.state.openMonthlyLimit}
          lockScroll={true}
        >
          <MonthlyLimitPopup
            meterCount={this.state?.currentMeter?.meterCount}
            monthlyLimit={this.state.monthlyLimit}
            onClose={this.closeMonthlyLimit.bind(this)}
          />
        </Popup>

        <Popup
          contentStyle={{ borderRadius: 8, width: "440px" }}
          open={this.state.openDefineVacation}
          closeOnDocumentClick={false}
          lockScroll={true}
        >
          <DefineVacationPopup
            vacation={this.state.currentMeterVacation}
            onClose={this.closeDefineVacation.bind(this)}
            currentMeter={this.state.currentMeter}
          />
        </Popup>

        <Popup
          contentStyle={{ borderRadius: 5 }}
          closeOnDocumentClick={false}
          open={this.state.openMeterSelection}
          onClose={this.closeMeterSelection.bind(this)}
          lockScroll={true}
        >
          <MeterSelection
            waterMeters={this.meters}
            onMeterSelected={this.onMeterSelected.bind(this)}
            onClose={this.closeMeterSelection.bind(this)}
          />
        </Popup>

        <Popup
          closeOnDocumentClick={false}
          contentStyle={{ borderRadius: 5 }}
          open={this.state.openCalendarPicker}
          lockScroll={true}
        >
          <CalendarRangePicker
            isMonthSelection={this.state.selectedChartTimeTypeIndex === 0}
            singleDay={this.state.selectedChartTimeTypeIndex === 2}
            startDate={
              this.state.startDate
                ? utils.toDateFromString(this.state.startDate)
                : null
            }
            endDate={
              this.state.endDate
                ? utils.toDateFromString(this.state.endDate)
                : null
            }
            onClose={this.closeCalendarPicker.bind(this)}
            billingCycle={this.state.billingCycles[0]?.billingCycleStart}
          />
        </Popup>

        <Popup
          contentStyle={{ borderRadius: 5 }}
          open={this.state.errorMessage}
          onClose={this.closeErrorPopup.bind(this)}
          lockScroll={true}
        >
          <AppPopup
            onClose={this.closeErrorPopup.bind(this)}
            message={this.state.errorMessage}
          />
        </Popup>

        {/*<ReactToPrint*/}
        {/*trigger={() => this.printButtonRef}*/}
        {/*content={() => this.chartRef}*/}
        {/*/>*/}
      </div>
    );
  }

  closeErrorPopup() {
    this.setState({ errorMessage: null });
  }

  onChooseDate() {
    this.setState({ openCalendarPicker: true });
  }

  closeCalendarPicker = (startDate, endDate) => {
    // 0 - months, 1 - days, 2 - hours
    const { currentMeter, selectedChartTimeTypeIndex } = this.state;

    if (selectedChartTimeTypeIndex === 2 && startDate) {
      endDate = startDate;
    }

    let isDefault = this.state.isDefault;
    if (isDefault && startDate && endDate) {
      isDefault = false;
    }

    if (selectedChartTimeTypeIndex) {
      this.setState(
        {
          isDefault,
          openCalendarPicker: false,
          isCalendarDateSelected: true,
          startDate: startDate
            ? startDate
            : utils.toDateString(this.state.startDate),
          endDate: endDate
            ? moment.min(moment(this.today), moment(endDate))
            : utils.toDateString(this.state.endDate),
        },
        () => {
          currentMeter
            ? this._getDataForMeter(currentMeter, false)
            : this._getDataForAllMeters(false);
        }
      );
    } else {
      this.setState(
        {
          openCalendarPicker: false,
          isCalendarDateSelected: true,
          startDate: moment(startDate).locale('en').format("YYYY-MM-DD"),
          endDate: utils.getEndOfMonthDate(endDate),
        },
        () => {
          currentMeter
            ? this._getDataForMeter(currentMeter, false)
            : this._getDataForAllMeters(false);
        }
      );
    }
  };

  onExcel = () => {
    if (this.state.selectedChartTimeTypeIndex === 0) {
      //Monthly
      api.getAllMonthlyConsumptionExcel(
        this.state?.currentMeter?.meterCount ?? null,
        utils.toDateString(this.state?.startDate) ?? utils.getMonthsBefore(this.state.endDate, MONTH_COUNT),
        utils.toDateStringEndMonth(this.state.endDate),
        (res, err) => {
          if (err) {
            console.log("Unknown error has occurred.");
          } else {
            if (res.error) {
              console.log(res.error);
            } else {
              FileSaver.saveAs(res, "monthly_consumption.csv");
            }
          }
        }
      );
    } else if (this.state.selectedChartTimeTypeIndex === 1) {
      //Daily
      if (this.state.isDefault) {
        const startDate = moment().format("MM-01-YYYY");
        const endDate = moment().format("MM-DD-YYYY");
        api.getAllDailyConsumptionExcel(
          this.state?.currentMeter?.meterCount ?? null,
          startDate,
          endDate,
          (res, err) => {
            if (err) {
              console.log("Unknown error has occurred.");
            } else {
              if (res.error) {
                console.log(res.error);
              } else {
                FileSaver.saveAs(res, "daily_consumption.csv");
              }
            }
          }
        );
      } else {
        api.getAllDailyConsumptionExcel(
          this.state?.currentMeter?.meterCount ?? null,
          utils.toDateString(this.state.startDate),
          utils.toDateString(this.state.endDate),
          (res, err) => {
            if (err) {
              console.log("Unknown error has occurred.");
            } else {
              if (res.error) {
                console.log(res.error);
              } else {
                FileSaver.saveAs(res, "daily_consumption.csv");
              }
            }
          }
        );
      }
    } else {
      //Hourly
      api.getAllHourlyConsumptionExcel(
        this.state?.currentMeter?.meterCount ?? null,
        utils.getDaysBefore(this.state.endDate),
        utils.toDateString(this.state.endDate),
        (res, err) => {
          if (err) {
            console.log("Unknown error has occurred.");
          } else {
            if (res.error) {
              console.log(res.error);
            } else {
              FileSaver.saveAs(res, "hourly_consumption.csv");
            }
          }
        }
      );
    }
  };

  onPrint = () => {
    window.print();
  };

  onMeterSelected = (meter) => {
    let chartTypes = ["WATER CONSUMPTION", "COMPARE TO PREVIOUS YEAR"];
    if (!meter) {
      chartTypes.splice(1, 0, "ME VS. SIMILAR HOUSEHOLDS");
    }
    meter
      ? this._getDataForMeter(meter)
      : this._getDataForAllMeters({ isUpdateThisMonth: true });
    this.setState({
      openMeterSelection: false,
      currentMeter: meter,
      chartTypes,
      currentMeterVacation:
        this.state.vacations?.find(
          (item) => item.meterCount == meter?.meterCount
        ) || null,
    });
    document.body.style.overflow = "unset";
  };

  onTabSelected = (index) => {};

  openDefineVacation = () => {
    this.setState({ openDefineVacation: true });
  };

  closeDefineVacation = (vacation) => {
    console.log("closeDefineVacation", vacation);
    if (vacation) {
      let newVacation = vacation;
      const editedVacationIndex = this.state.vacations?.findIndex(
        (item) => item?.vacationID === vacation?.vacationID
      );
      if (
        this.state?.vacation?.vacationID === vacation?.vacationID &&
        editedVacationIndex > -1 &&
        this.state?.vacations
      ) {
        const newVacations = this.state?.vacations?.map((item) =>
          item.vacationID !== vacation.vacationID ? item : vacation
        );
        newVacations.sort(
          (a, b) => new Date(a.startDate) - new Date(b.startDate)
        );
        newVacation = newVacations[0];
      } else if (this.state?.vacations && editedVacationIndex === -1) {
        const newVacations = [...this.state.vacations, vacation];
        newVacations.sort(
          (a, b) => new Date(a.startDate) - new Date(b.startDate)
        );
        newVacation = newVacations[0];
      }
      this.setState(
        {
          openDefineVacation: false,
          // vacation: newVacation,
          currentMeterVacation: vacation,
          vacations:
            editedVacationIndex > -1 && this.state?.vacations
              ? this.state?.vacations?.map((item) =>
                  item.vacationID !== vacation.vacationID ? item : vacation
                )
              : this.state?.vacations
              ? [...this.state.vacations, vacation]
              : [vacation],
        },
        () => console.log("done")
      );

      if (
        typeof this.state.vacation !== "undefined" &&
        this.state.vacation !== null
      ) {
        console.log("vacation", vacation);
        console.log("this.state.vacation", this.state.vacation);

        let newVacationDate = moment(vacation.startDate); //now
        let oldVacationDate = moment(this.state.vacation.startDate);

        console.log(
          newVacationDate,
          oldVacationDate,
          newVacationDate.diff(oldVacationDate, "minutes")
        );

        if (newVacationDate.diff(oldVacationDate, "minutes") < 0) {
          this.setState({ vacation: newVacation });
        }
      } else {
        this.setState({ vacation: newVacation });
      }
    } else {
      this.setState({
        openDefineVacation: false,
      });
    }
  };

  openMonthlyLimit = () => {
    this.setState({ openMonthlyLimit: true });
  };

  getMonthlyLimit = () => {
    if (this.state.currentMeter === null) {
      api.getMonthlyLimitAllMeters((res, err) => {
        if (err) {
          console.log("Unknown error has occurred.");
        } else {
          if (res.error) {
            console.log(res.error);
          } else {
            console.log("getMonthlyLimitAllMeters", res);
            this.setState({
              monthlyLimit: res.limit && res.limit !== "null" ? res.limit : 0,
            });
          }
        }
      });
    } else {
      api.getMonthlyLimit(this.state.currentMeter.meterCount, (res, err) => {
        if (err) {
          console.log("Unknown error has occurred.");
        } else {
          if (res.error) {
            console.log(res.error);
          } else {
            console.log("api.getMonthlyLimit", res);
            this.setState({
              monthlyLimit: res.limit && res.limit !== "null" ? res.limit : 0,
            });
          }
        }
      });
    }
  };

  closeMonthlyLimit = (monthlyLimit) => {
    if (monthlyLimit !== undefined) {
      if (monthlyLimit === "") {
        this.setState({ openMonthlyLimit: false, monthlyLimit: null });
      } else {
        this.setState({ openMonthlyLimit: false });
      }
    } else {
      this.setState({
        openMonthlyLimit: false,
        // monthlyLimit: this.state.monthlyLimit,
      });
    }

    setTimeout(() => this.getMonthlyLimit(), 1000);
  };

  closeMeterSelection = () => {
    this.setState({ openMeterSelection: false });
    document.body.style.overflow = "unset";
  };

  onChartTypeChange = (index) => {
    this.setState({ selectedChartTypeIndex: index });
  };

  onChartTimeChange = (index) => {
    console.log("onChartTimeChange");
    const startDate =
      index === 0
        ? moment(this.today).subtract(MONTH_COUNT, "months")
        : this.state.dailyDefaultStartDate;
    const endDate = index === 0 ? this.today : this.state.dailyDefaultEndDate;
    const monthlyConsumptions =
      index === 0 ? [] : this.state.monthlyConsumptions;
    const dailyConsumptions = index === 1 ? [] : this.state.dailyConsumptions;
    const hourlyConsumptions = index === 2 ? [] : this.state.hourlyConsumptions;

    this.setState(
      {
        selectedChartTimeTypeIndex: index,
        startDate,
        endDate,
        monthlyConsumptions,
        dailyConsumptions,
        hourlyConsumptions,
      },
      () => {
        if (index === 0) {
          //monthly
          if (this.state.currentMeter !== null) {
            api.getMeterMonthlyConsumption(
              this.state.currentMeter.meterCount,
              utils.getMonthsBeforeWithDay(this.state.endDate, MONTH_COUNT),
              moment().format("MM-DD-YYYY"),
              (res, err) => {
                if (err) {
                  console.log("Unknown error has occurred.");
                } else {
                  if (res.error) {
                    console.log(res.error);
                  } else {
                    console.log("thisMonthUsage", res);
                    this.setState({
                      monthlyConsumptions: res.consumptionData,
                      presentationMode: res.presentationMode,
                    });
                  }
                }
              }
            );
          } else {
            api.getAllMonthlyConsumption(
              utils.getMonthsBeforeWithDay(this.state.endDate, MONTH_COUNT),
              moment().format("MM-DD-YYYY"),
              (res, err) => {
                if (err) {
                  console.log("Unknown error has occurred.");
                } else {
                  if (res.error) {
                    console.log(res.error);
                  } else {
                    console.log("getAllMonthlyConsumption 1");
                    this.setState({
                      monthlyConsumptions: res.consumptionData,
                      presentationMode: res.presentationMode,
                    });
                  }
                }
              }
            );
          }
        } else if (index === 1) {
          //daily
          if (this.meters.length === 1) {
            let { billingCycleStart, billingCycleEnd } =
              this.state.billingCycles[0];
            const startDate = moment(billingCycleStart).format("MM-DD-YYYY");
            const endDate = moment().format("MM-DD-YYYY");
            api.getMeterDailyConsumption(
              this.state.currentMeter.meterCount,
              startDate,
              endDate,
              (res, err) => {
                console.log(res);
                if (err) {
                  console.log("Unknown error has occurred.");
                } else {
                  if (res.error) {
                    console.log(res.error);
                  } else {
                    console.log(
                      "res getMeterDailyConsumption",
                      res,
                      startDate,
                      endDate
                    );
                    if (res && res.length > 0) {
                      const dailyDefaultStartDate = moment(res[0].consDate);
                      const dailyDefaultEndDate = moment(
                        res[res.length - 1].consDate
                      );
                      console.log("dailyConsumptions 11", res);
                      this.setState({
                        dailyConsumptions: res,
                        dailyDefaultStartDate,
                        dailyDefaultEndDate,
                      });
                    } else {
                      console.log("dailyConsumptions 11", res);
                      this.setState({ dailyConsumptions: res });
                    }
                  }
                }
              }
            );
          } else {
            if (this.state.currentMeter !== null) {
              let { billingCycleStart, billingCycleEnd } =
                this.state.billingCycles[0];
              const startDate = moment(billingCycleStart).format("MM-DD-YYYY");
              const endDate = moment().format("MM-DD-YYYY");
              api.getMeterDailyConsumption(
                this.state.currentMeter.meterCount,
                startDate,
                endDate,
                (res, err) => {
                  console.log(res);
                  if (err) {
                    console.log("Unknown error has occurred.");
                  } else {
                    if (res.error) {
                      console.log(res.error);
                    } else {
                      console.log(
                        "res getMeterDailyConsumption",
                        res,
                        startDate,
                        endDate
                      );
                      if (res && res.length > 0) {
                        const dailyDefaultStartDate = moment(res[0].consDate);
                        const dailyDefaultEndDate = moment(
                          res[res.length - 1].consDate
                        );
                        console.log("dailyConsumptions 11", res);
                        this.setState({
                          dailyConsumptions: res,
                          dailyDefaultStartDate,
                          dailyDefaultEndDate,
                        });
                      } else {
                        console.log("dailyConsumptions 11", res);
                        this.setState({ dailyConsumptions: res });
                      }
                    }
                  }
                }
              );
            } else {
              let { billingCycleStart, billingCycleEnd } =
                this.state.billingCycles[0];
              const startDate = moment(billingCycleStart).format("MM-DD-YYYY");
              const endDate = moment().format("MM-DD-YYYY");

              api.getAllDailyConsumption(startDate, endDate, (res, err) => {
                if (err) {
                  console.log("Unknown error has occurred.");
                } else {
                  if (res.error) {
                    console.log(res.error);
                  } else {
                    if (res && res.length > 0) {
                      const dailyDefaultStartDate = moment(res[0].consDate);
                      const dailyDefaultEndDate = moment(
                        res[res.length - 1].consDate
                      );
                      console.log("dailyConsumptions 11", res);
                      this.setState({
                        dailyConsumptions: res,
                        dailyDefaultStartDate,
                        dailyDefaultEndDate,
                      });
                    } else {
                      console.log("dailyConsumptions 11", res);
                      this.setState({ dailyConsumptions: res });
                    }
                  }
                }
              });
            }
          }
        } else {
          //hourly
          const yesterday = moment().subtract(1, "day");
          this.setState({
            startDate: yesterday,
            endDate: yesterday,
          });

          api.getAllHourlyConsumption(
            utils.getDaysBefore(yesterday),
            utils.toDateString(yesterday),
            (res, err) => {
              if (err) {
                console.log("Unknown error has occurred.");
              } else {
                if (res.error) {
                  console.log(res.error);
                } else {
                  this.setState({ hourlyConsumptions: res });
                }
              }
            }
          );
        }
      }
    );
  };

  _getDataForAllMeters({ isUpdateThisMonth = true, isInitial = false }) {
    console.log("_getDataForAllMeters");
    console.log("isUpdateThisMonth");

    api.getAllMetersBillingCycleDates((res, err) => {
      if (err) {
        console.log("Unknown error has occurred.");
      } else {
        if (res.error) {
          console.log(res.error);
        } else {
          console.log("getAllMetersBillingCycleDates, billingCycles", res);
          {
            this.setState({ billingCycles: res }); // startDate: res[0].billingCycleStart

            if (isUpdateThisMonth) {
              console.log(
                "thisMonthUsage billingCycles original dates",
                res[0].billingCycleStart,
                res[0].billingCycleEnd
              );
              console.log(
                "thisMonthUsage billingCycles",
                utils.getMonth(res[0].billingCycleStart),
                utils.getMonth(res[0].billingCycleEnd)
              );
              api.getAllMonthlyConsumption(
                utils.toDateString(res[0].billingCycleStart), // toDateString
                // utils.toDateString(res[0].billingCycleEnd),
                moment().format("YYYY-MM-DD"),
                (res2, err) => {
                  if (err) {
                    console.log("Unknown error has occurred.");
                  } else {
                    if (res2.error) {
                      console.log(res2.error);
                    } else {
                      console.log(
                        "thisMonthUsage billingCycles",
                        JSON.stringify(res2)
                      );
                      console.log("getAllMonthlyConsumption 2");
                      this.setState({
                        thisMonthUsage:
                          Array.isArray(res2.consumptionData) &&
                          typeof res2.consumptionData[
                            res2.consumptionData.length - 1
                          ] !== "undefined" &&
                          typeof res2.consumptionData[
                            res2.consumptionData.length - 1
                          ].cons !== "undefined" &&
                          res2.consumptionData[res2.consumptionData.length - 1]
                            .cons !== null
                            ? res2.consumptionData[
                                res2.consumptionData.length - 1
                              ].cons?.toFixed(0)
                            : "No Data",
                      });
                    }
                  }
                }
              );
            }

            api.getAllMonthlyConsumption(
              // utils.getMonthsBefore(this.state.endDate, 36),
              utils.getMonthsBeforeWithDay(new Date(), MONTH_COUNT),
              moment().format("YYYY-MM-DD"),
              (res, err) => {
                if (err) {
                  console.log("Unknown error has occurred.");
                } else {
                  if (res.error) {
                    console.log(res.error);
                  } else {
                    console.log("getAllMonthlyConsumption 4");
                    this.setState({
                      // yearsConsumptions: res.consumptionData,
                      presentationMode: res.presentationMode,
                    });
        
                    if (isInitial) {
                      this.setState({
                        monthlyConsumptions: res.consumptionData,
                        monthlyConsumptionsForNeighborhood: res.consumptionData,
                      });
                    }
                  }
                }
              }
            );

            if (!isInitial) {
              api.getAllMonthlyConsumption(
                utils.toDateString(this.state.startDate),
                utils.toDateString(this.state.endDate),
                (res, err) => {
                  if (err) {
                    console.log("Unknown error has occurred.");
                  } else {
                    if (res.error) {
                      console.log(res.error);
                    } else {
                      console.log("getAllMonthlyConsumption 3");
                      this.setState({
                        monthlyConsumptions: res.consumptionData,
                        presentationMode: res.presentationMode,
                      });
                    }
                  }
                }
              );
            }
          }
        }
      }
    });

    api.getMonthlyLimitAllMeters((res, err) => {
      if (err) {
        console.log("Unknown error has occurred.");
      } else {
        if (res.error) {
          console.log(res.error);
        } else {
          console.log("getMonthlyLimitAllMeters", res);
          this.setState({
            monthlyLimit: res.limit && res.limit !== "null" ? res.limit : 0,
          });
        }
      }
    });

    api.getAllHourlyConsumption(
      utils.getDaysBefore(this.state.endDate),
      utils.toDateString(this.state.endDate),
      (res, err) => {
        if (err) {
          console.log("Unknown error has occurred.");
        } else {
          if (res.error) {
            console.log(res.error);
          } else {
            this.setState({ hourlyConsumptions: res });
          }
        }
      }
    );

    //Get monthly forecast
    api.getAllMonthlyForecast((res, err) => {
      if (err) {
        console.log("Unknown error has occurred.");
      } else {
        if (res.error) {
          console.log(res.error);
        } else {
          console.log("forecastUsage", res);
          this.setState({
            forecastUsage: res.estimatedConsumption?.toFixed(0),
          });
        }
      }
    });

    //Get daily consumption
    if (this.state.isDefault) {
      const startDate = moment().format("MM-01-YYYY");
      const endDate = moment().format("MM-DD-YYYY");
      api.getAllDailyConsumptionBasedOnBillingCycle((res, err) => {
        if (err) {
          console.log("Unknown error has occurred.");
        } else {
          if (res.error) {
            console.log(res.error);
          } else {
            console.log("dailyConsumptions 3", res);
            this.setState({ dailyConsumptions: res });
          }
        }
      });
    } else {
      console.log(this.state.startDate);
      console.log(this.state.endDate);
      api.getAllDailyConsumption(
        utils.toDateString(this.state.startDate),
        utils.toDateString(this.state.endDate),
        (res, err) => {
          console.log(res);
          if (err) {
            console.log("Unknown error has occurred.");
          } else {
            if (res.error) {
              console.log(res.error);
            } else {
              console.log("dailyConsumptions 5", res);
              this.setState({ dailyConsumptions: res });
            }
          }
        }
      );
    }

    //Get all years consumption
    api.getAllMonthlyConsumption(
      utils.getMonthsBefore(new Date(), 36),
      utils.getMonth(new Date()),
      (res, err) => {
        if (err) {
          console.log("Unknown error has occurred.");
        } else {
          if (res.error) {
            console.log(res.error);
          } else {
            this.setState({
              yearsConsumptions: res.consumptionData,
            });
          }
        }
      }
    );
  }

  _getDataForMeter(meter, isUpdateThisMonth = true) {
    api.getMeterBillingCycleDates(meter.meterCount, (res, err) => {
      if (err) {
        console.log("Unknown error has occurred.");
      } else {
        if (res.error) {
          console.log(res.error);
        } else {
          console.log("_getDataForMeter, billingCycles", res);
          this.setState({ billingCycles: res });

          if (isUpdateThisMonth) {
            api.getMeterMonthlyConsumption(
              meter.meterCount,
              utils.toDateString(res[0].billingCycleStart), // toDateString
              // utils.toDateString(res[0].billingCycleEnd),
              moment().format("YYYY-MM-DD"),
              (res2, err) => {
                if (err) {
                  console.log("Unknown error has occurred.");
                } else {
                  if (res2.error) {
                    console.log(res2.error);
                  } else {
                    console.log("getMeterMonthlyConsumption", res2);
                    console.log(
                      "getMeterMonthlyConsumption",
                      Array.isArray(res2.consumptionData) &&
                        res2.consumptionData[res2.consumptionData.length - 1] &&
                        res2.consumptionData[res2.consumptionData.length - 1]
                          .cons
                    );
                    this.setState({
                      thisMonthUsage:
                        Array.isArray(res2.consumptionData) &&
                        typeof res2.consumptionData[
                          res2.consumptionData.length - 1
                        ] !== "undefined" &&
                        typeof res2.consumptionData[
                          res2.consumptionData.length - 1
                        ].cons !== "undefined" &&
                        res2.consumptionData[res2.consumptionData.length - 1]
                          .cons !== null
                          ? res2.consumptionData[
                              res2.consumptionData.length - 1
                            ].cons?.toFixed(0)
                          : "No Data",
                          presentationMode: res2.presentationMode,
                    });
                  }
                }
              }
            );
          }

          api.getMeterMonthlyConsumption(
            meter.meterCount,
            utils.toDateString(this.state.startDate),
            utils.toDateString(this.state.endDate),
            (res, err) => {
              if (err) {
                console.log("Unknown error has occurred.");
              } else {
                if (res.error) {
                  console.log(res.error);
                } else {
                  this.setState({
                    monthlyConsumptions: res.consumptionData,
                    presentationMode: res.presentationMode,
                  });
                }
              }
            }
          );

          if (this.state.isDefault) {
            api.getMeterDailyConsumption(
              meter.meterCount,
              utils.toDateString(res[0].billingCycleStart), // toDateString
              moment().format("YYYY-MM-DD"),
              (res, err) => {
                console.log(res);
                if (err) {
                  console.log("Unknown error has occurred.");
                } else {
                  if (res.error) {
                    console.log(res.error);
                  } else {
                    console.log("dailyConsumptions 1", res);
                    this.setState({ dailyConsumptions: res });
                  }
                }
              }
            );
          } else {
            api.getMeterDailyConsumption(
              meter.meterCount,
              utils.toDateString(this.state.startDate),
              utils.toDateString(this.state.endDate),
              (res, err) => {
                if (err) {
                  console.log("Unknown error has occurred.");
                } else {
                  if (res.error) {
                    console.log(res.error);
                  } else {
                    console.log("dailyConsumptions 2", res);
                    this.setState({ dailyConsumptions: res });
                  }
                }
              }
            );
          }
        }
      }
    });

    api.getMonthlyLimit(meter.meterCount, (res, err) => {
      if (err) {
        console.log("Unknown error has occurred.");
      } else {
        if (res.error) {
          console.log(res.error);
        } else {
          this.setState({
            monthlyLimit: res.limit && res.limit !== "null" ? res.limit : 0,
          });
        }
      }
    });

    api.getMeterHourlyConsumption(
      meter.meterCount,
      utils.getDaysBefore(this.state.endDate),
      utils.toDateString(this.state.endDate),
      (res, err) => {
        if (err) {
          console.log("Unknown error has occurred.");
        } else {
          if (res.error) {
            console.log(res.error);
          } else {
            this.setState({ hourlyConsumptions: res });
          }
        }
      }
    );

    api.getMeterMonthlyForecast(meter.meterCount, (res, err) => {
      if (err) {
        console.log("Unknown error has occurred.");
      } else {
        if (res.error) {
          console.log(res.error);
        } else {
          console.log("forecastUsage", res);
          this.setState({
            forecastUsage: res.estimatedConsumption?.toFixed(0),
          });
        }
      }
    });

    api.getMeterMonthlyConsumption(
      meter.meterCount,
      utils.getMonthsBefore(new Date(), 36),
      utils.getMonth(new Date()),
      (res, err) => {
        if (err) {
          console.log("Unknown error has occurred.");
        } else {
          if (res.error) {
            console.log(res.error);
          } else {
            this.setState({ yearsConsumptions: res.consumptionData });
          }
        }
      }
    );
  }

  componentWillUnmount() {
    clearInterval(this.alertsTimers);
  }

  componentWillMount() {
    console.log("cwm");

    //Get consumer vacations
    api.getVacations((res, err) => {
      if (err) {
        console.log("Unknown error has occurred.");
      } else {
        if (res.error) {
          console.log(res.error);
        } else {
          if (res.length > 0) {
            res.sort((a, b) => new Date(a.startDate) - new Date(b.startDate));
            const vacation = res[0];
            DataManager.sharedInstance.vacation = vacation;
            this.setState({
              vacation,
              vacations: res,
            });
          }
        }
      }

      //Get consumer meters
      api.getMeters((res, err) => {
        if (err) {
          console.log("Unknown error has occurred.");
        } else {
          if (res.error) {
            console.log(res.error);
          } else {
            this.meters = res;
            DataManager.sharedInstance.meters = this.meters;

            console.log("get all meters", res);

            if (this.meters.length === 1) {
              //Only one meter exists
              this._getDataForMeter(this.meters[0], true);
              this.setState({
                currentMeter: this.meters[0],
                chartTypes: ["WATER CONSUMPTION", "COMPARE TO PREVIOUS YEAR"],
                currentMeterVacation: this.state.vacations?.find(
                  (item) => item.meterCount == this.meters[0].meterCount
                ),
              });
            } else {
              this._getDataForAllMeters({ isInitial: true });

              this.setState({
                currentMeter: null,
                chartTypes: [
                  "WATER CONSUMPTION",
                  "ME VS. SIMILAR HOUSEHOLDS",
                  "COMPARE TO PREVIOUS YEAR",
                ],
                currentMeterVacation: null,
              });
            }
          }
        }
      });

      this.setState({
        isLoading: true,
        startDate: utils.toDateString(this.state.startDate),
        endDate: utils.toDateString(this.state.endDate),
        vacation: DataManager.sharedInstance.vacation
          ? DataManager.sharedInstance.vacation
          : null,
      });

      api.getAlerts((res, err) => {
        if (err) {
          this.setState({ errorMessage: "Unknown error has occurred." });
        } else {
          if (res.error) {
          } else {
            let alerts = res.filter(
              (item) =>
                DataManager.sharedInstance.clearedAlerts.filter(
                  (alert) => alert.logId === item.logId
                ).length === 0
            );
            this.props.onAlerts(alerts.length);
            this.setState({ alerts });
          }
        }
      });
      this.alertsTimers = setInterval(() => {
        api.getAlerts((res, err) => {
          if (err) {
            console.log("setInterval error Home.js");
          } else {
            if (res.error) {
            } else {
              let alerts = res.filter(
                (item) =>
                  DataManager.sharedInstance.clearedAlerts.filter(
                    (alert) => alert.logId === item.logId
                  ).length === 0
              );
              this.props.onAlerts(alerts.length);
              this.setState({ alerts });
            }
          }
        });
      }, ALERTS_INTERVAL);

      //Get municipal customer service info
      api.getMunicipalCustomerService((res, err) => {
        if (err) {
          console.log("Unknown error has occurred.");
        } else {
          if (res.error) {
            console.log(res.error);
          } else {
            DataManager.sharedInstance.muni = res;
          }
        }
      });

      // //Get neighbors consumption
      api.getAvgHouseHoldsConsumption(
        utils.getMonthsBefore(this.state.endDate, MONTH_COUNT),
        utils.getMonth(this.state.endDate),
        (res, err) => {
          if (err) {
            console.log("Unknown error has occurred.");
          } else {
            if (res.error) {
              console.log(res.error);
            } else {
              this.setState({ neighborConsumptions: res });
            }
          }
        }
      );

      //Get measurement usings
      api.getMeasurementUnits((res, err) => {
        if (err) {
          console.log("Unknown error has occurred.");
        } else {
          if (res.error) {
            console.log(res.error);
          } else {
            DataManager.sharedInstance.units = res.unitShortName;
            this.setState({ units: res.unitShortName });
          }
        }
      });
    });
  }

  _renderMobile() {
    let meterDesc = "";
    if (!this.state.currentMeter) {
      meterDesc = `All Water Meters (${this.meters.length})`;
    } else {
      const meter = this.state.currentMeter;
      meterDesc = utils.getMeterDesc(meter);
    }

    let { billingCycleStart, billingCycleEnd } = this.state.billingCycles[0];

    // 04-01-2022 - 04-30-2022
    const startDate = moment(billingCycleStart).format("MM-DD-YYYY");
    const endDate = moment(billingCycleEnd).subtract(1, "days").format("MM-DD-YYYY");

    console.log(
      `billingCycleStart ${billingCycleStart} billingCycleEnd ${billingCycleEnd}`
    );

    let billingCycleText =
      billingCycleStart && billingCycleEnd
        ? `Billing Cycle Date: ${startDate} - ${endDate}`
        : "";

    let chartData;
    switch (this.state.selectedChartTypeIndex) {
      case 0: {
        //My
        switch (this.state.selectedChartTimeTypeIndex) {
          case 0:
            //Month
            chartData = this.state.monthlyConsumptions;
            break;
          case 1:
            //Day
            chartData = this.state.dailyConsumptions;
            break;
          case 2:
            //Hourly
            chartData = this.state.hourlyConsumptions;
            break;
        }
        break;
      }
      case 1: {
        //Neighbor
        chartData = this.state.monthlyConsumptionsForNeighborhood;
        break;
      }
      case 2: {
        //Year
        chartData = this.state.monthlyConsumptionsForNeighborhood;
        break;
      }
    }
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginTop: 8,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div
              style={{
                flexDirection: "column",
                display: "flex",
                flex: 1,
                alignItems: "center",
              }}
            >
              <div className="myWaterUsage myWaterUsageMob">
                Water Usage for Billing Cycle
              </div>
              {/* <div className="myWaterUsageDate myWaterUsageDateMob">
                {billingCycleText}
              </div> */}
            </div>
            <button
              style={{
                flexDirection: "column",
                display: "flex",
                alignItems: "center",
                flex: 1,
                marginTop: 10,
                backgroundColor: "transparent",
              }}
              onClick={() =>
                this.setState(
                  { openMeterSelection: true },
                  () => (document.body.style.overflow = "hidden")
                )
              }
            >
              <div
                className="waterMeterTitle"
                style={{
                  alignSelf: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Water Meter
              </div>
              <div
                className="waterMeterValue waterMeterValueMob"
                style={{
                  alignSelf: "center",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 4,
                }}
              >
                {meterDesc}
              </div>
            </button>
          </div>

          <div
            className="waterUsageSepHor"
            style={{
              marginLeft: 40,
              marginRight: 40,
              marginTop: 10,
            }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 20,
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="thisMonthLabel">Current Billing Cycle</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    className={
                      this.state.monthlyLimit &&
                      this.state.thisMonthUsage > this.state.monthlyLimit
                        ? "monthlyLimitValueLabel monthlyLimitValueLabelMob"
                        : "thisMonthValue thisMonthValueMob"
                    }
                  >
                    {this.state.thisMonthUsage}
                  </div>
                  {this.state.thisMonthUsage !== "No Data" && (
                    <div
                      className="thisMonthUnits thisMonthUnitsMob"
                      style={{
                        marginLeft: 2,
                        alignSelf: "flex-end",
                        marginBottom: 8,
                      }}
                    >
                      {this.state.units.toLowerCase()}
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: 20,
                }}
              >
                <div className="endOfMonthForecastLabel">
                  End of Billing Cycle Forecast
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div className="endOfMonthForecastValue endOfMonthForecastValueMob">
                    {this.state.forecastUsage}
                  </div>
                  <div
                    className="endOfMonthForecastUnits endOfMonthForecastUnitsMob"
                    style={{
                      marginLeft: 2,
                      alignSelf: "flex-end",
                      marginBottom: 8,
                    }}
                  >
                    {this.state.units.toLowerCase()}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {this.state.monthlyLimit ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <div className="monthlyLimitLabel">Billing Cycle Limit:</div>
              <div className="monthlyLimitValue">{this.state.monthlyLimit}</div>
              {this.state.currentMeter === null ? (
                <>
                  <br />
                  <div style={{ fontSize: 13 }}>
                    (Please select a specific meter to update billing cycle
                    limit)
                  </div>
                </>
              ) : (
                <a
                  style={{ fontSize: 13 }}
                  onClick={this.openMonthlyLimit.bind(this)}
                >
                  Update
                </a>
              )}
            </div>
          ) : this.state.currentMeter === null ? (
            <div
              className="monthlyLimitLabel"
              style={{ fontSize: 13, alignSelf: "center" }}
            >
              (Please select a specific meter to update billing cycle limit)
            </div>
          ) : (
            <a
              style={{
                fontSize: 13,
                marginTop: 20,
                alignSelf: "center",
              }}
              onClick={this.openMonthlyLimit.bind(this)}
            >
              Set Billing Cycle Limit
            </a>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              paddingLeft: 100,
              paddingBottom: 20,
            }}
          >
            <div className="monthlyLimitLabel">{billingCycleText}</div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: 8,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#f9f9f9",
                alignItems: "center",
                paddingTop: 20,
              }}
            >
              <div
                className="getInformed getInformedMob"
                style={{ width: "90%", textAlign: "center" }}
              >
                Get informed about water consumption while away from home
              </div>
              {this.state.vacation ? (
                <div
                  style={{
                    marginTop: 10,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {this.state?.currentMeterVacation && (
                    <div className="vacationDates">
                      {utils.toVacationDate(
                        this.state.currentMeterVacation.startDate
                      )}{" "}
                      - {utils.toVacationDate(this.state.vacation.endDate)}
                    </div>
                  )}

                  {this.state?.currentMeterVacation ? (
                    <div>
                      <a
                        style={{
                          fontSize: 13,
                          margin: 10,
                        }}
                        onClick={this.openDefineVacation.bind(this)}
                      >
                        Update
                      </a>
                      <a
                        style={{
                          fontSize: 13,
                          margin: 10,
                        }}
                        onClick={this.handleCancelVacation.bind(this)}
                      >
                        Cancel vacation
                      </a>
                    </div>
                  ) : (
                    <>
                      {this.state?.currentMeter && (
                        <button
                          style={{
                            border: 0,
                            backgroundColor: "transparent",
                          }}
                          onClick={this.openDefineVacation.bind(this)}
                        >
                          <img
                            src={images.btnDefineDays}
                            className="btnDefineDays"
                          />
                        </button>
                      )}
                    </>
                  )}
                </div>
              ) : (
                <>
                  {this.state?.currentMeter && (
                    <button
                      style={{
                        border: 0,
                        backgroundColor: "transparent",
                      }}
                      onClick={this.openDefineVacation.bind(this)}
                    >
                      <img
                        src={images.btnDefineDays}
                        className="btnDefineDays"
                      />
                    </button>
                  )}
                </>
              )}
              {!this.state?.currentMeter && (
                <div
                  className="getInformed getInformedMob"
                  style={{ width: "90%", textAlign: "center" }}
                >
                  Please select a specific meter to update the usage during
                  vacation alert
                </div>
              )}
              {!this.state?.currentMeter && this.state.vacation ? (
                <div
                  className="vacationDatesSmall"
                  style={{ textAlign: "center", width: "100%", padding: 15 }}
                >
                  {utils.toVacationDate(this.state.vacation.startDate)} -{" "}
                  {utils.toVacationDate(this.state.vacation.endDate)}
                  <br />
                  Water Meter:{" "}
                  {utils.findMeter(
                    this.state.vacation?.meterCount,
                    this.meters
                  )}
                </div>
              ) : (
                <></>
              )}
              {!this.state?.currentMeter &&
              this.state?.vacations?.length > 1 ? (
                <div
                  className="getInformed"
                  style={{ width: "90%", textAlign: "center" }}
                >
                  Additional vacation dates exist, select a specific meter to
                  show
                </div>
              ) : null}
            </div>
          </div>
          {this.state.alerts &&
            this.state.alerts.map((item) => {
              return (
                <div
                  style={{
                    display: "flex",
                    backgroundColor: globals.COLOR.BLUSH,
                    padding: 10,
                    margin: 8,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginLeft: 16,
                      }}
                    >
                      <img
                        src={utils.getSmallIconForAlertType(item.alertTypeId)}
                        style={{
                          width: 40,
                          height: 40,
                          resizeMode: "contain",
                        }}
                      />
                      <div style={{ marginLeft: 20, flex: 3 }}>
                        <div className="alertTypeLabel">
                          {item.alertTypeName}
                        </div>
                        <div className="alertDescription">{`${item.meterSn}, ${
                          item.address
                            ? item.address
                            : globals.METER_DEFAULT_ADDRESS
                        }`}</div>
                      </div>
                    </div>
                    <div
                      style={{
                        flexDirection: "column",
                        display: "flex",
                        alignItems: "flex-end",
                      }}
                    >
                      <div
                        style={{
                          alignItems: "flex-end",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <div className="alertDate">
                          {utils.toAlertDate(item.alertTime)}
                        </div>
                        <button
                          style={{ border: 0, backgroundColor: "transparent" }}
                          onClick={() => this.onOpenAlert(item)}
                        >
                          <img
                            src={images.btnSeeDetails}
                            className="btnSave"
                            style={{ width: 140, marginRight: -16 }}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              width: "100%",
            }}
          >
            {this.state.chartTypes.map((item, index) => {
              return (
                <button
                  onClick={() => this.onChartTypeChange(index)}
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "transparent",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      fontWeight: "bold",
                      fontSize: 12,
                      color:
                        this.state.selectedChartTypeIndex === index
                          ? globals.COLOR.AZURE
                          : globals.COLOR.COOL_GRAY,
                    }}
                  >
                    {item}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      marginTop: 10,
                      height: 2,
                      backgroundColor:
                        this.state.selectedChartTypeIndex === index
                          ? globals.COLOR.AZURE
                          : globals.COLOR.COOL_GRAY,
                    }}
                  />
                </button>
              );
            })}
          </div>
          <div
            className="chartBg chartBgMobile"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {this.state.selectedChartTypeIndex === 0 && (
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                    marginTop: 16,
                    marginLeft: 8,
                    marginRight: 8,
                  }}
                  className="chartTypesBg"
                >
                  {this.chartTimeTypes.map((item, index) => {
                    return (
                      <button
                        style={{
                          display: "flex",
                          width: "100%",
                          height: 30,
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "transparent",
                        }}
                        onClick={() => this.onChartTimeChange(index)}
                      >
                        <div
                          className={
                            this.state.selectedChartTimeTypeIndex === index
                              ? "chartTypesBg"
                              : ""
                          }
                          style={{
                            display: "flex",
                            width: "200%",
                            height: 30,
                            fontSize: 14,
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            color:
                              this.state.selectedChartTimeTypeIndex === index
                                ? "#fff"
                                : globals.COLOR.BROWNISH_GRAY,
                            backgroundColor:
                              this.state.selectedChartTimeTypeIndex === index
                                ? globals.COLOR.VIBRANT_BLUE
                                : "transparent",
                          }}
                        >
                          {item}
                        </div>
                      </button>
                    );
                  })}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: 8,
                    alignSelf: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="fromTo">
                      {this.state.selectedChartTimeTypeIndex !== 2
                        ? "From"
                        : "\n"}
                    </div>
                    <button
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        marginLeft: -8,
                        marginTop: 8,
                        backgroundColor: "transparent",
                      }}
                      onClick={this.onChooseDate.bind(this)}
                    >
                      <img src={images.icSmallCalendar} />
                      <div
                        placeholder="Choose date"
                        disabled={true}
                        type="text"
                        className="chooseDateInput"
                        style={{
                          marginLeft: 8,
                          border: 0,
                          paddingLeft: 10,
                          paddingRight: 10,
                          display: "flex",
                          fontSize: 13,
                        }}
                      >
                        {utils.toVacationDate(this.state.startDate)}
                      </div>
                    </button>
                  </div>
                  {this.state.selectedChartTimeTypeIndex !== 2 && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div className="fromTo">To</div>
                      <button
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginLeft: -8,
                          marginTop: 8,
                          justifyContent: "center",
                          backgroundColor: "transparent",
                        }}
                        onClick={this.onChooseDate.bind(this)}
                      >
                        <img src={images.icSmallCalendar} />
                        <div
                          placeholder="Choose date"
                          disabled={true}
                          type="text"
                          className="chooseDateInput"
                          style={{
                            marginLeft: 8,
                            border: 0,
                            paddingLeft: 10,
                            paddingRight: 10,
                            display: "flex",
                            fontSize: 13,
                          }}
                        >
                          {utils.toVacationDate(this.state.endDate)}
                        </div>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
            <MeterChart
              selectedMeter={this.state.currentMeter}
              units={this.state.units}
              startDate={this.state.startDate}
              endDate={this.state.endDate}
              neighborData={this.state.neighborConsumptions}
              type={this.state.selectedChartTypeIndex}
              timeType={this.state.selectedChartTimeTypeIndex}
              data={chartData}
              yearsConsumptions={this.state.yearsConsumptions}
              style={{ marginTop: 16 }}
              chartShape={this.state.chartShape}
              presentationMode={this.state.presentationMode}
            />
          </div>

          <Popup
            contentStyle={{ borderRadius: 5, width: "80%" }}
            closeOnDocumentClick={false}
            open={this.state.openMonthlyLimit}
            lockScroll={true}
          >
            <MonthlyLimitPopup
              meterCount={this.state?.currentMeter?.meterCount}
              monthlyLimit={this.state.monthlyLimit}
              onClose={this.closeMonthlyLimit.bind(this)}
            />
          </Popup>

          <Popup
            contentStyle={{ borderRadius: 8, width: "440px" }}
            closeOnDocumentClick={false}
            open={this.state.openDefineVacation}
            lockScroll={true}
          >
            <DefineVacationPopup
              vacation={this.state.vacation}
              onClose={this.closeDefineVacation.bind(this)}
              currentMeter={this.state.currentMeter}
            />
          </Popup>

          <Popup
            contentStyle={{ borderRadius: 5, width: "80%" }}
            open={this.state.openMeterSelection}
            closeOnDocumentClick={false}
            onClose={this.closeMeterSelection.bind(this)}
            lockScroll={true}
          >
            <MeterSelection
              waterMeters={this.meters}
              onMeterSelected={this.onMeterSelected.bind(this)}
              onClose={this.closeMeterSelection.bind(this)}
            />
          </Popup>

          <Popup
            contentStyle={{ borderRadius: 5, width: "80%" }}
            closeOnDocumentClick={false}
            open={this.state.openCalendarPicker}
            lockScroll={true}
          >
            <CalendarRangePicker
              isMonthSelection={this.state.selectedChartTimeTypeIndex === 0}
              singleDay={this.state.selectedChartTimeTypeIndex === 2}
              startDate={utils.toDateFromString(this.state.startDate)}
              endDate={utils.toDateFromString(this.state.endDate)}
              isVacation={false}
              onClose={this.closeCalendarPicker.bind(this)}
              billingCycle={this.state.billingCycles[0]?.billingCycleStart}
            />
          </Popup>
        </div>
        <Popup
          contentStyle={{ borderRadius: 5 }}
          open={this.state.errorMessage}
          onClose={this.closeErrorPopup.bind(this)}
          lockScroll={true}
        >
          <AppPopup
            onClose={this.closeErrorPopup.bind(this)}
            message={this.state.errorMessage}
          />
        </Popup>
      </div>
    );
  }

  onOpenAlert(item) {
    this.props.history.push({
      pathname: `/alerts/${item.logId}`,
      state: { alert: item },
    });
  }

  handleCancelVacation() {
    const { currentMeterVacation, vacations, vacation } = this.state;
    const updatedVacations = vacations?.filter(
      (item) => item.vacationID !== currentMeterVacation.vacationID
    );
    if (currentMeterVacation) {
      api.deleteVacation(currentMeterVacation.vacationID, (res, err) => {
        if (err) {
          console.log(err);
          console.log("Unknown error has occurred.");
        } else {
          if (res.error) {
            this.setState({ errorMessage: res.error });
          } else {
            this.setState({
              currentMeterVacation: null,
              vacations: updatedVacations,
              vacation:
                currentMeterVacation.vacationID === vacation.vacationID
                  ? updatedVacations
                    ? updatedVacations[0]
                    : null
                  : vacation,
            });
          }
        }
      });
    }
  }

  render() {
    return (
      <div>
        <Mobile>
          {(matches) => {
            if (matches) return this._renderMobile();
            else return this._renderDesktop();
          }}
        </Mobile>
      </div>
    );
  }
}

export default Home;
