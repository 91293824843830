import moment from 'moment';
import globals from './globals';
import images from './images';
import { IntlProvider } from 'react-intl';

export const AlertType = {
    SUSPECTED_LEAK: 23,
    MONTHLY_CONS_LIMIT: 1002,
    DAILY_USAGE_VACATION: 1001
};

const utils = {
    containsNumbers(value) {
        const re = /^[0-9\b]+$/;
        return re.test(value);
    },

    containsNumbersAndPoint(value) {
        const re = /^[0-9]*\.?[0-9]*$/;
        return re.test(value);
    },

    generateId() {
        const S4 = () => {
            return (((1 + Math.random()) * 0x10000) | 0)
                .toString(16)
                .substring(1);
        };
        return (
            S4() +
            S4() +
            '-' +
            S4() +
            '-' +
            S4() +
            '-' +
            S4() +
            '-' +
            S4() +
            S4() +
            S4()
        );
    },

    isEmail(candidate) {
        // let reg = /^[\w._-]+[+]?[\w._-]+@[\w.-]+\.[a-zA-Z]{2,6}$/;
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return reg.test(candidate);
    },

    toVacationDateRange(vacation) {
        if (vacation === '') return null;

        return `${this.toVacationDate(
            vacation.startDate
        )} - ${this.toVacationDate(vacation.endDate)}`;
    },

    toVacationDate(date) {
        if (date === '') return '';

        const dateString = moment(date).format('ddd, MMM DD');
        console.log('toVacationDate', dateString);
        return dateString;
    },

    toMonthDate(date) {
        if (date === '') return '';

        const dateString = moment(date).format('MMM, YYYY');
        // console.log(dateString);
        return dateString;
    },

    toAlertDate(date) {
        if (date === '') return '';

        const dateString = moment(date).format('MMMM DD, YYYY, hh:mm');
        // console.log(dateString);
        return dateString;
    },

    getBillingCycleDate(date) {
        const dateString = moment(date, moment.ISO_8601).format('MM-DD-YYYY');
        // console.log(dateString);
        return dateString;
    },

    toHomeDisplayDateString(date) {
        if (date === '') return '';

        const dateString = moment(date).format('MMM DD, YYYY');
        // console.log(dateString);
        return dateString;
    },

    toDisplayDateString(date, withTime) {
        if (date === '') return '';

        const dateString = moment(date).format(
            `MM/DD/YYYY${withTime ? ' HH:mm' : ''}`
        );
        // console.log(dateString);
        return dateString;
    },

    toDateFromString(dateString) {
        const date = moment(dateString, 'YYYY-MM-DD').toDate();
        return date;
    },

    toDateString(date) {
        if (date === '') return '';

        const dateString = moment(date).format('YYYY-MM-DD');
        // console.log(dateString);
        return dateString;
    },

    toDateStringEndMonth(date) {
        if (date === '') return '';

        const dateString = moment(date).endOf('month').format('YYYY-MM-DD');
        // console.log(dateString);
        return dateString;
    },

    toISODateString(date) {
        const dateString = moment(date, moment.ISO_8601);
        // console.log(dateString);
        return dateString;
    },

    getYearsBefore(date, amount) {
        const monthString = moment(date)
            .subtract(amount, 'years')
            .format('YYYY');
        // console.log(monthString);
        return monthString;
    },

    getDaysBefore(date, amount) {
        const monthString = moment(date)
            .subtract(amount, 'days')
            .format('YYYY-MM-DD');
        // console.log(monthString);
        return monthString;
    },

    getMonthsBefore(date, amount) {
        const monthString = moment(date)
            .subtract(amount, 'months')
            .format('YYYY-MM');
        // console.log(monthString);
        return monthString;
    },

    getMonthsBeforeWithDay(date, amount) {
        const monthString = moment(date)
            .subtract(amount, 'months')
            .format('YYYY-MM-DD');
        return monthString;
    },

    getMonth(date) {
        const monthString = moment(date).format('YYYY-MM');
        // console.log(monthString);
        return monthString;
    },

    getEndOfMonthDate(date) {
        return moment(date).endOf("month").locale('en').format("YYYY-MM-DD");
    },

    getMonthBillingCycle(date) {
        const monthString = moment(date).format('YYYY-DD');
        // console.log(monthString);
        return monthString;
    },

    getMonthDigit(date) {
        const monthString = moment(date).format('MM');
        // console.log(monthString);
        return monthString;
    },

    getMonthDiff(date1, date2) {
        let diff = moment(date2).diff(moment(date1), 'months', true);
        return diff + 1;
    },

    getHour(date) {
        const monthName = moment(date, moment.ISO_8601).format('HH');
        // console.log(monthName);
        return monthName;
    },

    getDayNumber(date) {
        const monthName = moment(date, moment.ISO_8601).format('DD');
        // console.log(monthName);
        return monthName;
    },

    getYear(date) {
        const monthName = moment(date, moment.ISO_8601).format('YYYY');
        // console.log(monthName);
        return monthName;
    },

    getMonthName(date) {
        const monthName = moment(date, moment.ISO_8601).format('MMM');
        // console.log(monthName);
        return monthName;
    },

    lastMonthCheck(date) {
        const currentDate = moment();
        const consumptionDate = moment(date);
        // console.log(`getChartRangeDates ${currentDate} ${consumptionDate}`);

        if (currentDate.month() === consumptionDate.month() && currentDate.year() === consumptionDate.year()){
            return true;
        }

        return false;
    },

    getChartRangeDates(date) {
        const startOfMonth = moment(date, moment.ISO_8601).format('MMM DD');
        const endOfMonth = moment(date, moment.ISO_8601).add(1, 'M').subtract(1, "days").format('MMM DD');

        return [startOfMonth + ' -', endOfMonth + '  '];
    },

    getChartRangeDatesTable(date) {
        const startOfMonth = moment(date, moment.ISO_8601).format('MM/DD/YYYY');
        const endOfMonth = moment(date, moment.ISO_8601).add(1, 'M').subtract(1, "days").format('MM/DD/YYYY');

        return [`${startOfMonth} - ${endOfMonth}`];
    },

    getChartYLabelThousands(label, units) {
        if (label === 0) return `0 ${units.toLowerCase()}`;

        if (label < 10) {
            return `${label?.toFixed(3)}`;
        } else if (Number(label) === label && label % 1 === 0) {
            return label / 1000 + "k";
        } else {
            return (label / 1000)?.toFixed(3) + "k";
        }
    },

    getDayMonth(date) {
        const day = moment(date).format('DD');
        const month = moment(date).format('MM');
        // console.log(day);

        return `${month}/${day}`;
    },

    getDay(date) {
        const day = moment(date, moment.ISO_8601).date();
        // console.log(day);
        return day;
    },

    getDaysInMonth(year, month) {
        const date = new Date(year, month - 1, 1);
        const result = [];
        while (date.getMonth() === month - 1) {
            result.push(`${date.getDate()}`);
            date.setDate(date.getDate() + 1);
        }
        return result;
    },

    isToday(date) {
        const today = new Date();
        return (
            utils.getYear(date) === utils.getYear(today) &&
            utils.getMonth(date) === utils.getMonth(today) &&
            utils.getDayMonth(date) === utils.getDayMonth(today)
        );
    },

    isArrAscendingByConsDate(arr) {
        return arr.every(function(x, i) {
            return i === 0 || x.consDate >= arr[i - 1].consDate;
        });
    },

    getMeterDesc(meter) {
        return `${meter.meterSn}, ${
            meter.fullAddress
                ? meter.fullAddress
                : globals.METER_DEFAULT_ADDRESS
        }`;
    },

    findMeter(meterCount, meters) {
        const result = meters.find(item => meterCount == item.meterCount)
        console.log(result)
        return `${result?.meterSn}, ${
            result?.fullAddress
                ? result?.fullAddress
                : globals.METER_DEFAULT_ADDRESS
        }`;
    },

    getLargeIconForAlertType(alertTypeId) {
        let bigIcon = '';
        switch (parseInt(alertTypeId)) {
            case AlertType.SUSPECTED_LEAK:
                bigIcon = images.icBigTap;
                break;
            case AlertType.DAILY_USAGE_VACATION:
                bigIcon = images.icBigVacations;
                break;
            case AlertType.MONTHLY_CONS_LIMIT:
                bigIcon = images.icBigWaterLimit;
                break;
        }

        return bigIcon;
    },

    getSmallIconForAlertType(alertTypeId) {
        console.log(`getSmallIconForAlertType ${alertTypeId}`);
        let icon = '';
        switch (parseInt(alertTypeId)) {
            case AlertType.SUSPECTED_LEAK:
                icon = images.icFaucet;
                break;
            case AlertType.DAILY_USAGE_VACATION:
                icon = images.icVacation;
                break;
            case AlertType.MONTHLY_CONS_LIMIT:
                icon = images.icWaterLimit;
                break;
        }

        return icon;
    }
};

export default utils;
