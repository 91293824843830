import React, { Component } from 'react';
import './Contacts.css';
import images from '../../images';
import { Mobile } from '../../App';
import DataManager from "../../managers/DataManager";
import * as api from "../../api";

export default class Contacts extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            description: DataManager.sharedInstance.muni && DataManager.sharedInstance.muni.description ? DataManager.sharedInstance.muni.description : '',
            phoneNumber: DataManager.sharedInstance.muni && DataManager.sharedInstance.muni.phoneNumber ? DataManager.sharedInstance.muni.phoneNumber : ''
        }
    }

    componentWillMount() {
        //Get municipal customer service info
        api.getMunicipalCustomerService((res, err) => {
            //this._checkFirstDataFetchCounter();
            if (err) {
                console.log('Unknown error has occurred.');
            } else {
                if (res.error) {
                    console.log(res.error);
                } else {
                    DataManager.sharedInstance.muni = res;
                    this.setState({description: DataManager.sharedInstance.muni.description, phoneNumber: DataManager.sharedInstance.muni.phoneNumber})
                }
            }
        });
    }

    render() {
        return (
            <Mobile>
                {matches => (
                    <div
                        className={
                            matches
                                ? 'mobileContactsContent'
                                : 'desktopContactsContent'
                        }
                    >
                        <div className="contactsContainer">
                            <div className="title">Contact</div>
                            <p className="contactsInfo">
                                {this.state.description}
                            </p>
                            <div className="contactsDetails">
                                <div className="detailRow">
                                    <img src={images.icPhoneCall} />
                                    <p>{this.state.phoneNumber}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Mobile>
        );
    }

};