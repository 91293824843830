import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import DataManager from '../managers/DataManager';

const ProtectedRoute = ({ component: Component, render, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            DataManager.sharedInstance.isAuthenticated ? (
                typeof render === 'function' ? (
                    render(props)
                ) : (
                    <Component {...props} />
                )
            ) : (
                <Redirect
                    to={{
                        pathname: '/login',
                        state: { from: props.location }
                    }}
                />
            )
        }
    />
);

export default ProtectedRoute;
