import React, {Component} from 'react';
import './App.css';
import Header from './common/Header/Header';
import Footer from './common/Footer/Footer';
import Responsive from 'react-responsive';
import {Link, BrowserRouter, Route, withRouter} from 'react-router-dom';
import Register from './scenes/register/Register';
import images from './images';
import globals from './globals';
import Login from './scenes/login/Login';
import ForgotPassword from './scenes/password/ForgotPassword';
import RegisterAccount from './scenes/register/RegisterAccount';
import RegisterPhone from './scenes/register/RegisterPhone';
import RegisterEnterCode from './scenes/register/RegisterEnterCode';
import RegisterNotFound from './scenes/register/RegisterNotFound';
import Popup from 'reactjs-popup';
import TermsOfUse from './scenes/common/TermsOfUse';
import RegisterFinish from './scenes/register/RegisterFinish';
import ChangePassword from './scenes/password/ChangePassword';
import EnterCodePassword from './scenes/password/EnterCodePassword';
import DataManager from './managers/DataManager';
import Settings from './scenes/settings/Settings';
// import 'react-s-alert/dist/s-alert-default.css';
import Home from './scenes/home/Home';
import SettingsChangePassword from './scenes/settings/SettingsChangePassword';
import SettingsChangePhone from './scenes/settings/SettingsChangePhone';
import Alerts from './scenes/alerts/Alerts';
import AlertInfo from './scenes/alerts/AlertInfo';
import * as api from './api';
import PersistenceManager from './managers/PersistenceManager';
import LandingPage from './scenes/home/LandingPage';
import PrivacyPolicy from './scenes/common/PrivacyPolicy';
import ProtectedRoute from './common/ProtectedRoute';
import Contacts from './scenes/contacts/Contacts';
import About from './scenes/about/About';
import { compose } from 'recompose'
import AppPopup from "./components/AppPopup";
import AdminLogin from "./scenes/admin_login/AdminLogin";

export const Desktop = props => <Responsive {...props} minWidth={992}/>;
export const Desktop2 = props => <Responsive {...props} minWidth={200} maxWidth={992} />;
export const Desktop3 = props => <Responsive {...props} minWidth={200} maxWidth={992} />;
export const Tablet = props => (
    <Responsive {...props} minWidth={768} maxWidth={991}/>
);
export const Mobile = props => <Responsive {...props} maxWidth={767}/>;
export const Mobile2 = props => <Responsive {...props} maxWidth={767}/>;
export const MobileLand = props => <Responsive {...props} maxWidth={767} orientation={'landscape'}/>;
export const Default = props => <Responsive {...props} minWidth={768}/>;

class App extends React.Component {
    constructor(props) {
        super(props);

        // this.location = props.history.location.pathname;
        this.state = {
            openTerms: false,
            openPrivacy: false,
            openChangePass: false,
            openChangePhone: false,
            isLoggedIn: false,
            currentScreen: 0,
            user: DataManager.sharedInstance.user,
            shouldShowBack: true,
            showHeader: true,
            showRightButton: true,
            showSignUpButton: false,
            initLoading: true,
            alertsCount: 0,
            errorMessage: null,
            online: window.navigator.onLine
        };
    }

    componentDidMount() {
        window.addEventListener('offline', this.handleNetworkChange);
        window.addEventListener('online', this.handleNetworkChange);
    }
    
    componentWillUnmount() {
        window.removeEventListener('offline', this.handleNetworkChange);
        window.removeEventListener('online', this.handleNetworkChange);
    }
    
    handleNetworkChange = () => {
        this.setState({ online: window.navigator.onLine });
    }

    render() {
        const {initLoading} = this.state;

        if (initLoading) {
            return <div/>;
        }

        return (
            <Mobile>
                {matches => {
                    if (matches) return this._renderMobile();
                    else return this._renderDesktop();
                }}
            </Mobile>
        );
    }

    componentDidMount() {
        DataManager.sharedInstance.onEnter = this.onEnter;

        const token = PersistenceManager.getItem(globals.STORAGE.TOKEN);

        if (token) {
            api.setTokenValue(token);

            if (this.props.location.pathname === '/settings')
                this.setState({currentScreen: 1});
            else if (this.props.location.pathname === '/alerts')
                this.setState({currentScreen: 2});

            api.getMe((res, err) => {
                if (err) {
                    console.log('Unknown error has occurred.');
                } else {
                    if (res.error) {
                        console.log(res.error);

                        if (res.code === 401) {
                            PersistenceManager.deleteItem(
                                globals.STORAGE.TOKEN
                            );
                            this.setState({errorMessage: res.error});
                        }

                        this.setState({
                            initLoading: false
                        });
                    } else {
                        DataManager.sharedInstance.user = res;
                        DataManager.sharedInstance.isAuthenticated = true;

                        this.setState({
                            isLoggedIn: true,
                            initLoading: false,
                            user: res
                        });
                    }
                }
            });
        } else {
            this.setState({
                initLoading: false
            });
        }
    }

    _onRightButtonPressed() {
        this.setState({showSignUpButton: !this.state.showSignUpButton});
    }

    _renderLoggedOutStripDesktop() {
        if (this.state.showHeader)
            return (
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        zIndex: 1
                    }}
                >
                    <Link
                        to={
                            this.state.showSignUpButton ? '/register' : '/login'
                        }
                        style={{display: 'flex', alignSelf: 'center'}}
                        onClick={() => this._onRightButtonPressed()}
                    >
                        <img
                            style={{
                                opacity: this.state.showRightButton ? 1 : 0,
                                objectFit: 'contain',
                                height: this.state.showSignUpButton ? 80 : 40,
                                marginTop: this.state.showSignUpButton ? 0 : 10,
                                marginRight: this.state.showSignUpButton
                                    ? 0
                                    : 20
                            }}
                            src={
                                this.state.showSignUpButton
                                    ? images.btnSignUp
                                    : images.btnLoginWhite
                            }
                        />
                        {/*<img src={images.btnSignUp}*/}
                        {/*style={{objectFit: 'contain'}}/>*/}
                    </Link>
                </div>
            );
        else {
            return null;
        }
    }

    _renderLoggedOutStripMobile() {
        if (this.state.showHeader)
            return (
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end'
                    }}
                >
                    <Link
                        to={
                            this.state.showSignUpButton ? '/register' : '/login'
                        }
                        style={{
                            marginRight: 0,
                            display: 'flex',
                            alignSelf: 'center'
                        }}
                        onClick={() => this._onRightButtonPressed()}
                    >
                        <img
                            style={{
                                objectFit: 'contain',
                                width: 100,
                                height: this.state.showSignUpButton ? 80 : 30,
                                marginTop: this.state.showSignUpButton ? 0 : 10
                            }}
                            src={
                                this.state.showSignUpButton
                                    ? images.btnSignUp
                                    : images.btnLoginWhite
                            }
                        />
                        {/*<img src={images.btnSignUp}*/}
                        {/*style={{objectFit: 'contain', width: 100}}/>*/}
                    </Link>
                </div>
            );
        else {
            return null;
        }
    }

    _renderLoggedInStripDesktop() {
        return (
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: '#3659c3',
                    height: 60,
                    justifyContent: 'space-between'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        marginLeft: 50,
                        alignContent: 'center'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Link
                            to={'/home'}
                            style={{
                                textDecoration: 'none',
                                backgroundColor: 'transparent',
                                color: 'white',
                                marginTop: 20,
                                fontSize: 13
                            }}
                            onClick={this.onHomePressed.bind(this)}
                        >
                            HOME
                        </Link>
                        {this.state.currentScreen === 0 && (
                            <div
                                style={{
                                    backgroundColor: '#84a2ff',
                                    height: 3,
                                    marginTop: 10
                                }}
                            />
                        )}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            marginLeft: 30
                        }}
                    >
                        <Link
                            to={'/settings'}
                            style={{
                                textDecoration: 'none',
                                backgroundColor: 'transparent',
                                color: 'white',
                                marginTop: 20,
                                fontSize: 13
                            }}
                            onClick={() => this.onSettingsPressed()}
                        >
                            SETTINGS
                        </Link>
                        {this.state.currentScreen === 1 && (
                            <div
                                style={{
                                    backgroundColor: '#84a2ff',
                                    height: 3,
                                    marginTop: 10
                                }}
                            />
                        )}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            marginLeft: 30
                        }}
                    >
                        <Link
                            to={'/alerts'}
                            style={{
                                textDecoration: 'none',
                                backgroundColor: 'transparent',
                                color: 'white',
                                marginTop: 20,
                                fontSize: 13
                            }}
                            onClick={() => this.onAlertsPressed()}
                        >
                            ALERTS
                        </Link>
                        {this.state.currentScreen === 2 && (
                            <div
                                style={{
                                    backgroundColor: '#84a2ff',
                                    height: 3,
                                    marginTop: 10
                                }}
                            />
                        )}
                    </div>
                    {this.state.alertsCount > 0 &&
                    <div style={{
                        width: 30,
                        height: 30,
                        alignItems: 'center',
                        marginLeft: 10,
                        display: 'flex',
                        alignSelf: 'center',
                        marginBottom: 10,
                    }}>
                        <div style={{position: 'absolute', flexDirection: 'row', display: 'flex',}}>
                            <img src={images.icAlerts}
                                 style={{width: 20, height: 20, resizeMode: 'contain', zIndex: 0}}/>
                            <div style={{
                                width: 18,
                                height: 18,
                                borderRadius: 9,
                                backgroundColor: globals.COLOR.GRAPEFRUIT,
                                display: 'flex',
                                color: 'white',
                                justifyContent: 'center',
                                fontSize: 12,
                                top: -4,
                                right: 0,
                                left: 10,
                                position: 'absolute',
                                alignSelf: 'flex-start',
                                alignItems: 'center'
                            }}>{this.state.alertsCount}
                            </div>
                        </div>
                    </div>
                    }
                </div>
                <div
                    style={{
                        display: 'flex',
                        marginRight: 50,
                        alignContent: 'center'
                    }}
                >
                    <div className={'sep'}/>
                    <h4 style={{marginLeft: 20}} className={'welcomeText'}>
                        Welcome {this.state.user.firstName}{' '}
                        {this.state.user.lastName}
                    </h4>
                    <Link
                        to={'/'}
                        style={{
                            border: 0,
                            backgroundColor: 'transparent',
                            marginLeft: 40
                        }}
                        onClick={this.onLogout.bind(this)}
                    >
                        <img src={images.btnLogOut}/>
                    </Link>
                </div>
            </div>
        );
    }

    _renderLoggedInStripMobile() {
        return (
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#3659c3',
                    height: 100
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginLeft: 20
                    }}
                >
                    <h4 className={'welcomeText'}>
                        Welcome {this.state.user.firstName}{' '}
                        {this.state.user.lastName}
                    </h4>
                    <Link
                        to={'/'}
                        style={{border: 0, backgroundColor: 'transparent'}}
                        onClick={this.onLogout.bind(this)}
                    >
                        <img src={images.btnLogOut}/>
                    </Link>
                </div>
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'row'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            flex: 1
                        }}
                    >
                        <Link
                            to={'/home'}
                            style={{
                                textDecoration: 'none',
                                backgroundColor: 'transparent',
                                color: 'white',
                                alignSelf: 'center',
                                fontSize: 13
                            }}
                            onClick={this.onHomePressed.bind(this)}
                        >
                            HOME
                        </Link>
                        {this.state.currentScreen === 0 && (
                            <div
                                style={{
                                    backgroundColor: '#84a2ff',
                                    height: 3,
                                    marginTop: 10
                                }}
                            />
                        )}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            marginLeft: 30,
                            flex: 1
                        }}
                    >
                        <Link
                            to={'/settings'}
                            style={{
                                textDecoration: 'none',
                                backgroundColor: 'transparent',
                                color: 'white',
                                alignSelf: 'center',
                                fontSize: 13
                            }}
                            onClick={() => this.onSettingsPressed()}
                        >
                            SETTINGS
                        </Link>
                        {this.state.currentScreen === 1 && (
                            <div
                                style={{
                                    backgroundColor: '#84a2ff',
                                    height: 3,
                                    marginTop: 10
                                }}
                            />
                        )}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            marginLeft: 30,
                            flex: 1.1
                        }}
                    >
                        <Link
                            to={'/alerts'}
                            style={{
                                textDecoration: 'none',
                                backgroundColor: 'transparent',
                                color: 'white',
                                alignSelf: 'center',
                                fontSize: 13
                            }}
                            onClick={() => this.onAlertsPressed()}
                        >
                            ALERTS
                        </Link>
                        {this.state.currentScreen === 2 && (
                            <div
                                style={{
                                    backgroundColor: '#84a2ff',
                                    height: 3,
                                    marginTop: 10
                                }}
                            />
                        )}
                    </div>
                    {this.state.alertsCount > 0 &&
                    <div style={{
                        width: 30,
                        height: 30,
                        alignItems: 'center',
                        marginLeft: 10,
                        display: 'flex',
                        alignSelf: 'center',
                        position: 'absolute',
                        right: 0
                    }}>
                        <div style={{position: 'absolute', flexDirection: 'row', display: 'flex', top: -4}}>
                            <img src={images.icAlerts}
                                 style={{width: 20, height: 20, resizeMode: 'contain', zIndex: 0}}/>
                            <div style={{
                                width: 18,
                                height: 18,
                                borderRadius: 9,
                                backgroundColor: globals.COLOR.GRAPEFRUIT,
                                display: 'flex',
                                color: 'white',
                                justifyContent: 'center',
                                fontSize: 12,
                                top: -4,
                                right: 0,
                                left: 10,
                                position: 'absolute',
                                alignSelf: 'flex-start',
                                alignItems: 'center'
                            }}>{this.state.alertsCount}
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        );
    }

    _renderMobile() {
        return (
            <BrowserRouter>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        minHeight: '100vh'
                    }}
                >
                    {(!this.state.online) && (
                        <div style={{
                            backgroundColor: 'red',
                            padding: 2,
                            width: '100%',
                            color: 'white',
                            textAlign: 'center'
                        }}>No internet connection
                        </div>
                    )}
                    {this.state.showHeader && <Header additionalFunction={this.onHomePressed} />}
                    {this.state.isLoggedIn
                        ? this._renderLoggedInStripMobile()
                        : this._renderLoggedOutStripMobile()}

                    <Route
                        exact
                        path="/"
                        render={props => (
                            <LandingPage
                                {...props}
                                toggleHeader={this.toggleHeader.bind(this)}
                                onEnter={this.onEnter.bind(this)}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/login"
                        render={props => (
                            <Login
                                {...props}
                                onLogin={this.onLogin.bind(this)}
                                onEnter={this.onEnter.bind(this)}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/register"
                        component={Register}
                        onEnter={this.onEnter.bind(this)}
                    />
                    <Route
                        exact
                        path="/password/recovery"
                        component={ForgotPassword}
                    />
                    <Route
                        exact
                        path="/password/change/:code"
                        component={ChangePassword}
                        onEnter={this.onEnter.bind(this)}
                    />
                    <Route
                        exact
                        path="/password/code"
                        component={EnterCodePassword}
                    />
                    <Route
                        exact
                        path="/register/account"
                        component={RegisterAccount}
                    />
                    <Route
                        exact
                        path="/register/phone"
                        component={RegisterPhone}
                    />
                    <Route
                        exact
                        path="/register/code/:phone"
                        component={RegisterEnterCode}
                    />
                    <Route
                        exact
                        path="/register/:type/not_found"
                        component={RegisterNotFound}
                    />
                    <Route
                        exact
                        path="/register/finish"
                        render={props => (
                            <RegisterFinish
                                {...props}
                                onLogin={this.onLogin.bind(this)}
                            />
                        )}
                    />
                    <Route exact path="/contacts" component={Contacts}/>
                    <Route exact path="/about" component={About}/>
                    <ProtectedRoute
                        exact
                        path="/settings"
                        render={props => (
                            <Settings
                                {...props}
                                onOpenChangePhone={this.openChangePhone.bind(
                                    this
                                )}
                                onOpenChangePass={this.openChangePass.bind(
                                    this
                                )}
                            />
                        )}
                    />
                    <ProtectedRoute exact path="/home" render={props => (
                        <Home {...props} onAlerts={(alertsCount) => this.setState({alertsCount})}/>
                    )} />
                    <ProtectedRoute
                        exact
                        path="/alerts"
                        render={props => (
                            <Alerts {...props} onAlerts={(alertsCount) => this.setState({alertsCount})}/>
                        )}
                    />

                    <ProtectedRoute
                        exact
                        path="/alerts/:id"
                        component={AlertInfo}
                    />

                    {/*</div>*/}
                    <Footer
                        openTerms={this.openTerms.bind(this)}
                        openPrivacy={this.openPrivacy.bind(this)}
                    />
                    <Popup
                        contentStyle={{borderRadius: 5, width: '80%'}}
                        open={this.state.openTerms}
                        onClose={this.closeTerms.bind(this)}
                        lockScroll={true}
                    >
                        <TermsOfUse onClose={this.closeTerms.bind(this)}/>
                    </Popup>
                    <Popup
                        contentStyle={{borderRadius: 5, width: '80%'}}
                        open={this.state.openPrivacy}
                        onClose={this.closePrivacy.bind(this)}
                        lockScroll={true}
                    >
                        <PrivacyPolicy onClose={this.closePrivacy.bind(this)}/>
                    </Popup>
                    <Popup
                        contentStyle={{borderRadius: 5, width: '80%'}}
                        open={this.state.openChangePass}
                        onClose={this.closeChangePass.bind(this)}
                        lockScroll={true}
                    >
                        <SettingsChangePassword
                            onClose={this.closeChangePass.bind(this)}
                        />
                    </Popup>
                    <Popup
                        contentStyle={{borderRadius: 5, width: '80%'}}
                        open={this.state.openChangePhone}
                        onClose={this.closeChangePhone.bind(this)}
                        lockScroll={true}
                    >
                        <SettingsChangePhone
                            onClose={this.closeChangePhone.bind(this)}
                        />
                    </Popup>
                    <Popup
                        contentStyle={{borderRadius: 5}}
                        open={this.state.errorMessage}
                        onClose={this.closeErrorPopup.bind(this)}
                        lockScroll={true}
                    >
                        <AppPopup onClose={this.closeErrorPopup.bind(this)} message={this.state.errorMessage}/>
                    </Popup>
                </div>
            </BrowserRouter>
        );
    }

    closeErrorPopup() {
        this.setState({errorMessage: null});
    }

    _renderDesktop() {
        return (
            <BrowserRouter>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}
                >
                    {/*<div className="Site-content">*/}
                    {(!this.state.online) && (
                        <div style={{
                            backgroundColor: 'red',
                            padding: 2,
                            width: '100%',
                            color: 'white',
                            textAlign: 'center'
                        }}>No internet connection
                        </div>
                    )}
                    {this.state.showHeader && <Header additionalFunction={this.onHomePressed} />}
                    {this.state.isLoggedIn
                        ? this._renderLoggedInStripDesktop()
                        : this._renderLoggedOutStripDesktop()}

                    <div
                        id="content"
                        style={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'column',
                            marginTop: this.state.isLoggedIn ? 0 : -70
                        }}
                    >
                        {/*<RingLoader*/}
                        {/*color={'#123abc'}*/}
                        {/*loading={true}*/}
                        {/*/>*/}
                        {/*<TransitionGroup>*/}
                        {/*<CSSTransition key={this.location} classNames="fade" timeout={2000}>*/}
                        {/*<Switch location={this.props.location}>*/}
                        <Route
                            exact
                            path="/"
                            render={props => (
                                <LandingPage
                                    {...props}
                                    onEnter={this.onEnter.bind(this)}
                                    toggleHeader={this.toggleHeader.bind(this)}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/admin_login"
                            render={props => (
                                <AdminLogin
                                    {...props}
                                    onLogin={() => {
                                        this.onLogin();
                                        this.setState({showRightButton: true});
                                    }}
                                    onEnter={() => {
                                        this.setState({showRightButton: false});
                                        this.onEnter();
                                    }}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/login"
                            render={props => (
                                <Login
                                    {...props}
                                    onLogin={this.onLogin.bind(this)}
                                    onEnter={this.onEnter.bind(this)}
                                    toggleHeader={this.toggleHeader.bind(this)}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="/register"
                            component={Register}
                            onEnter={this.onEnter.bind(this)}
                        />
                        <Route
                            exact
                            path="/password/recovery"
                            component={ForgotPassword}
                        />
                        <Route
                            exact
                            path="/password/change/:code"
                            component={ChangePassword}
                            onEnter={this.onEnter.bind(this)}
                        />
                        <Route
                            exact
                            path="/password/code"
                            component={EnterCodePassword}
                        />
                        <Route
                            exact
                            path="/register/account"
                            component={RegisterAccount}
                        />
                        <Route
                            exact
                            path="/register/phone"
                            component={RegisterPhone}
                        />
                        <Route
                            exact
                            path="/register/code/:phone"
                            component={RegisterEnterCode}
                        />
                        <Route
                            exact
                            path="/register/:type/not_found"
                            component={RegisterNotFound}
                        />
                        <Route
                            exact
                            path="/register/finish"
                            render={props => (
                                <RegisterFinish
                                    {...props}
                                    onLogin={this.onLogin.bind(this)}
                                />
                            )}
                        />
                        <Route exact path="/contacts" component={Contacts}/>
                        <Route exact path="/about" component={About}/>
                        <ProtectedRoute exact path="/home" render={props => (
                            <Home {...props} onAlerts={(alertsCount) => this.setState({alertsCount})}/>
                        )} />
                        <ProtectedRoute
                            exact
                            path="/settings"
                            render={props => (
                                <Settings
                                    {...props}
                                    onOpenChangePhone={this.openChangePhone.bind(
                                        this
                                    )}
                                    onOpenChangePass={this.openChangePass.bind(
                                        this
                                    )}
                                />
                            )}
                        />
                        <ProtectedRoute
                            exact
                            path="/alerts"
                            render={props => (
                                <Alerts {...props} onAlerts={(alertsCount) => this.setState({alertsCount})}/>
                            )}
                        />
                        <ProtectedRoute
                            exact
                            path="/alerts/:id"
                            component={AlertInfo}
                        />

                        {/*</Switch>*/}
                        {/*</CSSTransition>*/}
                        {/*</TransitionGroup>*/}
                    </div>

                    {/*</div>*/}
                    <Footer
                        openTerms={this.openTerms.bind(this)}
                        openPrivacy={this.openPrivacy.bind(this)}
                    />
                    <Popup
                        contentStyle={{borderRadius: 5}}
                        open={this.state.openTerms}
                        onClose={this.closeTerms.bind(this)}
                        lockScroll={true}
                    >
                        <TermsOfUse onClose={this.closeTerms.bind(this)}/>
                    </Popup>
                    <Popup
                        contentStyle={{borderRadius: 5}}
                        open={this.state.openPrivacy}
                        onClose={this.closePrivacy.bind(this)}
                        lockScroll={true}
                    >
                        <PrivacyPolicy onClose={this.closePrivacy.bind(this)}/>
                    </Popup>
                    <Popup
                        contentStyle={{borderRadius: 5}}
                        open={this.state.openChangePass}
                        onClose={this.closeChangePass.bind(this)}
                        lockScroll={true}
                    >
                        <SettingsChangePassword
                            onClose={this.closeChangePass.bind(this)}
                        />
                    </Popup>
                    <Popup
                        contentStyle={{borderRadius: 5}}
                        open={this.state.openChangePhone}
                        onClose={this.closeChangePhone.bind(this)}
                        lockScroll={true}
                    >
                        <SettingsChangePhone
                            onClose={this.closeChangePhone.bind(this)}
                        />
                    </Popup>
                    <Popup
                        contentStyle={{borderRadius: 5}}
                        open={this.state.errorMessage}
                        onClose={this.closeErrorPopup.bind(this)}
                        lockScroll={true}
                    >
                        <AppPopup onClose={this.closeErrorPopup.bind(this)} message={this.state.errorMessage}/>
                    </Popup>
                </div>
            </BrowserRouter>
        );
    }

    onEnter = showSignUpButton => {
        this.setState({showSignUpButton});
    };

    toggleHeader = show => {
        let isLoggedIn = this.state.isLoggedIn;
        if (!show) isLoggedIn = false;
        this.setState({showHeader: show, isLoggedIn});
    };

    onLogout = () => {
        api.logout((res, err) => {
            if (err) {
                console.log('Unknown error has occurred.');
            } else {
                if (res.error) {
                    console.log(res.error);

                    if (res.code === 401) {
                        PersistenceManager.deleteItem(
                            globals.STORAGE.TOKEN
                        );
                        this.setState({errorMessage: res.error});
                    }

                    this.setState({
                        initLoading: false
                    });
                } else {
                    DataManager.sharedInstance.reset();
                    this.setState({isLoggedIn: false, user: null});
                }
            }
        });
    };

    onLogin = () => {
        this.setState({
            isLoggedIn: true,
            user: DataManager.sharedInstance.user
        });
    };

    onHomePressed = () => {
        if (this.state.currentScreen === 0) return;

        this.setState({currentScreen: 0});
    };

    onSettingsPressed = () => {
        if (this.state.currentScreen === 1) return;

        this.setState({currentScreen: 1});
    };

    onAlertsPressed = () => {
        if (this.state.currentScreen === 2) return;

        this.setState({currentScreen: 2});
    };

    openChangePhone = () => {
        this.setState({openChangePhone: true});
    };

    closeChangePhone = () => {
        this.setState({openChangePhone: false});
    };

    openChangePass = () => {
        this.setState({openChangePass: true});
    };

    closeChangePass = () => {
        this.setState({openChangePass: false});
    };

    closeTerms = () => {
        this.setState({openTerms: false});
    };

    openTerms = () => {
        this.setState({openTerms: true});
    };

    closePrivacy = () => {
        this.setState({openPrivacy: false});
    };

    openPrivacy = () => {
        this.setState({openPrivacy: true});
    };
}

export default withRouter(App);
