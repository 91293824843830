import React from 'react';
import { Mobile } from '../../App';
import './About.css';
import images from '../../images';

const About = () => {
    return (
        <Mobile>
            {matches => (
                <div
                    className={
                        matches ? 'mobileAboutContent' : 'desktopAboutContent'
                    }
                >
                    <div className="aboutContainer">
                        <div className="title">About Master Meter</div>
                        <p className="version">v1.0</p>
                        <div className="row">
                            <p className="aboutInfo">
                                At Master Meter, we are focused on the future of water management.
                                Everything we do is designed to help our utility customers reduce costs, 
                                improve efficiencies, and promote water stewardship through empowering conservation.<br/><br/>

                                Master Meter is a study in innovation. Our parent company, Israel-based Arad
                                Group, is located in the world’s most robust center of resource management 
                                technology development. With few natural resources, Israel has positioned 
                                itself as a global leader in desalination, micro-irrigation, water management
                                software, advanced meter infrastructure, solar power, pipe rehabilitation, and 
                                more. Albert Einstein famously noted, “Necessity is the mother of all 
                                invention.” Our customers enjoy a real advantage in working with the very 
                                best technologies available today.<br/><br/>

                                Our Sonata and Octave smart water meters are based on solid-state
                                technology and leverage the most innovative science to ensure that your
                                water meters stay accurate. Over 6 million Dialog® 3G AMR, Allegro Mobile,
                                and Allegro AMI Under- the-Glass (UTGTM) endpoints are in use today, making
                                our networking solutions a tried and trusted technology for remotely
                                communicating consumption and performance alert information.<br/><br/>

                                Whether you’re serving small rural populations or major metropolitan areas,
                                delivering water only or provisioning combination utilities, Master Meter is your
                                partner to deliver game changing results across Finance, Customer Service,
                                and Utility Operations. Master Meter’s vast sales presence and partner
                                network ensure a personalized, attentive service to ensure your specific
                                needs are met, and your expectations exceeded. Contact us today to
                                determine which smart water metering solution is right for you.
                            </p>
                            <div className="aboutImg">
                                <img src={images.aboutLogo} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </Mobile>
    );
};

export default About;
