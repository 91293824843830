import React from 'react';
import * as validators from "../../validators";
import images from "../../images";
import './ChangePassword.css';
import '../../App.css';
import * as api from "../../api";
import Input from 'react-validation/build/input';
import Form from 'react-validation/build/form';
import Button from 'react-validation/build/button';
import {Desktop, Mobile} from "../../App";
import DataManager from "../../managers/DataManager";
import Popup from "reactjs-popup";
import AppPopup from "../../components/AppPopup";

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pass: '',
            confirmPass: '',
            isSecureNewPass: true,
            isSecureConfirmPass: true,
            errorMessage: null
        };
    }

    render() {
        return (
            <div>
                <Mobile>
                    {matches => {
                        if (matches)
                            return this._renderMobile();
                        else
                            return this._renderDesktop();
                    }}
                </Mobile>
            </div>
        );
    }

    _renderMobile() {
        return (
            <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginRight: 20,
                marginLeft: 20
            }}>
                <h1 className={"titleMobile"} style={{fontSize: 17}}>Change Password</h1>
                <Form method={"post"} onSubmit={this.onSubmit.bind(this)}>
                    <div style={{marginTop: 10}}>
                        <h1 className={"choosePassword"} style={{textAlign: 'center'}}>CHOOSE A PASSWORD YOU WISH TO
                            USE</h1>
                        <div style={{
                            flexDirection: 'row',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Input validations={[validators.required]} placeholder="Password"
                                   className="rectangleMobile rectangle" name="password"
                                   value={this.state.pass}
                                   type={this.state.isSecureNewPass ? "password" : "text"}
                                   onChange={(event) => this.setState({pass: event.target.value})}
                                   style={{paddingLeft: 10, paddingRight: 10, display: 'flex', fontSize: 16}}>
                            </Input>
                            <button style={{float: 'right', marginLeft: -40, backgroundColor: 'transparent'}}
                                    onClick={(event) => this.toggleSecureNewPass(event)}>
                                <img src={images.icOpenEyeGray}/>
                            </button>
                        </div>
                    </div>
                    <div style={{marginTop: 20}}>
                        <h1 className={"choosePassword"} style={{textAlign: 'center'}}>TYPE THE PASSWORD AGAIN</h1>
                        <div style={{flexDirection: 'row', display: 'flex', justifyContent: 'center'}}>
                            <Input validations={[validators.required]} placeholder="Password"
                                   type={this.state.isSecureConfirmPass ? "password" : "text"}
                                   className="rectangle rectangleMobile" name="Confirm Password"
                                   value={this.state.confirmPass}
                                   onChange={(event) => this.setState({confirmPass: event.target.value})}
                                   style={{paddingLeft: 10, paddingRight: 10, display: 'flex', fontSize: 16}}>
                            </Input>
                            <button style={{float: 'right', marginLeft: -40, backgroundColor: 'transparent'}}
                                    onClick={(event) => this.toggleSecureConfirmPass(event)}>
                                <img src={images.icOpenEyeGray}/>
                            </button>
                        </div>
                    </div>
                    <div style={{marginTop: 10, display: 'flex', justifyContent: 'center'}}>
                        <Button type="submit" style={{border: 0, backgroundColor: 'transparent'}}>
                            <img src={images.btnSavePassword} className="btnSavePassword"/>
                        </Button>
                    </div>
                    <Popup
                        contentStyle={{borderRadius: 5}}
                        open={this.state.errorMessage}
                        onClose={this.closePopup.bind(this)}
                        lockScroll={true}
                    >
                        <AppPopup onClose={this.closePopup.bind(this)} message={this.state.errorMessage}/>
                    </Popup>
                </Form>
            </div>
        );
    }

    _renderDesktop() {
        return (
            <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <h1 className={"title"}>Change Password</h1>
                <Form method={"post"} onSubmit={this.onSubmit.bind(this)}>
                    <div style={{marginTop: 20}}>
                        <h1 className={"choosePassword"}>CHOOSE A PASSWORD YOU WISH TO USE</h1>
                        <div style={{flexDirection: 'row', display: 'flex'}}>
                            <Input validations={[validators.required]} placeholder="Password"
                                   className="rectangle" name="password"
                                   value={this.state.pass}
                                   type={this.state.isSecureNewPass ? "password" : "text"}
                                   onChange={(event) => this.setState({pass: event.target.value})}
                                   style={{paddingLeft: 10, paddingRight: 10, display: 'flex', fontSize: 16}}>
                            </Input>
                            <button style={{float: 'right', marginLeft: -40, backgroundColor: 'transparent'}}
                                    onClick={(event) => this.toggleSecureNewPass(event)}>
                                <img src={images.icOpenEyeGray}/>
                            </button>
                        </div>
                    </div>
                    <div style={{marginTop: 20}}>
                        <h1 className={"choosePassword"}>TYPE THE PASSWORD AGAIN</h1>
                        <div style={{flexDirection: 'row', display: 'flex'}}>
                            <Input validations={[validators.required]} placeholder="Password"
                                   type={this.state.isSecureConfirmPass ? "password" : "text"} className="rectangle"
                                   name="Confirm Password"
                                   value={this.state.confirmPass}
                                   onChange={(event) => this.setState({confirmPass: event.target.value})}
                                   style={{paddingLeft: 10, paddingRight: 10, display: 'flex', fontSize: 16}}>
                            </Input>
                            <button style={{float: 'right', marginLeft: -40, backgroundColor: 'transparent'}}
                                    onClick={(event) => this.toggleSecureConfirmPass(event)}>
                                <img src={images.icOpenEyeGray}/>
                            </button>
                        </div>
                    </div>
                    <div style={{marginTop: 20, display: 'flex', justifyContent: 'center'}}>
                        <Button type="submit" style={{border: 0, backgroundColor: 'transparent'}}>
                            <img src={images.btnSavePassword} className="btnSavePassword"/>
                        </Button>
                    </div>
                    <Popup
                        contentStyle={{borderRadius: 5}}
                        open={this.state.errorMessage}
                        onClose={this.closePopup.bind(this)}
                        lockScroll={true}
                    >
                        <AppPopup onClose={this.closePopup.bind(this)} message={this.state.errorMessage}/>
                    </Popup>
                </Form>
            </div>
        );
    }

    closePopup() {
        this.setState({errorMessage: null});
    }

    toggleSecureNewPass(event) {
        event.preventDefault();
        this.setState({isSecureNewPass: !this.state.isSecureNewPass});
    };

    toggleSecureConfirmPass(event) {
        event.preventDefault();
        this.setState({isSecureConfirmPass: !this.state.isSecureConfirmPass});
    };

    onSubmit = (event) => {
        event.preventDefault();

        const {pass, confirmPass} = this.state;
        if (pass !== confirmPass) {
            this.setState({errorMessage: 'Passwords do not match.'});
        } else {
            //const isCharsValid = /^\w+$/.test(pass);
            const isCharsValid = /^(?=.*[a-zA-Z])(?=.*[0-9])/.test(pass);
            const isNumCharsValid = pass.length >= 8;

            // this.setState({isCharsValid: isCharsValid});
            // this.setState({isNumCharsValid: isNumCharsValid});

            if (isCharsValid && isNumCharsValid) {
                //Success
                const {code} = this.props.match.params;
                //this.setState({isLoading: true});
                api.finishRecovery(code, pass, (response, error) => {
                    //this.setState({isLoading: false});
                    if (error) {
                        this.setState({errorMessage: 'Unknown error has occurred.'});
                    } else {
                        if (response.error) {
                            //Error
                            this.setState({errorMessage: response.error});
                        } else {
                            //Success
                            this.setState({errorMessage: 'Password was reset successfully.'});
                            //DataManager.sharedInstance.onEnter();

                            this.props.history.push('/login');
                        }
                    }
                });
            } else {
                this.setState({errorMessage: 'Password must contain numbers and letters.\nPassword must contain 8 characters or more.'});
            }
        }
    };
}

export default (ChangePassword)