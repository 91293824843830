import React from 'react';
import './AlertInfo.css';
import images from '../../images';
import globals from '../../globals';
import utils from '../../utils';
import * as api from '../../api';
import { Mobile } from '../../App';
import DataManager from '../../managers/DataManager';
import AppPopup from "../../components/AppPopup";
import Popup from "reactjs-popup";

class AlertInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errorMessage: '',
            alert: this.props.location.state.alert,
        };
    }

    componentWillMount() {
        api.getAlertDetails(
          this.state.alert.alertTypeId,
          this.state.alert.logId,
          (res, err) => {
            if (err) {
                this.setState({errorMessage: 'Cannot load alert details.'});
            } else {
              if (res.error) {
                this.setState({errorMessage: 'Cannot load alert details.'});
              } else if (res.length > 0) {
                const icon = this.state.alert.icon;
                res[0].icon = icon;
                this.setState({alert: res[0]});
              }
            }
          },
        );
      }

    render() {
        return (
            <div>
                <Mobile>
                    {matches => {
                        if (matches) return this._renderMobile();
                        else return this._renderDesktop();
                    }}
                </Mobile>
            </div>
        );
    }

    _renderMobile() {
        const { alert } = this.props.location.state;
        const utility = DataManager.sharedInstance.muni
            ? DataManager.sharedInstance.muni
            : { description: '', phoneNumber: '' };
        // const utility = {description: 'test', phoneNumber: '12345678'};

        return (
            <div
                style={{
                    flexDirection: 'column',
                    display: 'flex',
                    marginTop: 10
                }}
            >
                <div
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        display: 'flex'
                    }}
                >
                    <button
                        onClick={() => this.props.history.goBack()}
                        style={{
                            marginLeft: 10,
                            flexDirection: 'row',
                            alignItems: 'center',
                            display: 'flex',
                            flex: 1,
                            backgroundColor: 'transparent'
                        }}
                    >
                        <img
                            src={images.icBack}
                            style={{ width: 20, height: 20 }}
                        />
                        <div
                            style={{
                                marginLeft: 8,
                                fontSize: 15,
                                color: globals.COLOR.AZURE
                            }}
                        >
                            Back
                        </div>
                    </button>
                    <div
                        className="report"
                        style={{
                            display: 'flex',
                            flex: 3,
                            justifyContent: 'center'
                        }}
                    >
                        Report
                    </div>
                    <div style={{ flex: 1, display: 'flex' }} />
                </div>
                <div
                    className="alertInfoType"
                    style={{ marginLeft: 20, marginTop: 20 }}
                >
                    {alert.alertTypeName}
                </div>
                <div
                    className="alertInfoDate"
                    style={{ marginLeft: 20, marginTop: 4 }}
                >
                    {utils.toAlertDate(alert.alertTime)}
                </div>
                <div
                    className="alertInfoWaterMeter"
                    style={{ marginLeft: 20, marginTop: 20 }}
                >
                    Water Meter: {alert.meterSn}
                </div>
                <div className="alertInfoAddress" style={{ marginLeft: 20 }}>
                    {alert.address
                        ? alert.address
                        : globals.METER_DEFAULT_ADDRESS}
                </div>
                <div
                    className="alertInfoText"
                    style={{
                        marginTop: 10,
                        marginLeft: 20
                    }}
                >
                    {this.state.alert.description ? this.state.alert.description : ''}
                </div>
                <div
                    className="alertInfoText"
                    style={{ marginTop: 15, alignSelf: 'center' }}
                >
                    Call us if you need any help
                </div>
                <div className="alertInfoCall" style={{ alignSelf: 'center' }}>
                    {utility.description}
                </div>
                <div className="alertInfoCall" style={{ alignSelf: 'center' }}>
                    {utility.phoneNumber}
                </div>
                <button
                    onClick={() => this._confirmAlert(alert)}
                    style={{
                        alignSelf: 'center',
                        backgroundColor: 'transparent',
                        marginBottom: 100
                    }}
                >
                    <img src={images.btnConfirm} />
                </button>
                <Popup
                    contentStyle={{borderRadius: 5}}
                    open={this.state.errorMessage}
                    onClose={this.closePopup.bind(this)}
                    lockScroll={true}
                >
                    <AppPopup onClose={this.closePopup.bind(this)} message={this.state.errorMessage}/>
                </Popup>
            </div>
        );
    }



    _renderDesktop() {
        const { alert } = this.props.location.state;
        const utility = DataManager.sharedInstance.muni
            ? DataManager.sharedInstance.muni
            : { description: '', phoneNumber: '' };
        // const utility = {description: 'test', phoneNumber: '12345678'};

        return (
            <div
                style={{
                    flexDirection: 'column',
                    display: 'flex',
                    marginTop: 10
                }}
            >
                <div
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        display: 'flex'
                    }}
                >
                    <button
                        onClick={() => this.props.history.goBack()}
                        style={{
                            marginLeft: 10,
                            flexDirection: 'row',
                            alignItems: 'center',
                            display: 'flex',
                            flex: 1,
                            backgroundColor: 'transparent'
                        }}
                    >
                        <img
                            src={images.icBack}
                            style={{ width: 20, height: 20 }}
                        />
                        <div
                            style={{
                                marginLeft: 8,
                                fontSize: 15,
                                color: globals.COLOR.AZURE
                            }}
                        >
                            Back
                        </div>
                    </button>
                    <div
                        className="report"
                        style={{
                            display: 'flex',
                            flex: 3,
                            justifyContent: 'center'
                        }}
                    >
                        Report
                    </div>
                    <div style={{ flex: 1, display: 'flex' }} />
                </div>
                <div
                    className="alertInfoType"
                    style={{ marginLeft: 20, marginTop: 20 }}
                >
                    {alert.alertTypeName}
                </div>
                <div
                    className="alertInfoDate"
                    style={{ marginLeft: 20, marginTop: 4 }}
                >
                    {utils.toAlertDate(alert.alertTime)}
                </div>
                <div
                    className="alertInfoWaterMeter"
                    style={{ marginLeft: 20, marginTop: 20 }}
                >
                    Water Meter: {alert.meterSn}
                </div>
                <div className="alertInfoAddress" style={{ marginLeft: 20 }}>
                    {alert.address
                        ? alert.address
                        : globals.METER_DEFAULT_ADDRESS}
                </div>
                <div
                    className="alertInfoText"
                    style={{
                        marginTop: 10,
                        marginLeft: 20
                    }}
                >
                    {this.state.alert.description ? this.state.alert.description : ''}
                </div>
                <div
                    className="alertInfoText"
                    style={{ marginTop: 15, alignSelf: 'center' }}
                >
                    Call us if you need any help
                </div>
                <div className="alertInfoCall" style={{ alignSelf: 'center' }}>
                    {utility.description}
                </div>
                <div className="alertInfoCall" style={{ alignSelf: 'center' }}>
                    {utility.phoneNumber}
                </div>
                <button
                    onClick={() => this._confirmAlert(alert)}
                    style={{
                        alignSelf: 'center',
                        backgroundColor: 'transparent'
                    }}
                >
                    <img src={images.btnConfirm} />
                </button>
                <Popup
                    contentStyle={{borderRadius: 5}}
                    open={this.state.errorMessage}
                    onClose={this.closePopup.bind(this)}
                    lockScroll={true}
                >
                    <AppPopup onClose={this.closePopup.bind(this)} message={this.state.errorMessage}/>
                </Popup>
            </div>
        );
    }

    closePopup() {
        let isAlertConfirmed = (this.state.errorMessage === 'Alert confirmed successfully') ? true : false;
        this.setState({errorMessage: null}, () => {
            if (isAlertConfirmed) {
                setTimeout(() => {
                    this.props.history.goBack();
                 }, 100);    
            }
        });
    }

    _confirmAlert(alertItem) {
        //this.setState({isLoading: true});
        api.confirmAlert(alertItem.logId, (res, err) => {
            //this.setState({isLoading: false});
            if (err) {
                this.setState({errorMessage: 'Could not confirm alert'});
            } else {
                if (res.error) {
                    this.setState({errorMessage: res.error});
                } else {
                    this.setState({errorMessage: 'Alert confirmed successfully'});
                }
            }
        });
    }
}

export default (AlertInfo)