import globals from './globals';
import DataManager from "./managers/DataManager";
import errors from "./errors";

export let token = undefined;

const _getFile = (url, authorize, callback) => {
    console.log('token', token);

    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };

    if (authorize) headers['x-access-token'] = token;

    fetch(url, {
        method: 'GET',
        headers: headers
    })
    // .then(async res => {
    //     let text = (await res.text()).toString();
    //     text = text.replaceAll(',0', ', ');
    //     console.log('res', text);

    //      const csvFile = new Blob([text], {type: 'text/csv'});
    //      return csvFile;
    // })
        .then(res => res.blob())
        .then(blob => callback(blob, null));
};

const _get = (url, authorize, callback) => {
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };

    if (authorize) headers['x-access-token'] = token;

    fetch(url, {
        method: 'GET',
        headers: headers
    })
        .then(res => {
            const statusCode = res.status;
            if (statusCode === 401) {
                DataManager.sharedInstance.isAuthenticated = false;
            }
            //return res.json();
            return res.text().then(function (text) {
                return text ? JSON.parse(text) : {}
            }).catch(err => {
                return err;
            });
        })
        .then((res, err) => {
            console.log(
                url +
                ' ' +
                'response: ' +
                JSON.stringify(res) +
                '\nerror: ' +
                err
            );

            // if (res.error) {
            //     const errorCode = res.code;
            //     res.error = errors[errorCode];
            // }

            callback(res, err);
        }).catch(err => {
        if (callback)
            callback(null, err);

    });
};

const _post = (url, params, authorize, callback) => {
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };

    if (authorize) {
        headers['x-access-token'] = token;
    } else {
        headers['x-app-id'] = globals.APP_ID;
    }

    fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(params)
    })
        .then(res => {
            const statusCode = res.status;
            if (statusCode === 401) {
                DataManager.sharedInstance.isAuthenticated = false;
            }

            //return res.json()
            return res.text().then(function (text) {
                return text ? JSON.parse(text) : {}
            }).catch(err => {
                return err;
            });
        })
        .then((res, err) => {
            console.log(
                url +
                ' ' +
                'response: ' +
                JSON.stringify(res) +
                '\nerror: ' +
                err
            );

            // if (res.error) {
            //     const errorCode = res.code;
            //     res.error = errors[errorCode];
            // }

            if (typeof res?.errors !== 'undefined') {
                err = 'Unknown error has occurred.';
            }

            callback(res, err);
        }).catch(err => {
            if (callback)
                callback(null, err);
    });
};

const _put = (url, params, authorize, callback) => {
    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };

    if (authorize) headers['x-access-token'] = token;

    fetch(url, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(params)
    })
        .then(res => {
            const statusCode = res.status;
            if (statusCode === 401) {
                DataManager.sharedInstance.isAuthenticated = false;
            }

            return res.text().then(function (text) {
                return text ? JSON.parse(text) : {}
            }).catch(err => {
                return err;
            });
        })
        .then((res, err) => {
            console.log(
                url +
                ' ' +
                'response: ' +
                JSON.stringify(res) +
                '\nerror: ' +
                err
            );

            // if (res.error) {
            //     const errorCode = res.code;
            //     res.error = errors[errorCode];
            // }

            callback(res, err);
        })
        .catch(err => {
            if (callback)
                callback(null, err);
        });
};

const _delete = (url, params, authorize, callback) => {
    let headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    };

    if (authorize) headers['x-access-token'] = token;

    fetch(url, {
        method: 'DELETE',
        headers: headers,
        body: JSON.stringify(params)
    })
        .then(res => {
            const statusCode = res.status;
            if (statusCode === 401) {
                DataManager.sharedInstance.isAuthenticated = false;
            }

            return res.text().then(function (text) {
                return text ? JSON.parse(text) : {}
            }).catch(err => {
                return err;
            });
        })
        .then((res, err) => {
            console.log(
                url +
                ' ' +
                'response: ' +
                JSON.stringify(res) +
                '\nerror: ' +
                err
            );

            // if (res.error) {
            //     const errorCode = res.code;
            //     res.error = errors[errorCode];
            // }

            callback(res, err);
        })
        .catch(err => {
            if (callback) callback(null, err);
        });
};

export const setTokenValue = tokenValue => {
    token = tokenValue;
};

//Push
export const sendDeviceToken = (deviceId, token, callback) => {
    let url = `${
        globals.BASE_URL
        }/communication/mobiledevices/pushprovidertoken`;
    console.log(url);

    const params = {
        deviceId: deviceId,
        pushProviderToken: token,
        pushProviderId: 1
    };

    _post(url, params, true, callback);
};

//Vacations
export const deleteVacation = (vacationID, callback) => {
    let url = `${globals.BASE_URL}/consumer/vacations/${vacationID}`;
    console.log(url);

    _delete(url, {}, true, callback);
};

export const editVacation = (
    vacationID,
    startDate,
    endDate,
    consumptionDailyLimit,
    meterCount,
    callback
) => {
    let url = `${globals.BASE_URL}/consumer/vacations/`;
    console.log(url);

    const params = {
        vacationID: vacationID,
        startDate: startDate,
        endDate: endDate,
        consumptionDailyLimit: consumptionDailyLimit,
        meterCount: meterCount
    };

    _put(url, params, true, callback);
};

export const addVacation = (
    startDate,
    endDate,
    consumptionDailyLimit,
    meterCount,
    callback
) => {
    let url = `${globals.BASE_URL}/consumer/vacations/`;
    console.log(url);

    const params = {
        vacationID: 0,
        startDate: startDate,
        endDate: endDate,
        consumptionDailyLimit: consumptionDailyLimit,
        meterCount: meterCount
    };

    _post(url, params, true, callback);
};

export const getVacations = callback => {
    let url = `${globals.BASE_URL}/consumer/vacations/`;
    console.log(url);

    _get(url, true, callback);
};

//Alerts
export const confirmAlert = (alertLogId, callback) => {
    let url = `${globals.BASE_URL}/consumer/myalerts/confirm/${alertLogId}`;
    console.log(url);

    _post(url, {}, true, callback);
};

export const getAlertDetails = (alertTypeId, alertLogId, callback) => {
    let url = `${
        globals.BASE_URL
        }/consumer/myalerts/${alertLogId}/${alertTypeId}`;
    console.log(url);

    _get(url, true, callback);
};

export const getAlerts = callback => {
    let url = `${globals.BASE_URL}/consumer/myalerts`;
    console.log(url);

    _get(url, true, callback);
};

//Alerts settings
export const unsetAlert = (alertTypeId, mediaTypeId, callback) => {
    let url = `${globals.BASE_URL}/consumer/myalerts/settings/${alertTypeId}`;
    console.log(url);

    const params = [mediaTypeId];

    _delete(url, params, true, callback);
};

export const setAlert = (alertTypeId, mediaTypeId, callback) => {
    let url = `${globals.BASE_URL}/consumer/myalerts/settings/${alertTypeId}`;
    console.log(url);

    const params = [mediaTypeId];

    _put(url, params, true, callback);
};

export const getAlertSettings = callback => {
    let url = `${globals.BASE_URL}/consumer/myalerts/settings`;
    console.log(url);

    _get(url, true, callback);
};

export const getMe = callback => {
    let url = `${globals.BASE_URL}/consumer/me`;
    console.log(url);

    _get(url, true, callback);
};

export const adminLogin = (email, password, captchaResponse, callback) => {
    let url = `${globals.BASE_URL}/account/login`;
    console.log(url);

    const params = {
        username: email,
        password,
        lc: 'en-US',
        captchaResponse
    };

    _post(url, params, false, callback);
};

export const login = (email, password, deviceId, callback) => {
    let url = `${globals.BASE_URL}/consumer/login`;
    console.log(url);

    const params = {
        email: email,
        type: 1,
        pw: password,
        deviceId: deviceId,
        osType: 3,
        app: "3a869241-d476-40f6-a923-d789d63db11d",

    };

    console.log('login params', params);

    _post(url, params, false, callback);
};

export const getAvgHouseHoldsConsumption = (fromDate, toDate, callback) => {
    let url = `${
        globals.BASE_URL
        }/consumption/avghouseholds/${fromDate}/${toDate}`;
    console.log(url);

    _get(url, true, callback);
};

// /v1/meters/billing-cycles
export const getAllMetersBillingCycleDates = (callback) => {
    let url = `${globals.BASE_URL}/v1.1/meters/billing-cycles`;
    console.log(url);

    _get(url, true, callback);
};

export const getMeterBillingCycleDates = (meterCount, callback) => {
    let url = `${globals.BASE_URL}/meters/${meterCount}/billing-cycles?cycles=current,previous`;
    console.log(url);

    _get(url, true, callback);
};

export const getAllHourlyConsumption = (fromDate, toDate, callback) => {
    let url = `${globals.BASE_URL}/consumption/hourly/${fromDate}/${toDate}`;
    console.log(url);

    _get(url, true, callback);
};

export const getAllDailyConsumption = (fromDate, toDate, callback) => {
    let url = `${globals.BASE_URL}/consumption/daily/${fromDate}/${toDate}`;
    console.log(url);

    _get(url, true, callback);
};

export const getAllDailyConsumptionBasedOnBillingCycle = (callback) => {
    let url = `${globals.BASE_URL}/consumption/daily/lastbillingcycle`;
    console.log(url);

    _get(url, true, callback);
};

export const getAllMonthlyConsumption = (fromMonth, toMonth, callback) => {
    let url = `${globals.BASE_URL}/v1.1/consumption/monthly/${fromMonth}/${toMonth}`;
    console.log(url);

    _get(url, true, callback);
};

export const getAllHourlyConsumptionExcel = (meterCount, fromDate, toDate, callback) => {
    let url = `${
        globals.BASE_URL
        }/consumption/hourlyExcel/${meterCount !== null ? meterCount + '/' : ''}${fromDate}/${toDate}`;
    console.log(url);

    _getFile(url, true, callback);
};

export const getAllDailyConsumptionExcelBasedOnBillingCycle = (meterCount, callback) => {
    let url = `${
        globals.BASE_URL
        }/consumption/dailyExcel/${meterCount !== null ? meterCount + '/' : ''}lastbillingcycle`;
    console.log(url);

    _getFile(url, true, callback);
};

export const getAllDailyConsumptionExcel = (meterCount, fromDate, toDate, callback) => {
    let url = `${
        globals.BASE_URL
        }/consumption/dailyExcel/${meterCount !== null ? meterCount + '/' : ''}${fromDate}/${toDate}`;
    console.log(url);

    _getFile(url, true, callback);
};

export const getAllMonthlyConsumptionExcel = (meterCount, fromMonth, toMonth, callback) => {
    let url = `${
        globals.BASE_URL
        }/consumption/monthlyExcel/${meterCount !== null ? meterCount + '/' : ''}${fromMonth}/${toMonth}`;
    console.log(url);

    _getFile(url, true, callback);
};

export const getAllMonthlyForecast = callback => {
    let url = `${globals.BASE_URL}/v1/consumption/forecast`;
    console.log(url);

    _get(url, true, callback);
};

export const getMeterHourlyConsumption = (
    meterCount,
    fromDate,
    toDate,
    callback
) => {
    let url = `${
        globals.BASE_URL
        }/consumption/hourly/${meterCount}/${fromDate}/${toDate}`;
    console.log(url);

    _get(url, true, callback);
};

export const getMeterDailyConsumption = (
    meterCount,
    fromDate,
    toDate,
    callback
) => {
    let url = `${
        globals.BASE_URL
        }/consumption/daily/${meterCount}/${fromDate}/${toDate}`;
    console.log(url);

    _get(url, true, callback);
};


export const getMeterDailyConsumptionBasedOnBillingCycle = (
    meterCount,
    callback
) => {
    let url = `${
        globals.BASE_URL
        }/consumption/daily/lastbillingCycle/${meterCount}`;
    console.log(url);

    _get(url, true, callback);
};

export const getMeterMonthlyConsumption = (
    meterCount,
    fromMonth,
    toMonth,
    callback
) => {
    let url = `${
        globals.BASE_URL
        }/v1.1/consumption/monthly/${meterCount}/${fromMonth}/${toMonth}`;
    console.log(url);

    _get(url, true, callback);
};

export const getMeterMonthlyForecast = (meterCount, callback) => {
    let url = `${globals.BASE_URL}/v1/consumption/forecast/${meterCount}`;
    console.log(url);

    _get(url, true, callback);
};

export const setMonthlyLimit = (limit, meterCount, callback) => {
    // settings/monthlylimit/{meterCount}/{limit}
    let url = `${globals.BASE_URL}/v1.1/consumer/settings/monthlylimit/${meterCount}/${limit}`;
    console.log(url);

    _post(url, {}, true, callback);
};

export const getMonthlyLimitAllMeters = (callback) => {
    let url = `${globals.BASE_URL}/v1.1/consumer/settings/monthlylimit`;
    console.log(url);

    _get(url, true, callback);
};

export const getMonthlyLimit = (meterCount, callback) => {
    // "settings/monthlylimit/{meterCount}
    let url = `${globals.BASE_URL}/v1.1/consumer/settings/monthlylimit/${meterCount}`;
    console.log(url);

    _get(url, true, callback);
};

export const deleteMonthlyLimit = (meterCount, callback) => {
    let url = `${globals.BASE_URL}/v1.1/consumer/settings/monthlylimit/${meterCount}`;
    console.log(url);

    _delete(url, {}, true, callback);
};

export const getMeasurementUnits = callback => {
    let url = `${globals.BASE_URL}/municipals/h1/measurmentunits`;
    console.log(url);

    _get(url, true, callback);
};

export const getMeters = callback => {
    let url = `${globals.BASE_URL}/consumer/meters`;
    console.log(url);

    _get(url, true, callback);
};

export const finishRecovery = (code, password, callback) => {
    let url = `${globals.BASE_URL}/consumer/password/recovery/finish`;
    console.log(url);

    const params = {
        code: code,
        password: password
    };

    _post(url, params, false, callback);
};

export const validateRecoveryCode = (code, callback) => {
    let url = `${globals.BASE_URL}/consumer/password/recovery/codevalidation`;
    console.log(url);

    const params = {
        code: code
    };

    _post(url, params, false, callback);
};

export const recoverPassword = (email, callback) => {
    let url = `${globals.BASE_URL}/consumer/password/recovery`;
    console.log(url);

    const params = {
        email: email
    };

    _post(url, params, false, callback);
};

export const getMunicipalCustomerService = callback => {
    let url = `${globals.BASE_URL}/municipals/municipalCustomerService`;
    console.log(url);

    _get(url, true, callback);
};

export const searchUtilities = (term, callback) => {
    let url = `${globals.BASE_URL}/municipals/h1/search/${term}`;
    console.log(url);

    _get(url, false, callback);
};

export const logout = callback => {
    let url = `${globals.BASE_URL}/consumer/logout`;
    console.log(url);

    _post(url, {}, true, callback);
};

export const registerPhone = (
    countryCode,
    phoneNumber,
    languageCode,
    callback
) => {
    let url = `${globals.BASE_URL}/consumer/register/phone`;
    console.log(url);

    const params = {
        countryCode: countryCode, //972
        phoneNumber: phoneNumber, //0544310754
        languageCode: languageCode
    };

    _post(url, params, false, callback);
};

export const registerAccount = (fullName, accountNumber, callback) => {
    let url = `${globals.BASE_URL}/consumer/register/account`;
    console.log(url);

    const params = {
        fullName: fullName,
        accountNumber: accountNumber
    };

    _post(url, params, false, callback);
};

export const registerFinish = (sessionId, email, password, code, callback) => {
    let url = `${globals.BASE_URL}/consumer/register/finish`;
    console.log(url);

    let params = {
        sessionId: sessionId,
        email: email,
        password: password,
        type: 1
    };

    if (code) params.code = code;

    _post(url, params, false, callback);
};

export const validateRegisterCode = (sessionId, code, callback) => {
    let url = `${globals.BASE_URL}/consumer/register/codevalidation`;
    console.log(url);

    const params = {
        sessionId: sessionId,
        code: code
    };

    _post(url, params, false, callback);
};

export const updatePhoneNumber = (
    countryCode,
    phoneNumber,
    languageCode,
    callback
) => {
    const url = `${globals.BASE_URL}/consumer/phone`;
    console.log(url);

    const params = {
        countryCode: countryCode, //972
        phoneNumber: phoneNumber, //0544310754
        languageCode: languageCode
    };

    _put(url, params, true, callback);
};

export const changePassword = (currentPassword, newPassword, callback) => {
    const url = `${globals.BASE_URL}/consumer/password/change`;

    const body = {
        currentPassword,
        newPassword
    };

    _put(url, body, true, callback);
};

//Admin
export const getLoginCredentials = (key,callback) => {
    let url = `${globals.BASE_URL}/consumer/login-credentials/${key}`;
    console.log(url);

    _get(url, true, callback);
};
