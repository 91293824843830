export default class PersistenceManager {
    static storeItem(key, value) {
        localStorage.setItem(key, value);
    }

    static getItem(key) {
        return localStorage.getItem(key);
    }

    static deleteItem(key) {
        localStorage.removeItem(key)
    }
}