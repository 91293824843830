import React from 'react';
import './Footer.css';
import images from '../../images';
import globals from '../../globals';
import MediaQuery from 'react-responsive';
import { Desktop, Mobile } from '../../App';
import { Link } from 'react-router-dom';
import UserGuidePDF from '../../resources/UserGuide.pdf';
import FAQPDF from '../../resources/FAQ.pdf';

export default class Footer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Mobile>
                {matches => {
                    if (matches) return this._renderMobile();
                    else return this._renderDesktop();
                }}
            </Mobile>
        );
    }

    _renderDesktop() {
        return (
            <div className="footer">
                <div
                    style={{
                        alignItems: 'center',
                        marginLeft: 40,
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'flex-start'
                    }}
                >
                    <h4
                        style={{
                            color: globals.COLOR.DUSK,
                            fontWeight: 'lighter',
                            fontSize: 12,
                            textAlign: 'center'
                        }}
                    >
                        All rights reserves ©2018 My Water Advisor
                    </h4>
                </div>
                <div
                    style={{
                        flex: 3,
                        flexDirection: 'row',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Link to={'/about'} className="footerLink">
                        About us
                    </Link>
                    <div
                        style={{
                            backgroundColor: globals.COLOR.DODGER_BLUE_3,
                            width: 2,
                            marginLeft: 8,
                            marginRight: 8,
                            height: 14,
                            alignSelf: 'center'
                        }}
                    />
                    <a
                        href="#"
                        className="footerLink"
                        onClick={e => {
                            e.preventDefault();
                            this.props.openTerms();
                        }}
                    >
                        Terms of use
                    </a>
                    <div
                        style={{
                            backgroundColor: globals.COLOR.DODGER_BLUE_3,
                            width: 2,
                            marginLeft: 8,
                            marginRight: 8,
                            height: 14,
                            alignSelf: 'center'
                        }}
                    />
                    <a
                        href="#"
                        className="footerLink"
                        onClick={e => {
                            e.preventDefault();
                            this.props.openPrivacy();
                        }}
                    >
                        Privacy policy
                    </a>
                    <div
                        style={{
                            backgroundColor: globals.COLOR.DODGER_BLUE_3,
                            width: 2,
                            marginLeft: 8,
                            marginRight: 8,
                            height: 14,
                            alignSelf: 'center'
                        }}
                    />
                    <Link to={'/contacts'} className="footerLink">
                        Contact
                    </Link>
                    <div
                        style={{
                            backgroundColor: globals.COLOR.DODGER_BLUE_3,
                            width: 2,
                            marginLeft: 8,
                            marginRight: 8,
                            height: 14,
                            alignSelf: 'center'
                        }}
                    />
                    <a
                        href={UserGuidePDF} download="My_File.pdf"
                        className="footerLink"
                    >
                        User Guide
                    </a>
                    <div
                        style={{
                            backgroundColor: globals.COLOR.DODGER_BLUE_3,
                            width: 2,
                            marginLeft: 8,
                            marginRight: 8,
                            height: 14,
                            alignSelf: 'center'
                        }}
                    />
                    <a
                        href={FAQPDF} download="FAQ.pdf"
                        className="footerLink"
                    >
                        FAQ
                    </a>
                </div>
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row',
                        flex: 1,
                        justifyContent: 'flex-end',
                        marginRight: 40
                    }}
                >
                    <img
                        className="logoMWAFooter"
                        src={images.logo}
                        width={30}
                        height={30}
                    />
                    <h5 className="myWaterAdvisor">My Water Advisor</h5>
                    <h5 className="myWaterAdvisorLogo">&nbsp;®</h5>
                </div>
            </div>
        );
    }

    _renderMobile() {
        return (
            <div className="footerMobile">
                <div
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >
                    <img src={images.logo} width={30} height={30} />
                    <h5 style={{ color: 'white', fontSize: 12 }}>
                        My Water Advisor
                    </h5>
                    <h5 className="myWaterAdvisorLogo">&nbsp;®</h5>
                </div>
                <div
                    style={{
                        flex: 3,
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Link to={'/about'} className="footerLink">
                        About us
                    </Link>
                    <div
                        style={{
                            backgroundColor: globals.COLOR.DODGER_BLUE_3,
                            width: 2,
                            marginLeft: 8,
                            marginRight: 8,
                            height: 14,
                            alignSelf: 'center'
                        }}
                    />
                    <a
                        href="#"
                        className="footerLink"
                        onClick={e => {
                            e.preventDefault();
                            this.props.openTerms();
                        }}
                    >
                        Terms of use
                    </a>
                    <div
                        style={{
                            backgroundColor: globals.COLOR.DODGER_BLUE_3,
                            width: 2,
                            marginLeft: 8,
                            marginRight: 8,
                            height: 14,
                            alignSelf: 'center'
                        }}
                    />
                    <a
                        href="#"
                        className="footerLink"
                        onClick={e => {
                            e.preventDefault();
                            this.props.openPrivacy();
                        }}
                    >
                        Privacy policy
                    </a>
                    <div
                        style={{
                            backgroundColor: globals.COLOR.DODGER_BLUE_3,
                            width: 2,
                            marginLeft: 8,
                            marginRight: 8,
                            height: 14,
                            alignSelf: 'center'
                        }}
                    />
                    <Link to={'/contacts'} className="footerLink">
                        Contact
                    </Link>
                    <div
                        style={{
                            backgroundColor: globals.COLOR.DODGER_BLUE_3,
                            width: 2,
                            marginLeft: 8,
                            marginRight: 8,
                            height: 14,
                            alignSelf: 'center'
                        }}
                    />
                    <a
                        href={UserGuidePDF} download="My_File.pdf"
                        className="footerLink"
                    >
                        User Guide
                    </a>
                    <div
                        style={{
                            backgroundColor: globals.COLOR.DODGER_BLUE_3,
                            width: 2,
                            marginLeft: 8,
                            marginRight: 8,
                            height: 14,
                            alignSelf: 'center'
                        }}
                    />
                    <a
                        href={FAQPDF} download="FAQ.pdf"
                        className="footerLink"
                    >
                        FAQ
                    </a>
                </div>
                <div style={{ alignItems: 'center', marginLeft: 20 }}>
                    <h4
                        style={{
                            color: globals.COLOR.DUSK,
                            fontWeight: 'lighter',
                            fontSize: 12,
                            textAlign: 'center'
                        }}
                    >
                        All rights reserves ©2018 My Water Advisor
                    </h4>
                </div>
            </div>
        );
    }
}
