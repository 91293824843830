import React from 'react';
import images from "../../images";
import {Link} from "react-router-dom";
import './ForgotPassword.css';
import {Desktop, Mobile} from "../../App";
import * as validators from "../../validators";
import Input from 'react-validation/build/input';
import Form from 'react-validation/build/form';
import Button from 'react-validation/build/button';
import * as api from "../../api";
import DataManager from "../../managers/DataManager";
import Popup from "reactjs-popup";
import AppPopup from "../../components/AppPopup";

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            errorMessage: null
        }
    }

    render() {
        return (
            <div>
                <Mobile>
                    {matches => {
                        if (matches)
                            return this._renderMobile();
                        else
                            return this._renderDesktop();
                    }}
                </Mobile>
            </div>
        );
    }

    _renderMobile() {
        return (
            <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', marginLeft: 20, marginRight: 20}}>
                <h2 className={"mainTitleMob"} style={{fontSize: 17}}>Change Password</h2>
                <h3 className="Enter-your-e-mail-below-and-we-will-send-you-the-i" style={{marginLeft: 20, marginRight: 20, fontSize: 15}}>Enter your e-mail below and we will
                    send you the instructions on how to reset your password</h3>
                <Form method={"post"} onSubmit={this.onSubmit.bind(this)}>
                    <div style={{margin: 10, marginTop: 30}}>
                        <Input validations={[validators.required, validators.email]} placeholder="E-mail" type="email"
                               className="Rectangle rectangleMobile" name="email" value={this.state.email}
                               onChange={(event) => this.setState({email: event.target.value})}
                               style={{paddingLeft: 10, paddingRight: 10, display: 'flex'}}>
                        </Input>
                    </div>
                    <div style={{margin: 10, display: 'flex', justifyContent: 'center'}}>
                        <Button type="submit" style={{border: 0, backgroundColor: 'transparent'}}>
                            <img src={images.btnSendInstructions} className="btn_send_instructions"/>
                        </Button>
                    </div>
                </Form>
                <Link to='/'>Cancel</Link>
                <Popup
                    contentStyle={{borderRadius: 5}}
                    open={this.state.errorMessage}
                    onClose={this.closePopup.bind(this)}
                    lockScroll={true}
                >
                    <AppPopup onClose={this.closePopup.bind(this)} message={this.state.errorMessage}/>
                </Popup>
            </div>
        );
    }

    _renderDesktop() {
        return (
            <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <h1 className={"title"}>Change Password</h1>
                <h3 className="Enter-your-e-mail-below-and-we-will-send-you-the-i">Enter your e-mail below and we will
                    send you the instructions on how to reset your password</h3>
                <Form method={"post"} onSubmit={this.onSubmit.bind(this)}>
                    <div style={{marginTop: 20}}>
                        <Input validations={[validators.required, validators.email]} placeholder="E-mail" type="email"
                               className="Rectangle" name="email" value={this.state.email} onChange={(event) => this.setState({email: event.target.value})}
                               style={{paddingLeft: 10, paddingRight: 10, display: 'flex'}}>
                        </Input>
                    </div>
                    <div style={{marginTop: 20, display: 'flex', justifyContent: 'center'}}>
                        <Button type="submit" style={{border: 0, backgroundColor: 'transparent'}}>
                            <img src={images.btnSendInstructions} className="btn_send_instructions"/>
                        </Button>
                    </div>
                    <Popup
                        contentStyle={{borderRadius: 5}}
                        open={this.state.errorMessage}
                        onClose={this.closePopup.bind(this)}
                        lockScroll={true}
                    >
                        <AppPopup onClose={this.closePopup.bind(this)} message={this.state.errorMessage}/>
                    </Popup>
                </Form>
                <Link to='/'>Cancel</Link>
            </div>
        );
    }

    closePopup() {
        this.setState({errorMessage: null});
    }

    onSubmit = (event) => {
        event.preventDefault();

        //this.setState({isLoading: true});
        api.recoverPassword(this.state.email, (res, error) => {
            //this.setState({isLoading: false});
            if (error) {
                this.setState({errorMessage: 'Password recovery failed.'});
            } else {
                if (res.error) {
                    this.setState({errorMessage: res.error});
                } else {
                    this.props.history.push(`/password/code`);
                }
            }
        })

    };
}

export default (ForgotPassword)