import React from 'react';
import {Desktop, Mobile} from "../../App";
import './RegisterFinish.css';
import * as api from "../../api";
import Input from 'react-validation/build/input';
import Form from 'react-validation/build/form';
import Button from 'react-validation/build/button';
import * as validators from '../../validators';
import DataManager from "../../managers/DataManager";
import images from "../../images";
import {Link} from "react-router-dom";
import Popup from "reactjs-popup";
import TermsOfUse from "../common/TermsOfUse";
import PersistenceManager from "../../managers/PersistenceManager";
import utils from "../../utils";
import globals from "../../globals";
import AppPopup from "../../components/AppPopup";

class RegisterFinish extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            confirmEmail: '',
            pass: '',
            confirmPass: '',
            isSecure: true,
            openTerms: false,
            openPrivacy: false,
            isNumCharsValid: true,
            isCharsValid: true,
            errorMessage: null
        }
    }

    render() {
        return (
            <div>
                <Mobile>
                    {matches => {
                        if (matches)
                            return this._renderMobile();
                        else
                            return this._renderDesktop();
                    }}
                </Mobile>
            </div>
        );
    }

    _renderMobile() {
        return (
            <div style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginRight: 20,
                marginLeft: 20,
                marginTop: -36
            }}>
                <h1 className={"titleMobile"}>Join My Water Advisor</h1>
                <Form method={"post"} onSubmit={this.onSubmit.bind(this)}>
                    <div style={{margin: 10}}>
                        <h1 className="inputTitleMobile">PROVIDE A VALID E-MAIL</h1>
                        <Input validations={[validators.required]} placeholder="Email"
                               type="email"
                               className="rectangle rectangleMobile" name="email"
                               value={this.state.email}
                               onChange={(event) => this.setState({email: event.target.value})}
                               style={{paddingLeft: 10, paddingRight: 10, display: 'flex', fontSize: 16}}>
                        </Input>
                    </div>
                    <div style={{margin: 10}}>
                        <h1 className={"inputTitle"}>REPEAT E-MAIL</h1>
                        <Input validations={[validators.required]} placeholder="Email"
                               type="email"
                               className="rectangle rectangleMobile" name="confirmEmail"
                               value={this.state.confirmEmail}
                               onChange={(event) => this.setState({confirmEmail: event.target.value})}
                               style={{paddingLeft: 10, paddingRight: 10, display: 'flex', fontSize: 16}}>
                        </Input>
                    </div>
                    <div style={{margin: 10}}>
                        <h1 className={"inputTitle"}>CHOOSE A NEW PASSWORD TO USE</h1>
                        <div style={{flexDirection: 'row', display: 'flex'}}>
                            <Input validations={[validators.required]} placeholder="Password"
                                   type={this.state.isSecure ? "password" : "text"}
                                   className="rectangle rectangleMobile"
                                   name="password"
                                   value={this.state.pass}
                                   onChange={(event) => this.setState({pass: event.target.value})}
                                   style={{paddingLeft: 10, paddingRight: 10, display: 'flex', fontSize: 16}}>
                            </Input>
                            <button type={'button'} style={{float: 'right', marginLeft: -40, backgroundColor: 'transparent'}}
                                    onClick={(event) => this.toggleSecurePass(event)}>
                                <img src={images.icOpenEyeGray}/>
                            </button>
                        </div>
                    </div>
                    <p className={"passwordRulesText"} style={{
                        margin: 10,
                        marginTop: 8,
                        color: this.state.isCharsValid ? '#999ea8' : '#ff7a7a'
                    }}>Must contain numbers and
                        characters</p>
                    <p className={"passwordRulesText"}
                       style={{margin: 10, marginTop: 4, color: this.state.isNumCharsValid ? '#999ea8' : '#ff7a7a'}}>Must
                        contain 8 characters or
                        more</p>
                    <div style={{margin: 10}}>
                        <h1 className={"inputTitle"}>TYPE THE PASSWORD AGAIN</h1>
                        <div style={{flexDirection: 'row', display: 'flex'}}>
                            <Input validations={[validators.required]} placeholder="Password"
                                   type={this.state.isSecure ? "password" : "text"}
                                   className="rectangle rectangleMobile"
                                   name="Confirm Password"
                                   onChange={(event) => this.setState({confirmPass: event.target.value})}
                                   value={this.state.confirmPass}
                                   style={{paddingLeft: 10, paddingRight: 10, display: 'flex', fontSize: 16}}>
                            </Input>
                            <button type={'button'} style={{float: 'right', marginLeft: -40, backgroundColor: 'transparent'}}
                                    onClick={(event) => this.toggleSecurePass(event)}>
                                <img src={images.icOpenEyeGray}/>
                            </button>
                        </div>
                    </div>
                    <div style={{margin: 10}}>
                        <h4 className={"signingUp"} style={{textAlign: 'center'}}>By signing up, you agree to <a
                            onClick={this.openTerms.bind(this)}>Terms
                            of Use</a> and <a onClick={this.openPrivacy.bind(this)}>Privacy Policy</a></h4>
                    </div>
                    <div style={{marginTop: -20, display: 'flex', justifyContent: 'center'}}>
                        <Button type="submit" style={{border: 0, backgroundColor: 'transparent'}}>
                            <img src={images.btnFinishAndLogin} className="btnFinishAndLogin"/>
                        </Button>
                    </div>
                    <Popup contentStyle={{borderRadius: 5}} open={this.state.openTerms}
                           onClose={this.closeTerms.bind(this)} lockScroll={true}>
                        <TermsOfUse onClose={this.closeTerms.bind(this)}/>
                    </Popup>
                    <Popup contentStyle={{borderRadius: 5}} open={this.state.openPrivacy}
                           onClose={this.closePrivacy.bind(this)} lockScroll={true}>
                        <TermsOfUse onClose={this.closePrivacy.bind(this)}/>
                    </Popup>
                    <Popup
                        contentStyle={{borderRadius: 5}}
                        open={this.state.errorMessage}
                        onClose={this.closePopup.bind(this)}
                        lockScroll={true}
                    >
                        <AppPopup onClose={this.closePopup.bind(this)} message={this.state.errorMessage} />
                    </Popup>
                </Form>
            </div>
        );
    }

    _renderDesktop() {
        return (
            <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 20}}>
                <h1 className={"title"}>Join My Water Advisor</h1>
                <Form method={"post"} onSubmit={this.onSubmit.bind(this)}>
                    <div style={{marginTop: 20}}>
                        <h1 className={"inputTitle"}>PROVIDE A VALID E-MAIL</h1>
                        <Input validations={[validators.required]} placeholder="Email"
                               type="email"
                               className="rectangle" name="email"
                               value={this.state.email}
                               onChange={(event) => this.setState({email: event.target.value})}
                               style={{paddingLeft: 10, paddingRight: 10, display: 'flex', fontSize: 16}}>
                        </Input>
                    </div>
                    <div style={{marginTop: 20}}>
                        <h1 className={"inputTitle"}>REPEAT E-MAIL</h1>
                        <Input validations={[validators.required]} placeholder="Email"
                               type="email"
                               className="rectangle" name="confirmEmail"
                               value={this.state.confirmEmail}
                               onChange={(event) => this.setState({confirmEmail: event.target.value})}
                               style={{paddingLeft: 10, paddingRight: 10, display: 'flex', fontSize: 16}}>
                        </Input>
                    </div>
                    <div style={{marginTop: 20}}>
                        <h1 className={"inputTitle"}>CHOOSE A NEW PASSWORD TO USE</h1>
                        <div style={{flexDirection: 'row', display: 'flex'}}>
                            <Input validations={[validators.required]} placeholder="Password"
                                   type={this.state.isSecure ? "password" : "text"} className="rectangle"
                                   name="password"
                                   value={this.state.pass}
                                   onChange={(event) => this.setState({pass: event.target.value})}
                                   style={{paddingLeft: 10, paddingRight: 10, display: 'flex', fontSize: 16}}>
                            </Input>
                            <button type={'button'} style={{float: 'right', marginLeft: -40, backgroundColor: 'transparent'}}
                                    onClick={(event) => this.toggleSecurePass(event)}>
                                <img src={images.icOpenEyeGray}/>
                            </button>
                        </div>
                    </div>
                    <p className={"passwordRulesText"}
                       style={{margin: 0, marginTop: 8, color: this.state.isCharsValid ? '#999ea8' : '#ff7a7a'}}>Must
                        contain numbers and
                        characters</p>
                    <p className={"passwordRulesText"}
                       style={{margin: 0, marginTop: 4, color: this.state.isNumCharsValid ? '#999ea8' : '#ff7a7a'}}>Must
                        contain 8 characters or
                        more</p>
                    <div style={{marginTop: 20}}>
                        <h1 className={"inputTitle"}>TYPE THE PASSWORD AGAIN</h1>
                        <div style={{flexDirection: 'row', display: 'flex'}}>
                            <Input validations={[validators.required]} placeholder="Password"
                                   type={this.state.isSecure ? "password" : "text"} className="rectangle"
                                   name="Confirm Password"
                                   value={this.state.confirmPass}
                                   onChange={(event) => this.setState({confirmPass: event.target.value})}
                                   style={{paddingLeft: 10, paddingRight: 10, display: 'flex', fontSize: 16}}>
                            </Input>
                            <button type={'button'} style={{float: 'right', marginLeft: -40, backgroundColor: 'transparent'}}
                                    onClick={(event) => this.toggleSecurePass(event)}>
                                <img src={images.icOpenEyeGray}/>
                            </button>
                        </div>
                    </div>
                    <div style={{marginTop: 20}}>
                        <h4 className={"signingUp"}>By signing up, you agree to <a onClick={this.openTerms.bind(this)}>Terms
                            of Use</a> and <a onClick={this.openPrivacy.bind(this)}>Privacy Policy</a></h4>
                    </div>
                    <div style={{marginTop: 20, display: 'flex', justifyContent: 'center', marginBottom: 40}}>
                        <Button type="submit" style={{border: 0, backgroundColor: 'transparent'}}>
                            <img src={images.btnFinishAndLogin} className="btnFinishAndLogin"/>
                        </Button>
                    </div>
                    <Popup contentStyle={{borderRadius: 5}} open={this.state.openTerms}
                           onClose={this.closeTerms.bind(this)} lockScroll={true}>
                        <TermsOfUse onClose={this.closeTerms.bind(this)}/>
                    </Popup>
                    <Popup contentStyle={{borderRadius: 5}} open={this.state.openPrivacy}
                           onClose={this.closePrivacy.bind(this)} lockScroll={true}>
                        <TermsOfUse onClose={this.closePrivacy.bind(this)}/>
                    </Popup>
                    <Popup
                        contentStyle={{borderRadius: 5}}
                        open={this.state.errorMessage}
                        onClose={this.closePopup.bind(this)}
                        lockScroll={true}
                    >
                        <AppPopup onClose={this.closePopup.bind(this)} message={this.state.errorMessage} />
                    </Popup>
                </Form>
            </div>
        );
    }

    openPrivacy = () => {
        this.setState({openPrivacy: true});
    };

    closePrivacy = () => {
        this.setState({openPrivacy: false});
    };

    openTerms = () => {
        this.setState({openTerms: true});
    };

    closeTerms = () => {
        this.setState({openTerms: false});
    };

    closePopup = () => {
        this.setState({errorMessage: null});
    };

    onSubmit = (event) => {
        event.preventDefault();

        const sessionId = DataManager.sharedInstance.sessionId;
        const {code} = this.props.match.params;
        const {email, confirmEmail, pass, confirmPass} = this.state;
        if (pass !== confirmPass) {
            this.setState({errorMessage: 'Passwords do not match.'});
        } else if (email !== confirmEmail) {
            this.setState({errorMessage: 'Emails do not match.'});
        } else {
            //const isCharsValid = /^\w+$/.test(pass);
            const isCharsValid = /^(?=.*[a-zA-Z])(?=.*[0-9])/.test(pass);
            const isNumCharsValid = pass.length >= 8;

            this.setState({isCharsValid: isCharsValid});
            this.setState({isNumCharsValid: isNumCharsValid});

            if (isCharsValid && isNumCharsValid) {
                //Success
                //this.setState({isLoading: true});
                api.registerFinish(sessionId, email, pass, code, (response, error) => {
                    //this.setState({isLoading: false});
                    if (error) {
                        this.setState({errorMessage: 'Unknown error has occurred.'});
                    } else {
                        if (response.error) {
                            this.setState({errorMessage: response.error});
                        } else {
                            //Success
                            const uniqueId = utils.generateId();
                            api.login(email, pass, uniqueId, (response, error) => {
                                if (error) {
                                    console.log('Unknown error has occurred.');
                                    this.setState({errorMessage: 'Unknown error has occurred.'});
                                } else {
                                    if (response.error) {
                                        console.log(response.error);
                                        this.setState({errorMessage: response.error});
                                    } else {
                                        const token = response.token;
                                        console.log('token = ' + token);
                                        api.setTokenValue(token);

                                        PersistenceManager.storeItem(globals.STORAGE.TOKEN, token);

                                        this.props.history.push('/home');
                                        this.props.onLogin();
                                    }
                                }
                            });

                        }
                    }
                })
            }
        }

    };

    toggleSecurePass(event) {
        event.preventDefault();
        if (event.detail !== 0) {
            this.setState({ isSecure: !this.state.isSecure });
        }
    }
}

export default (RegisterFinish)