import React from "react";
import globals from "../globals";
import utils from "../utils";
import images from "../images";
import { Mobile } from "../App";

export default class MeterSelection extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Mobile>
          {(matches) => {
            if (matches) return this._renderMobile();
            else return this._renderDesktop();
          }}
        </Mobile>
      </div>
    );
  }

  _renderMobile() {
    return (
        <div style={{maxHeight: '100%'}}>
        <div style={{ padding: 20 }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ color: globals.COLOR.COOL_GRAY, fontSize: 16 }}>
              Choose Water Meter
            </div>
            <button
              onClick={() => this.props.onClose()}
              style={{ backgroundColor: "transparent" }}
            >
              <img src={images.icClose} style={{ width: 15, height: 15 }} />
            </button>
          </div>
          <div
            style={{
              height: 1,
              backgroundColor: globals.COLOR.COOL_GRAY,
              marginTop: 10,
              position: 'relative'
            }}
          />
          <div style={{maxHeight: '100%', overflowY: 'scroll'}}>
          {this.props.waterMeters.map((item) => {
            return (
              <div style={{ padding: 6 }}>
                <button
                  onClick={() => this.props.onMeterSelected(item)}
                  style={{
                    color: globals.COLOR.DUSK,
                    fontSize: 14,
                    backgroundColor: "transparent",
                  }}
                >
                  {utils.getMeterDesc(item)}
                </button>
              </div>
            );
          })}
          </div>
          <button
            style={{
              backgroundColor: globals.COLOR.WHITE_TWO,
              padding: 14,
              marginTop: 16,
              width: "100%",
              // position: 'fixed',
              // bottom: 0
            }}
            onClick={() => this.props.onMeterSelected(null)}
          >
            <div style={{ color: globals.COLOR.DUSK, fontSize: 14 }}>
              All water meters
            </div>
          </button>
        </div>
      </div>
    );
  }

  _renderDesktop() {
    return (
      <div style={{ width: "100%"}}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ color: globals.COLOR.COOL_GRAY, fontSize: 16 }}>
            Choose Water Meter
          </div>
          <button
            onClick={() => this.props.onClose()}
            style={{ backgroundColor: "transparent" }}
          >
            <img src={images.icClose} style={{ width: 15, height: 15 }} />
          </button>
        </div>
        <div
          style={{
            height: 1,
            backgroundColor: globals.COLOR.COOL_GRAY,
            marginTop: 10,
          }}
        />
        <div style={{maxHeight: 500, overflowY: "scroll"}}>
          {this.props.waterMeters.map((item) => {
            return (
              <div style={{ padding: 6 }}>
                <button
                  onClick={() => this.props.onMeterSelected(item)}
                  style={{ color: globals.COLOR.DUSK, fontSize: 14 }}
                >
                  {utils.getMeterDesc(item)}
                </button>
              </div>
            );
          })}
        </div>
        <button
          style={{
            backgroundColor: globals.COLOR.WHITE_TWO,
            padding: 14,
            width: "100%",
            borderRadius: 5
          }}
          onClick={() => this.props.onMeterSelected(null)}
        >
          <div style={{ color: globals.COLOR.DUSK, fontSize: 14 }}>
            All water meters
          </div>
        </button>
      </div>
    );
  }
}
