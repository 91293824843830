import './DefineVacationPopup.css';
import utils, {AlertType} from '../../utils';
import * as api from '../../api';
import DataManager from '../../managers/DataManager';
import moment from 'moment';
import {Desktop, Mobile} from '../../App';
import images from '../../images';
import Toggle from 'react-toggle';
import React from 'react';
import globals from '../../globals';
import CalendarRangePicker from '../../components/CalendarRangePicker';
import Popup from 'reactjs-popup';
import AppPopup from "../../components/AppPopup";

class DefineVacationPopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            smsAlert: false,
            emailAlert: false,
            consumptionDailyLimit: this.props.vacation?.consumptionDailyLimit ?? '',
            startDate: this.props.vacation?.startDate,
            endDate: this.props.vacation?.endDate,
            openCalendarPicker: false,
            errorMessage: null
        };

        this.vacationID = this.props.vacation?.vacationID;

        this.meterCount = this.props.currentMeter
        ? this.props.currentMeter
        : '';
    }

    componentWillMount() {
        //this.setState({isLoading: true});

        let tomorrowDate = new Date();
        tomorrowDate.setDate(tomorrowDate.getDate() + 1);

        if (typeof this.props.vacation?.startDate === 'undefined') {
            this.setState({
                startDate: tomorrowDate,
                endDate: tomorrowDate
            });
        }

        api.getAlertSettings((res, err) => {
            //this.setState({isLoading: false});
            if (err) {
                console.log('Unknown error has occurred.');
            } else {
                if (res.error) {
                    console.log(res.error);
                } else {
                    if (res.length > 0) {
                        let emailAlert = false;
                        let smsAlert = false;

                        for (let alertSetting of res) {
                            if (
                                alertSetting.alertTypeId ===
                                AlertType.DAILY_USAGE_VACATION
                            ) {
                                alertSetting.mediaTypeId === 0
                                    ? (emailAlert = true)
                                    : (smsAlert = true);
                            }
                        }

                        this.setState({emailAlert, smsAlert});
                    }
                }
            }
        });
    }

    render() {
        return (
            <div>
                <Mobile>
                    {matches => {
                        if (matches) return this._renderMobile();
                        else return this._renderDesktop();
                    }}
                </Mobile>
            </div>
        );
    }

    _renderMobile() {
        const units = DataManager.sharedInstance.units;
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: 10,
                    paddingLeft: 20
                }}
            >
                <h1 className={'setMonthlyTitleVacation'}>
                    Define usage limit while away
                </h1>
                <div
                    style={{
                        marginTop: 0,
                        flexDirection: 'row',
                        display: 'flex'
                    }}
                >
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div className="dailyUsage">Daily usage limit</div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginTop: 8
                            }}
                        >
                            <input
                                onChange={event =>
                                    this._onChangeLimit(event.target.value)
                                }
                                type="text"
                                className="borderRectVacation"
                                style={{
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    display: 'flex',
                                    fontSize: 16,
                                    marginTop: 8,
                                    // textAlign: 'center'
                                }}
                                value={this.state.consumptionDailyLimit}
                            />
                            <div
                                style={{marginLeft: 20}}
                                className="monthlyLimitUnits"
                            >
                                {units}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="dailyUsage" style={{marginTop: 20}}>
                    Vacation dates
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: 18
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%'
                        }}
                    >
                        <div className="fromTo">From</div>
                        <button
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginLeft: -8,
                                marginTop: 8,
                                backgroundColor: 'transparent'
                            }}
                            onClick={this.onChooseDate.bind(this)}
                        >
                            <img src={images.icSmallCalendar}/>
                            <input
                                placeholder="Choose date"
                                disabled={true}
                                type="text"
                                className="chooseDateInput"
                                style={{
                                    marginLeft: 8,
                                    border: 0,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    display: 'flex',
                                    width: '100%',
                                    fontSize: 13
                                }}
                                value={utils.toVacationDate(
                                    this.state.startDate
                                )}
                            />
                        </button>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50%'
                        }}
                    >
                        <div className="fromTo">To</div>
                        <button
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginLeft: -8,
                                marginTop: 8,
                                backgroundColor: 'transparent'
                            }}
                            onClick={this.onChooseDate.bind(this)}
                        >
                            <img src={images.icSmallCalendar}/>
                            <input
                                placeholder="Choose date"
                                disabled={true}
                                type="text"
                                className="chooseDateInput"
                                style={{
                                    marginLeft: 8,
                                    border: 0,
                                    width: '100%',
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    display: 'flex',
                                    fontSize: 13
                                }}
                                value={utils.toVacationDate(this.state.endDate)}
                            />
                        </button>
                    </div>
                </div>
                <div
                    style={{
                        marginTop: 24,
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'row',
                        width: '100%',
                        backgroundColor: 'transparent'
                    }}
                >
                    <button
                        style={{border: 0, backgroundColor: 'transparent'}} onClick={this.saveVacation.bind(this)}

                    >
                        <img
                            src={images.btnSaveBlue}
                            className="btnSave"
                            style={{width: 150}}
                        />
                    </button>
                    <button
                        style={{border: 0, backgroundColor: 'transparent'}} onClick={this.closePopup.bind(this)}

                    >
                        <img
                            src={images.btnCancelWhite}
                            className="btnSave"
                            style={{width: 150}}
                        />
                    </button>
                </div>

                <Popup
                    contentStyle={{borderRadius: 5, width: '80%'}}
                    open={this.state.openCalendarPicker}
                    closeOnDocumentClick={false}
                    lockScroll={true}
                >

                    <CalendarRangePicker
                        isVacation
                        startDate={new Date(this.state.startDate)}
                        endDate={new Date(this.state.endDate)}
                        onClose={this.closeCalendarPicker.bind(this)}
                    />
                </Popup>
                <Popup
                    contentStyle={{borderRadius: 5}}
                    open={this.state.errorMessage}
                    onClose={this.closeErrorPopup.bind(this)}
                    lockScroll={true}
                >
                    <AppPopup onClose={this.closeErrorPopup.bind(this)} message={this.state.errorMessage}/>
                </Popup>
            </div>
        );
    }

    _renderDesktop() {
        console.log('this.state.consumptionDailyLimit', this.state.consumptionDailyLimit);
        const units = DataManager.sharedInstance.units;

        let tomorrowDate = new Date();
        tomorrowDate.setDate(tomorrowDate.getDate() + 1);
        return (
            <div
                style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    // padding: 10,
                    paddingLeft: 34,
                }}
            >
                <h1 className={'setMonthlyTitleVacation'}>
                    Define usage limit while away
                </h1>
                <div
                    style={{
                        marginTop: 0,
                        flexDirection: 'column',
                        display: 'flex'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            // width: '50%'
                        }}
                    >
                        <div className="dailyUsage">Daily usage limit</div>
                        <div
                            className={parseFloat(this.state.consumptionDailyLimit) <= 0 ? "borderRectVacationError" : "borderRectVacation"}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                width: "360px",
                                marginTop: 10
                            }}
                        >
                            <input
                                onChange={event =>
                                    this._onChangeLimit(event.target.value)
                                }
                                type="text"
                                className="vacationInput"
                                style={{
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    display: 'flex',
                                    width: '85%',
                                    fontSize: 16,
                                    borderRadius: 0
                                    // textAlign: 'center'
                                }}
                                value={this.state.consumptionDailyLimit}
                            />
                            <div
                                style={{marginLeft: 20,  display: 'flex',
                                    width: '15%',}}
                                className="monthlyLimitUnits"
                            >
                                {units}
                            </div>
                        </div>
                        <div className="vacationErrorText" style={{marginTop: typeof this.state.consumptionDailyLimit !== 'undefined' && parseFloat(this.state.consumptionDailyLimit) === 0 ? 8 : 24}}>{typeof this.state.consumptionDailyLimit !== 'undefined' && parseFloat(this.state.consumptionDailyLimit) === 0 ? 'You need to choose more than 0 gallons' : ''}</div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            marginTop: 16
                        }}
                    >
                        <div className="dailyUsage">Vacation dates</div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginTop: 10
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <div className="fromTo">From</div>
                                <button
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginLeft: -8,
                                        marginTop: 8
                                    }}
                                    onClick={this.onChooseDate.bind(this)}
                                >
                                    <img src={images.icSmallCalendar}/>
                                    <input
                                        placeholder="Choose date"
                                        disabled={true}
                                        type="text"
                                        className="chooseDateInput"
                                        style={{
                                            marginLeft: 0,
                                            border: 0,
                                            paddingLeft: 10,
                                            display: 'flex',
                                            fontSize: 13,
                                            width: '100%'
                                        }}
                                        value={utils.toVacationDate(
                                            this.state.startDate ?  this.state.startDate : tomorrowDate
                                        )}
                                    />
                                </button>
                            </div>
                            <div
                                style={{
                                    backgroundColor: globals.COLOR.SILVER,
                                    width: 1,
                                    height: 30,
                                    marginLeft: 10,
                                    marginRight: 10,
                                    display: 'flex',
                                    marginTop: 20
                                }}
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <div className="fromTo">To</div>
                                <button
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginLeft: -8,
                                        marginTop: 8,
                                        marginRight: 20
                                    }}
                                    onClick={this.onChooseDate.bind(this)}
                                >
                                    <img src={images.icSmallCalendar}/>
                                    <input
                                        placeholder="Choose date"
                                        disabled={true}
                                        type="text"
                                        className="chooseDateInput"
                                        style={{
                                            marginLeft: 0,
                                            border: 0,
                                            paddingLeft: 10,
                                            display: 'flex',
                                            fontSize: 13
                                        }}
                                        value={utils.toVacationDate(
                                            this.state.endDate ? this.state.endDate : tomorrowDate
                                        )}
                                    />
                                </button>
                            </div>
                        </div>
                        <div
                            style={{
                                backgroundColor: globals.COLOR.SILVER,
                                width: '90%',
                                height: 1,
                                marginTop: 20
                            }}
                        />
                    </div>
                </div>
                <div
                    style={{
                        marginTop: 24,
                        display: 'flex',
                        // justifyContent: 'center', 
                        flexDirection: 'row',
                        width: '90%',
                        backgroundColor: 'transparent'
                    }}
                >
                    <button
                        style={{border: 0, backgroundColor: 'transparent'}} onClick={(typeof this.state.consumptionDailyLimit === 'undefined' || this.state.consumptionDailyLimit === '') ? null : this.saveVacation.bind(this)}
                    >
                        <img
                            src={(typeof this.state.consumptionDailyLimit === 'undefined' || this.state.consumptionDailyLimit === '') ? images.btnSaveGrey : images.btnSaveBlue}
                            className="btnSave"
                        />
                    </button>
                    <button
                        style={{border: 0, backgroundColor: 'transparent'}} onClick={this.closePopup.bind(this)}
                    >
                        <img
                            src={images.btnCancelWhite}
                            className="btnSave"
                        />
                    </button>
                </div>

                <Popup
                    contentStyle={{borderRadius: 5}}
                    open={this.state.openCalendarPicker}
                    closeOnDocumentClick={false}
                    lockScroll={true}
                >
                    <CalendarRangePicker
                        isVacation
                        startDate={this.state.startDate ? new Date(this.state.startDate) : tomorrowDate}
                        endDate={this.state.endDate ? new Date(this.state.endDate) : tomorrowDate}
                        onClose={this.closeCalendarPicker.bind(this)}
                    />
                </Popup>
                <Popup
                    contentStyle={{borderRadius: 5}}
                    open={!!this.state.errorMessage}
                    onClose={this.closeErrorPopup.bind(this)}
                    lockScroll={true}
                >
                    <AppPopup onClose={this.closeErrorPopup.bind(this)} message={this.state.errorMessage}/>
                </Popup>
            </div>
        );
    }

    closeErrorPopup = () => {
        this.setState({errorMessage: null});
    };

    _onChangeLimit = limit => {
        if (limit === '' || utils.containsNumbersAndPoint(limit))
            this.setState({consumptionDailyLimit: limit});
    };

    closeCalendarPicker = (startDate, endDate) => {
        this.setState({
            openCalendarPicker: false,
            startDate: startDate ? startDate : this.state.startDate,
            endDate: endDate ? endDate : this.state.endDate
        });
    };

    closePopupSave = vacation => {
        this.props.onClose(vacation);
    };

    closePopup = () => {
        this.props.onClose();
    };

    saveVacation = () => {
        if (isNaN(this.state.consumptionDailyLimit) || this.state.consumptionDailyLimit === '' || parseFloat(this.state.consumptionDailyLimit) <= 0) {
            this.setState({errorMessage: 'Invalid daily limit set'});
            return;
        }

        if (
            !this.state.startDate ||
            this.state.startDate === '' ||
            !this.state.endDate ||
            this.state.endDate === ''
        ) {
            this.setState({errorMessage: 'Invalid start/end date set'});
            return;
        }

        if (this.vacationID) {
            //Edit
            if (this.state.smsAlert) {
                api.setAlert(
                    AlertType.DAILY_USAGE_VACATION,
                    3,
                    (res, err) => {
                    }
                );
            } else {
                api.unsetAlert(
                    AlertType.DAILY_USAGE_VACATION,
                    3,
                    (res, err) => {
                    }
                );
            }

            if (this.state.emailAlert) {
                api.setAlert(
                    AlertType.DAILY_USAGE_VACATION,
                    1,
                    (res, err) => {
                    }
                );
            } else {
                api.unsetAlert(
                    AlertType.DAILY_USAGE_VACATION,
                    1,
                    (res, err) => {
                    }
                );
            }

            api.editVacation(
                this.vacationID,
                moment(this.state.startDate).format(
                    globals.DATE.STANDARD_FORMAT
                ),
                moment(this.state.endDate).format(globals.DATE.STANDARD_FORMAT),
                this.state.consumptionDailyLimit,
                this.meterCount.meterCount,
                (res, err) => {
                    if (err) {
                        this.setState({errorMessage: 'Unknown error has occurred.'});
                    } else {
                        if (res.error) {
                            this.setState({errorMessage: res.error});
                        } else {
                            DataManager.sharedInstance.vacation = {
                                startDate: this.state.startDate,
                                endDate: this.state.endDate,
                                vacationID: this.vacationID,
                                consumptionDailyLimit: this.state
                                    .consumptionDailyLimit,
                                meterCount: this.meterCount.meterCount
                            };

                            this.closePopupSave(
                                DataManager.sharedInstance.vacation
                            );
                        }
                    }
                }
            );
        } else {
            //Create
            if (this.state.smsAlert) {
                api.setAlert(
                    AlertType.DAILY_USAGE_VACATION,
                    3,
                    (res, err) => {
                    }
                );
            } else {
                api.unsetAlert(
                    AlertType.DAILY_USAGE_VACATION,
                    3,
                    (res, err) => {
                    }
                );
            }

            if (this.state.emailAlert) {
                api.setAlert(
                    AlertType.DAILY_USAGE_VACATION,
                    1,
                    (res, err) => {
                    }
                );
            } else {
                api.unsetAlert(
                    AlertType.DAILY_USAGE_VACATION,
                    1,
                    (res, err) => {
                    }
                );
            }
            api.addVacation(
                moment(this.state.startDate).format(
                    globals.DATE.STANDARD_FORMAT
                ),
                moment(this.state.endDate).format(globals.DATE.STANDARD_FORMAT),
                this.state.consumptionDailyLimit,
                this.meterCount.meterCount,
                (res, err) => {
                    this.setState({isLoading: false});
                    if (err) {
                        this.setState({errorMessage: 'Unknown error has occurred.'});
                    } else {
                        if (res.error) {
                            this.setState({errorMessage: res.error});
                        } else {
                            const vacationID = res.newVacationId;
                            DataManager.sharedInstance.vacation = {
                                startDate: this.state.startDate,
                                endDate: this.state.endDate,
                                vacationID: vacationID,
                                consumptionDailyLimit: this.state
                                    .consumptionDailyLimit,
                                meterCount: this.meterCount.meterCount
                            };

                            this.closePopupSave(
                                DataManager.sharedInstance.vacation
                            );
                        }
                    }
                }
            );
        }
    };

    onChooseDate() {
        this.setState({openCalendarPicker: true});
        // this.props.navigation.navigate('CalendarRangePicker', {
        //     startDate: this.state.startDate, endDate: this.state.endDate,
        //     onDateSelected: (startDate, endDate) => this.setState({startDate, endDate})
        // });
    }

    _toggleSMSAlertSwitch(smsAlert) {
        this.setState({smsAlert});
    }

    _toggleEmailAlertSwitch(emailAlert) {
        this.setState({emailAlert});
    }
}

export default (DefineVacationPopup)