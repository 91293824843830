export default {
    logo: require('./logo_new.svg'),
    icBack: require('./ic_back.svg'),
    icBigLock: require('./ic_big_lock.svg'),
    btnLogOut: require('./btn_log_out.svg'),
    icArrowRightBlue: require('./ic_arrow_right_blue.svg'),
    btnLogin: require('./btn_login.svg'),
    btnSendInstructions: require('./btn_send_instructions.svg'),
    btnContinue: require('./btn_continue.svg'),
    btnContinueGrey: require('./btn_continue_grey.svg'),
    btnGetCode: require('./btn_get_code.svg'),
    btnSavePassword: require('./btn_save_password.svg'),
    btnSignUp: require('./btn_sign up.svg'),
    btnFinishAndLogin: require('./btn_finish.svg'),
    btnDefineDays: require('./btn_define_days.svg'),
    btnJoin: require('./btn_join.svg'),
    btnJoinMob: require('./btn_join_mob.svg'),
    btnLoginWhite: require('./btn_log_in.svg'),
    btnLoginWhiteMob: require('./btn_log_in_mob.svg'),
    btnBack: require('./btn_back.svg'),
    btnGooglePlay: require('./ic_google play.svg'),
    btnAppStore: require('./ic_app store.svg'),
    icClose: require('./ic_close.svg'),
    icOpenEyeGray: require('./ic_eye_open_gray.svg'),
    icBigGauge: require('./ic_big_gauge.svg'),
    btnChangePassword: require('./btn_change_password.svg'),
    btnSaveBlue: require('./btn_save_blue.svg'),
    btnSaveGrey: require('./btn_save_grey.svg'),
    btnCancelWhite: require('./btn_cancel_white.svg'),
    btnConfirm: require('./btn_confirm.svg'),
    icWaterLimit: require('./ic_water_limit.svg'),
    icVacation: require('./ic_vacation.svg'),
    icFaucet: require('./ic_big_tap.svg'),
    icSmallCalendar: require('./ic_small_calendar.svg'),
    icLocation: require('./ic_location.svg'),
    lpImage: require('./landing_page_image.png'),
    lpTopImage: require('./landing_page_top.svg'),
    lpLogo: require('./logo_new.svg'),
    bgWave: require('./bg_wave.svg'),
    bgWaveMob: require('./bg_wave_mob.svg'),
    bgWaveBottom: require('./bg_wave_bottom.svg'),
    icEmpowerment: require('./ic_empowerement.svg'),
    icFlexibility: require('./ic_flexibility.svg'),
    icCustomerService: require('./ic_custom service.svg'),
    icSafety: require('./ic_safety.svg'),
    icClarity: require('./ic_clarity.svg'),
    icContext: require('./ic_context.svg'),
    icBarChart: require('./ic_bar_chart.svg'),
    icBarChartSelected: require('./ic_bar_chart_selected.svg'),
    icTableChart: require('./ic_table_chart.svg'),
    icTableChartSelected: require('./ic_table_chart_selected.svg'),
    icPrinter: require('./printer.svg'),
    icExcel: require('./excel.svg'),
    lpTopImageMob: require('./landing_page_top_mob.svg'),
    lpImageMob: require('./landing_page_image_mob.svg'),
    icPhoneCall: require('./phone-call.svg'),
    placeholder: require('./placeholder.png'),
    aboutLogo: require('./about_logo.png'),
    btnSeeDetails: require('./btn_see_details.svg'),
    icAlerts: require('./ic_alerts.svg'),
};
