import React from 'react';
import './Register.css';
import images from '../../images';
import '../../App.css';
import * as api from "../../api";
import globals from "../../globals";
import {Desktop, Mobile} from "../../App";

export default class Register extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchTerm: '',
            utilities: []
        }
    }

    componentWillMount() {
        if (this.props.onEnter)
            this.props.onEnter(false);
    }

    render() {
        return (
            <div>
                <Mobile>
                    {matches => {
                        if (matches)
                            return this._renderMobile();
                        else
                            return this._renderDesktop();
                    }}
                </Mobile>
            </div>
        );
    }

    _renderMobile() {
        return (
            <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginLeft: 20,
                marginRight: 20,
                marginTop: -36
            }}>
                <h1 className="title titleMobile" style={{fontSize: 17}}>Join My Water Advisor</h1>
                <div style={{marginTop: 10, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <button className="signUpCellMobile"
                            style={{
                                padding: 16,
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%'
                            }}
                            onClick={this.goToRegisterPhone.bind(this)}>
                        <div style={{flexDirection: 'column', display: 'flex', width: '100%'}}>
                            <p style={{padding: 0, margin: 0, textAlign: 'left'}}
                               className="Sign-up-with-cell-phone-number">Sign up with
                                cell phone number</p>
                            <p style={{padding: 0, margin: 0, marginTop: 10, textAlign: 'left'}}
                               className="Phone-number-registered-with-your-utility-or-servi">Phone number registered
                                with your utility or service provider</p>
                        </div>
                        <div>
                            <img src={images.icArrowRightBlue} style={{width: 16, height: 16}}/>
                        </div>
                    </button>
                    <button className="signUpCellMobile"
                            style={{
                                marginTop: 10,
                                padding: 16,
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%'
                            }}
                            onClick={this.goToRegisterAccount.bind(this)}>
                        <div style={{flexDirection: 'column', display: 'flex', width: '100%'}}>
                            <p style={{padding: 0, margin: 0, textAlign: 'left'}}
                               className="Sign-up-with-cell-phone-number">Sign up with
                                account number</p>
                            <p style={{padding: 0, margin: 0, marginTop: 10, textAlign: 'left'}}
                               className="Phone-number-registered-with-your-utility-or-servi">Account number as it
                                appears on the bill</p>
                        </div>
                        <div>
                            <img src={images.icArrowRightBlue} style={{width: 16, height: 16}}/>
                        </div>
                    </button>
                </div>
                <p style={{marginTop: 10, justifyContent: 'center'}} className="Need-help-Call-your-utility-office">Need
                    help? Call your utility office</p>
                <p style={{marginTop: -8, justifyContent: 'center', fontSize: 15, textAlign: 'center'}}
                   className="Your-water-utility-name-can-be-found-in-the-monthl">Your water utility name can be found
                    in the monthly water bill</p>
                <input placeholder="Search Utility by Name" type="text" className="RectangleMob"
                       style={{paddingLeft: 10, paddingRight: 10, width: '100%', marginTop: 10}}
                       onChange={this.onChangeUtilityName.bind(this)}>
                </input>
                <div style={{marginTop: 10}}>
                    {this.state.utilities.map(item => {
                        return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <p style={{color: globals.COLOR.DUSK, fontSize: 16}}>{item.description}</p>
                            <p style={{
                                color: globals.COLOR.AZURE,
                                fontSize: 15,
                                marginLeft: 10
                            }}>{item.phoneNumber}</p>
                        </div>
                    })}
                </div>
            </div>
        );
    }

    _renderDesktop() {
        return (
            <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 20, height: '100%'}}>
                <h1 className={"title"}>Join My Water Advisor</h1>
                <div style={{marginTop: 20}}>
                    <button className="signUpCell" style={{padding: 16, alignItems: 'center', display: 'flex', height: 'auto'}}
                            onClick={this.goToRegisterPhone.bind(this)}>
                        <div style={{flexDirection: 'column', display: 'flex'}}>
                            <p style={{padding: 0, margin: 0, textAlign: 'left'}}
                               className="Sign-up-with-cell-phone-number">Sign up with
                                cell phone number</p>
                            <p style={{padding: 0, margin: 0, marginTop: 10, textAlign: 'left'}}
                               className="Phone-number-registered-with-your-utility-or-servi">Phone number registered
                                with your utility or service provider</p>
                        </div>
                        <div style={{flex: 1}}>
                            <img src={images.icArrowRightBlue} style={{
                                alignItems: 'flex-end',
                                display: 'flex',
                                float: 'right'
                            }}/>
                        </div>
                    </button>
                    <button className="signUpCell"
                            style={{marginTop: 10, padding: 16, alignItems: 'center', display: 'flex', height: 'auto'}}
                            onClick={this.goToRegisterAccount.bind(this)}>
                        <div style={{flexDirection: 'column', display: 'flex'}}>
                            <p style={{padding: 0, margin: 0, textAlign: 'left'}}
                               className="Sign-up-with-cell-phone-number">Sign up with
                                account number</p>
                            <p style={{padding: 0, margin: 0, marginTop: 10, textAlign: 'left'}}
                               className="Phone-number-registered-with-your-utility-or-servi">Account number as it
                                appears on the bill</p>
                        </div>
                        <div style={{flex: 1}}>
                            <img src={images.icArrowRightBlue} style={{
                                alignItems: 'flex-end',
                                display: 'flex',
                                float: 'right'
                            }}/>
                        </div>
                    </button>
                </div>
                <p style={{marginTop: 20, justifyContent: 'center'}} className="Need-help-Call-your-utility-office">Need
                    help? Call your utility office</p>
                <p style={{marginTop: 4, justifyContent: 'center'}}
                   className="Your-water-utility-name-can-be-found-in-the-monthl">Your water utility name can be found
                    in the monthly water bill</p>
                <input placeholder="Search Utility by Name" type="text" className="Rectangle"
                       style={{paddingLeft: 10, paddingRight: 10, width: 300}}
                       onChange={this.onChangeUtilityName.bind(this)}>
                </input>
                <div style={{marginTop: 10, width: 320}}>
                    {this.state.utilities.map(item => {
                        return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <p style={{color: globals.COLOR.DUSK, fontSize: 16}}>{item.description}</p>
                            <p style={{
                                color: globals.COLOR.AZURE,
                                fontSize: 15,
                                marginLeft: 10
                            }}>{item.phoneNumber}</p>
                        </div>
                    })}
                </div>
                <div style={{height: 100}} />
            </div>
        );
    }

    goToRegisterAccount = () => {
        this.props.history.push('/register/account');
    };

    goToRegisterPhone = () => {
        this.props.history.push('/register/phone');
    };

    onChangeUtilityName = (event) => {
        //this.state.searchTerm = value;
        const term = event.target.value;
        if (term.length >= 2) {
            api.searchUtilities(term, (res, err) => {
                if (!err) {
                    this.setState({utilities: res});
                }
            })
        } else if (term.length === 0) {
            this.setState({utilities: []});
        }
    };
}
