import React from 'react';
import './SettingsChangePassword.css';
import images from '../../images';
import * as api from '../../api';
import Input from 'react-validation/build/input';
import Form from 'react-validation/build/form';
import Button from 'react-validation/build/button';
import * as validators from '../../validators';
import { Mobile } from '../../App';
import DataManager from "../../managers/DataManager";
import AppPopup from "../../components/AppPopup";
import Popup from "reactjs-popup";

class SettingsChangePhone extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            phone: '',
            errorMessage: null
        };
    }

    componentDidMount() {
        if (DataManager?.sharedInstance?.user?.phoneNumber !== null) {
          this.setState({
            phone: DataManager?.sharedInstance?.user?.phoneNumber?.AdditionalPhoneNumber ?? DataManager?.sharedInstance?.user?.phoneNumber?.phoneNumber,
          });
        }
      }

    render() {
        return (
            <div>
                <Mobile>
                    {matches => {
                        if (matches) return this._renderMobile();
                        else return this._renderDesktop();
                    }}
                </Mobile>
            </div>
        );
    }

    _renderMobile() {
        return (
            <div
                style={{
                    padding: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <h1 className={'title'}>
                    Enter a cell phone number to receive SMS alerts
                </h1>
                <Form method={'post'} onSubmit={this.onSubmit.bind(this)}>
                    <div
                        style={{
                            marginTop: 20,
                            display: 'flex',
                            alignItems: 'center',
                            marginLeft: 10
                        }}
                        className="rectangleMob"
                    >
                        <h3 className="country" style={{ marginLeft: 8 }}>+1(US)</h3>
                        <Input
                            validations={[validators.required]}
                            placeholder=""
                            type="tel"
                            name="phone"
                            onChange={event =>
                                this.setState({ phone: event.target.value })
                            }
                            value={this.state.phone}
                            className="phoneInput country"
                            style={{
                                marginLeft: 10,
                                marginRight: 10,
                                paddingLeft: 10,
                                paddingRight: 10,
                                display: 'flex',
                                border: 0,
                                backgroundColor: 'transparent'
                            }}
                        />
                    </div>
                    <div
                        style={{
                            marginTop: 10,
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'row',
                            backgroundColor: 'transparent'
                        }}
                    >
                        <Button
                            type="submit"
                            style={{
                                border: 0,
                                backgroundColor: 'transparent'
                            }}
                        >
                            <img
                                src={images.btnSaveBlue}
                                style={{ width: 150 }}
                                className="btnSavePassword"
                            />
                        </Button>
                        <button
                            style={{
                                border: 0,
                                backgroundColor: 'transparent'
                            }}
                        >
                            <img
                                src={images.btnCancelWhite}
                                style={{ width: 150 }}
                                className="btnSavePassword"
                                onClick={this.closePopup.bind(this)}
                            />
                        </button>
                    </div>
                </Form>
                <Popup
                    contentStyle={{borderRadius: 5}}
                    open={this.state.errorMessage}
                    onClose={this.closeErrorPopup.bind(this)}
                    lockScroll={true}
                >
                    <AppPopup onClose={this.closeErrorPopup.bind(this)} message={this.state.errorMessage}/>
                </Popup>
            </div>
        );
    }

    _renderDesktop() {
        return (
            <div
                style={{
                    height: 200,
                    padding: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <h1 className={'title'}>
                    Enter a cell phone number to receive SMS alerts
                </h1>
                <Form method={'post'} onSubmit={this.onSubmit.bind(this)}>
                    <div
                        style={{
                            marginTop: 14,
                            display: 'flex',
                            alignItems: 'center'
                        }}
                        className="rectangle"
                    >
                        <h3
                            className="country"
                            style={{ float: 'left', marginLeft: 8 }}
                        >
                            +1 US
                        </h3>
                        <Input
                            validations={[validators.required]}
                            placeholder=""
                            type="tel"
                            name="phone"
                            onChange={event =>
                                this.setState({ phone: event.target.value })
                            }
                            value={this.state.phone}
                            className="phoneInput country"
                            style={{
                                paddingLeft: 10,
                                paddingRight: 10,
                                display: 'flex',
                                border: 0,
                                backgroundColor: 'transparent'
                            }}
                        />
                    </div>
                    <div
                        style={{
                            marginTop: 4,
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'row',
                            backgroundColor: 'transparent'
                        }}
                    >
                        <Button
                            type="submit"
                            style={{
                                border: 0,
                                backgroundColor: 'transparent'
                            }}
                        >
                            <img
                                src={images.btnSaveBlue}
                                className="btnSavePassword"
                            />
                        </Button>
                        <button
                            style={{
                                border: 0,
                                backgroundColor: 'transparent'
                            }}
                        >
                            <img
                                src={images.btnCancelWhite}
                                className="btnSavePassword"
                                onClick={this.closePopup.bind(this)}
                            />
                        </button>
                    </div>
                </Form>
                <Popup
                    contentStyle={{borderRadius: 5}}
                    open={this.state.errorMessage}
                    onClose={this.closeErrorPopup.bind(this)}
                    lockScroll={true}
                >
                    <AppPopup onClose={this.closeErrorPopup.bind(this)} message={this.state.errorMessage}/>
                </Popup>
            </div>
        );
    }

    closeErrorPopup() {
        this.setState({errorMessage: null});
    }

    onSubmit = event => {
        event.preventDefault();

        const { phone } = this.state;

        api.updatePhoneNumber('1', phone, 'en', (response, error) => {
            if (error) {
                this.setState({errorMessage: 'Unknown error has occurred.'});
            } else {
                if (response.error) {
                    this.setState({errorMessage: response.error});
                } else {
                    console.log(response);

                    api.getMe((res, err) => {
                        if (err) {
                            console.log('Unknown error has occurred.');
                        } else {
                            if (res.error) {
                                console.log(res.error);
                            } else {
                                DataManager.sharedInstance.user = res;
                            }
                        }

                        this.closePopup();
                    });

                    //this.closePopup();
                }
            }
        });
    };

    closePopup = () => {
        this.props.onClose();
    };
}

export default (SettingsChangePhone)