import React from 'react';
import images from "../../images";
import '../../App.css';
import './RegisterPhone.css';
import * as api from "../../api";
import Input from 'react-validation/build/input';
import Form from 'react-validation/build/form';
import Button from 'react-validation/build/button';
import * as validators from '../../validators';
import DataManager from "../../managers/DataManager";
import {Desktop, Mobile} from "../../App";
import AppPopup from "../../components/AppPopup";
import Popup from "reactjs-popup";

class RegisterPhone extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            phone: '',
            errorMessage: null
        }
    }

    render() {
        return (
            <div>
                <Mobile>
                    {matches => {
                        if (matches)
                            return this._renderMobile();
                        else
                            return this._renderDesktop();
                    }}
                </Mobile>
            </div>
        );
    }

    _renderMobile() {
        return (
            <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginRight: 20,
                marginLeft: 20,
                marginTop: -36
            }}>
                <h1 className={"title"} style={{fontSize: 17}}>Join My Water Advisor</h1>
                <h3 className="enterPrimaryMobile" style={{margin: 20, textAlign: 'center'}}>Enter the primary cell
                    phone number registered with your utility or service
                    provider.</h3>
                <Form method={'post'} onSubmit={this.onSubmit.bind(this)}>
                    <div style={{margin: 20, display: 'flex', alignItems: 'center'}} className="rectangleMobile">
                        <h3 className="country" style={{float: 'left', marginLeft: 8}}>+1 US</h3>
                        <Input validations={[validators.required]} placeholder="" type="tel" name="phone"
                               onChange={(event) => this.setState({phone: event.target.value})}
                               value={this.state.phone}
                               className="phoneInput country"
                               style={{
                                   paddingLeft: 10,
                                   paddingRight: 10,
                                   display: 'flex',
                                   border: 0,
                                   backgroundColor: 'transparent'
                               }}>
                        </Input>
                    </div>
                    <div style={{marginTop: 10, display: 'flex', justifyContent: 'center'}}>
                        <Button type="submit" style={{border: 0, backgroundColor: 'transparent'}}>
                            <img src={images.btnGetCode} className="btn_get_code"/>
                        </Button>
                    </div>
                </Form>
                <Popup
                    contentStyle={{borderRadius: 5}}
                    open={this.state.errorMessage}
                    onClose={this.closeErrorPopup.bind(this)}
                    lockScroll={true}
                >
                    <AppPopup onClose={this.closeErrorPopup.bind(this)} message={this.state.errorMessage}/>
                </Popup>
            </div>
        );
    }


    _renderDesktop() {
        return (
            <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 20}}>
                <h1 className={"title"}>Join My Water Advisor</h1>
                <h3 className="enterPrimary">Enter the primary cell phone number registered with your utility or service
                    provider.</h3>
                <Form method={'post'} onSubmit={this.onSubmit.bind(this)}>
                    <div style={{marginTop: 20, display: 'flex', alignItems: 'center'}} className="rectangle">
                        <h3 className="country" style={{float: 'left', marginLeft: 8}}>+1 US</h3>
                        <Input validations={[validators.required]} placeholder="" type="tel" name="phone"
                               onChange={(event) => this.setState({phone: event.target.value})}
                               value={this.state.phone}
                               className="phoneInput country"
                               style={{
                                   paddingLeft: 10,
                                   paddingRight: 10,
                                   display: 'flex',
                                   border: 0,
                                   backgroundColor: 'transparent'
                               }}>
                        </Input>
                    </div>
                    <div style={{marginTop: 20, display: 'flex', justifyContent: 'center'}}>
                        <Button type="submit" style={{border: 0, backgroundColor: 'transparent'}}>
                            <img src={images.btnGetCode} className="btn_get_code"/>
                        </Button>
                    </div>
                </Form>
                <Popup
                    contentStyle={{borderRadius: 5}}
                    open={this.state.errorMessage}
                    onClose={this.closeErrorPopup.bind(this)}
                    lockScroll={true}
                >
                    <AppPopup onClose={this.closeErrorPopup.bind(this)} message={this.state.errorMessage}/>
                </Popup>
            </div>
        );
    }

    closeErrorPopup() {
        this.setState({errorMessage: null});
    }

    onSubmit = (event) => {
        event.preventDefault();

        const {phone} = this.state;

        api.registerPhone('1', phone, 'en', (response, error) => {
            if (error) {
                this.setState({errorMessage: 'Unknown error has occurred.'});
            } else {
                if (response.error) {
                    if (response.code === 10030)
                        this.props.history.push('/register/phone/not_found');
                    else
                        this.setState({errorMessage: response.error});
                } else {
                    const sessionId = response.sessionId;
                    DataManager.sharedInstance.sessionId = sessionId;
                    this.props.history.push(`/register/code/${this.state.phone}`);
                }
            }
        });
    }
}

export default (RegisterPhone)