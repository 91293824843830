/*
 * genady.mironenko@aradtec.com / 1qazxsw2
 * */

import moment from 'moment';
import { IntlProvider } from 'react-intl';
import * as api from '../api';
import PersistenceManager from './PersistenceManager';
import globals from '../globals';

export default class DataManager {
    static sharedInstance =
        this.sharedInstance == null ? new DataManager() : this.sharedInstance;

    constructor() {
        const locale = IntlProvider.locale;
        moment.locale(locale);

        this.meters = [];
        this.units = 'gallons';
        this.user = { firstName: '', lastName: '', accountNumber: '', phoneNumber: '' };
        this.vacation = null;
        this.muni = null;
        this.showAlertBadge = false;
        this.sessionId = '';
        this.isAuthenticated = false;
        this.clearedAlerts = [];
    }

    reset() {
        api.setTokenValue(undefined);

        PersistenceManager.deleteItem(globals.STORAGE.TOKEN);

        this.meters = [];
        this.units = 'gallons';
        this.user = { firstName: '', lastName: '', accountNumber: '' };
        this.vacation = null;
        this.muni = null;
        this.showAlertBadge = false;
        this.sessionId = '';
        this.isAuthenticated = false;
    }
}
