import React from 'react';
import images from "../../images";
import './RegisterEnterCode.css';
import '../../App.css';
import Input from 'react-validation/build/input';
import Form from 'react-validation/build/form';
import Button from 'react-validation/build/button';
import * as validators from '../../validators';
import * as api from "../../api";
import DataManager from "../../managers/DataManager";
import {Desktop, Mobile} from "../../App";
import AppPopup from "../../components/AppPopup";
import Popup from "reactjs-popup";

class RegisterEnterCode extends React.Component {
    constructor(props) {
        super(props);

        const {phone} = props.match.params;

        this.state = {
            phone: phone,
            code: '',
            errorMessage: null
        }
    }

    render() {
        return (
            <div>
                <Mobile>
                    {matches => {
                        if (matches)
                            return this._renderMobile();
                        else
                            return this._renderDesktop();
                    }}
                </Mobile>
            </div>
        );
    }

    _renderMobile() {
        return (
            <div style={{
                flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: -36
            }}>
                <h2 className={"mainTitleMob"}>Join My Water Advisor</h2>
                <h4 className={"subTitleMob"}>Enter the code that was sent via SMS to number</h4>
                <Form method={"post"} onSubmit={this.onSubmit.bind(this)}>
                    <Input validators={[validators.required]} placeholder="Code" type="tel" name="code"
                           onChange={(event) => this.setState({code: event.target.value})}
                           className="codeInput rectangle" value={this.state.code}
                           style={{
                               paddingLeft: 10,
                               paddingRight: 10,
                               display: 'flex',
                               border: 0,
                               backgroundColor: 'transparent'
                           }}>
                    </Input>
                    <div style={{marginTop: 20, display: 'flex', justifyContent: 'center'}}>
                        <Button type="submit" style={{border: 0}}>
                            <img src={this.state.code === '' ? images.btnContinueGrey : images.btnContinue} className="btn_continue"/>
                        </Button>
                    </div>
                </Form>
                <Popup
                    contentStyle={{borderRadius: 5}}
                    open={this.state.errorMessage}
                    onClose={this.closeErrorPopup.bind(this)}
                    lockScroll={true}
                >
                    <AppPopup onClose={this.closeErrorPopup.bind(this)} message={this.state.errorMessage}/>
                </Popup>
            </div>
        );
    }

    _renderDesktop() {
        return (
            <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 20}}>
                <h1 className={"title"}>Join My Water Advisor</h1>
                <h3 className="enterCode">Enter the code that was sent via SMS to number</h3>
                <h3 className="phone" style={{display: 'flex', justifyContent: 'center'}}>{this.state.phone}</h3>
                <Form method={"post"} onSubmit={this.onSubmit.bind(this)}>
                    <div style={{marginTop: 20, display: 'flex', alignItems: 'center'}}>
                        <Input validators={[validators.required]} placeholder="Code" type="tel" name="code"
                               onChange={(event) => this.setState({code: event.target.value})}
                               className="codeInput rectangle" value={this.state.code}
                               style={{
                                   paddingLeft: 10,
                                   paddingRight: 10,
                                   display: 'flex',
                                   border: 0,
                                   backgroundColor: 'transparent'
                               }}>
                        </Input>
                    </div>
                    <div style={{marginTop: 20, display: 'flex', justifyContent: 'center'}}>
                        <Button type="submit" style={{border: 0}}>
                            <img src={this.state.code === '' ? images.btnContinueGrey : images.btnContinue} className="btn_continue"/>
                        </Button>
                    </div>
                </Form>
                <Popup
                    contentStyle={{borderRadius: 5}}
                    open={this.state.errorMessage}
                    onClose={this.closeErrorPopup.bind(this)}
                    lockScroll={true}
                >
                    <AppPopup onClose={this.closeErrorPopup.bind(this)} message={this.state.errorMessage}/>
                </Popup>
            </div>
        );
    }

    closeErrorPopup() {
        this.setState({errorMessage: null});
    }

    onSubmit = (event) => {
        event.preventDefault();

        if (this.state.code !== '') {
            const sessionId = DataManager.sharedInstance.sessionId;

            api.validateRegisterCode(sessionId, this.state.code, (response, error) => {
                //this.setState({isLoading: false});
                if (error) {
                    this.setState({errorMessage: 'Unknown error has occurred.'});
                } else {
                    if (response.error) {
                        this.setState({errorMessage: response.error});
                    } else {
                        //Success
                        this.props.history.push('/register/finish', {
                            sessionId: sessionId,
                            code: this.state.code
                        });
                    }
                }
            });
        }
    }
}

export default (RegisterEnterCode)