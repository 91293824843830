import React, { PureComponent } from "react";
import globals from "../globals";
import utils from "../utils";
import { Bar } from "react-chartjs-2";
import moment from "moment";
import DataManager from "../managers/DataManager";
import { Mobile, Mobile2, Desktop, Desktop2, Desktop3 } from "../App";

const Statistics = ({ data }) => {
  return (
    <Mobile>
      {(matches) => {
        if (matches)
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: -50,
                alignItems: "center",
                marginLeft: 20,
                marginRight: 20,
                marginBottom: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: 13,
                    color: globals.COLOR.COOL_GRAY,
                  }}
                >
                  Minimum
                </div>
                <div
                  style={{
                    fontSize: 17,
                    color: globals.COLOR.AZURE,
                  }}
                >
                  {data
                    ?.reduce((min, val) => (val < min ? val : min), data[0])
                    .toFixed(0)}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginLeft: 20,
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: 13,
                    color: globals.COLOR.COOL_GRAY,
                  }}
                >
                  Maximum
                </div>
                <div
                  style={{
                    fontSize: 17,
                    color: globals.COLOR.AZURE,
                  }}
                >
                  {data
                    ?.reduce((max, val) => (val > max ? val : max), data[0])
                    .toFixed(0)}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginLeft: 20,
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: 13,
                    color: globals.COLOR.COOL_GRAY,
                  }}
                >
                  Average
                </div>
                <div
                  style={{
                    fontSize: 17,
                    color: globals.COLOR.AZURE,
                  }}
                >
                  {(data?.reduce((a, v) => a + v) / data?.length).toFixed(0)}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginLeft: 20,
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: 13,
                    color: globals.COLOR.COOL_GRAY,
                  }}
                >
                  Total
                </div>
                <div
                  style={{
                    fontSize: 17,
                    color: globals.COLOR.AZURE,
                  }}
                >
                  {data?.reduce((a, v) => a + v).toFixed(0)}
                </div>
              </div>
            </div>
          );
        else
          return (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: -50,
                marginLeft: 50,
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: 15,
                    color: globals.COLOR.COOL_GRAY,
                  }}
                >
                  Minimum
                </div>
                <div
                  style={{
                    fontSize: 20,
                    color: globals.COLOR.AZURE,
                  }}
                >
                  {data
                    ?.reduce((min, val) => (val < min ? val : min), data[0])
                    .toFixed(0)}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginLeft: 40,
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: 15,
                    color: globals.COLOR.COOL_GRAY,
                  }}
                >
                  Maximum
                </div>
                <div
                  style={{
                    fontSize: 20,
                    color: globals.COLOR.AZURE,
                  }}
                >
                  {data
                    ?.reduce((max, val) => (val > max ? val : max), data[0])
                    .toFixed(0)}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginLeft: 40,
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: 15,
                    color: globals.COLOR.COOL_GRAY,
                  }}
                >
                  Average
                </div>
                <div
                  style={{
                    fontSize: 20,
                    color: globals.COLOR.AZURE,
                  }}
                >
                  {(data?.reduce((a, v) => a + v) / data?.length).toFixed(0)}
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginLeft: 40,
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    fontSize: 15,
                    color: globals.COLOR.COOL_GRAY,
                  }}
                >
                  Total
                </div>
                <div
                  style={{
                    fontSize: 20,
                    color: globals.COLOR.AZURE,
                  }}
                >
                  {data?.reduce((a, v) => a + v).toFixed(0)}
                </div>
              </div>
            </div>
          );
      }}
    </Mobile>
  );
};

const Legend = ({ entries }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginLeft: 50,
        marginTop: -50,
      }}
    >
      {entries?.map((item, index) => {
        return (
          <div
            key={index.toString()}
            style={{
              display: "flex",
              marginLeft: 26,
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <div
              style={{
                width: 10,
                height: 10,
                backgroundColor: item?.color,
              }}
            />
            <div
              style={{
                marginLeft: 5,
                fontSize: 14,
                color: globals.COLOR.COOL_GRAY,
              }}
            >
              {item?.text}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default class MeterChart extends PureComponent {
  constructor(props) {
    super(props);

    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.options = {
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio: 2,
    };
  }

  // componentDidUpdate(prevProps, prevState) {
  //     console.log('componentDidUpdate prevProps', prevProps);
  //     console.log('componentDidUpdate this.props', this.props);
  //     if (prevProps.type !== this.props.type || prevProps.timeType !== this.props.timeType) {
  //         const {type, timeType, units, selectedMeter} = this.props;
  //         if (type === 0) {
  //             //My consumptions
  //             switch (timeType) {
  //                 case 0:
  //                     return this._renderMonthlyChart(units);
  //                 case 1:
  //                     return this._renderDailyChart(units);
  //                 case 2:
  //                     return this._renderHourlyChart(units);
  //             }
  //         } else if (type === 1) {
  //             if (selectedMeter) {
  //                 //Year
  //                 return this._renderYearChart(units);
  //             } else {
  //                 //Neighbor
  //                 return this._renderNeighborChart(units);
  //             }

  //         } else {
  //             //Year
  //             return this._renderYearChart(units);
  //         }
  //     }
  //   }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    const { type, timeType, units, selectedMeter } = this.props;
    if (type === 0) {
      //My consumptions
      switch (timeType) {
        case 0:
          return this._renderMonthlyChart(units, this.props.presentationMode);
        case 1:
          console.log("selectedMeter", selectedMeter);
          return this._renderDailyChart(units, selectedMeter);
        case 2:
          return this._renderHourlyChart(units);
      }
    } else if (type === 1) {
      if (selectedMeter) {
        //Year
        return this._renderYearChart(units);
      } else {
        //Neighbor
        return this._renderNeighborChart(units);
      }
    } else {
      //Year
      return this._renderYearChart(units);
    }
  }

  _renderYearChart(units) {
    const currentYear = utils.getYear(new Date());

    const allYearsData = this.props.yearsConsumptions;
    console.log("currentYear", currentYear);
    console.log("allYearsData", allYearsData);
    console.log("_renderYearChart props", this.props);
    const prev2YearData =
      allYearsData && allYearsData.length > 0
        ? allYearsData?.filter(
            (item) =>
              utils.getYear(item.consDate) ===
              utils.getYearsBefore(currentYear, 2)
          )
        : [];
    const prevYearData =
      allYearsData && allYearsData.length > 0
        ? allYearsData?.filter(
            (item) =>
              utils.getYear(item.consDate) ===
              utils.getYearsBefore(currentYear, 1)
          )
        : [];
    const currentYearData =
      allYearsData && allYearsData.length > 0
        ? allYearsData?.filter(
            (item) => utils.getYear(item.consDate) === currentYear
          )
        : [];

    let isEmpty = true;

    if (currentYearData && currentYearData.length > 0) {
      const pivot = Math.floor(currentYearData.length / 2);

      for (
        let i = 0, j = currentYearData.length - 1;
        i < pivot, j >= pivot;
        i++, j--
      ) {
        if (currentYearData[i].cons > 0 || currentYearData[j].cons > 0) {
          isEmpty = false;
          break;
        }
      }
    }

    const chartOptions = {
      legend: {
        display: false,
      },
      layout: {
        padding: {
          bottom: 100,
        },
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            // data for manipulation
            return Math.round(tooltipItem?.yLabel);
          },
        },
      },
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              min: 0,
              //max: 100000,
              callback: function (label, index, labels) {
                console.log(label);
                let isTooLong = false;
                if (label.toString().includes(".000")) {
                  label = label?.toFixed(4);
                  isTooLong = true;
                }
                return label === 0
                  ? `0 ${units.toLowerCase()}`
                  : Number(label) === label && label % 1 === 0
                  ? label / 1000 + "k"
                  : (label / 1000)?.toFixed(4) + "k";
              },
              fontColor: "#999ea8",
              labelOffset: 20,
            },
            gridLines: {
              color: "rgba(223,223,223, 0.4)",
            },
            stacked: false,
          },
        ],
        xAxes: [
          {
            barPercentage: 1,
            ticks: {
              beginAtZero: true,
              labelOffset: 0,
              callback: function (label, index, labels) {
                if (
                  currentYearData &&
                  currentYearData.length > 0 &&
                  index < currentYearData.length
                ) {
                  // const date = utils
                  //     .getMonthName(
                  //         currentYearData[index].consDate
                  //     )
                  //     .toUpperCase();
                  const date = moment(currentYearData[index]?.consDate)?.format(
                    "MMM"
                  );
                  console.log(`date ${date}`);
                  return date;
                } else if (
                  prevYearData &&
                  prevYearData.length > 0 &&
                  index < prevYearData.length
                ) {
                  // const date = utils
                  //     .getMonthName(
                  //         prevYearData[index].consDate
                  //     )
                  //     .toUpperCase();

                  const date = moment(prevYearData[index]?.consDate)?.format(
                    "MMM"
                  );
                  console.log(`date ${date}`);
                  return date;
                } else if (
                  prev2YearData &&
                  prev2YearData.length > 0 &&
                  index < prev2YearData.length
                ) {
                  const date = moment(prev2YearData[index]?.consDate)?.format(
                    "MMM"
                  );
                  console.log(`date ${date}`);
                  return date;
                }
              },
              fontColor: "#999ea8",
            },
            stacked: false,
            gridLines: {
              display: false,
            },
          },
        ],
      },
    };

    const labels = moment.monthsShort("-MMM-");

    const data = (canvas) => {
      const ctx = canvas.getContext("2d");
      const gradient1 = ctx.createLinearGradient(0, 0, 0, 200);
      gradient1.addColorStop(0, "#3eb3f9");
      gradient1.addColorStop(1, "#2b74fa");

      const gradient2 = ctx.createLinearGradient(0, 0, 0, 200);
      gradient2.addColorStop(0, "#1fede0");
      gradient2.addColorStop(1, "#18b6c0");

      const gradient3 = ctx.createLinearGradient(0, 0, 0, 200);
      gradient3.addColorStop(0, "#c6c6c6");
      gradient3.addColorStop(1, "#8d8d8d");
      return {
        labels: labels,
        datasets: [
          {
            label: `${moment().year()}`,
            backgroundColor: gradient1,
            pointColor: "#fff",
            pointStrokeColor: "#2b74fa",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "#2b74fa",
            data:
              currentYearData && currentYearData.length > 0
                ? currentYearData?.map((item) => item.cons)
                : [],
          },
          {
            label: `${moment().year() - 1}`,
            backgroundColor: gradient2,
            pointColor: "#fff",
            pointStrokeColor: "#2b74fa",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "#2b74fa",
            data:
              prevYearData && prevYearData.length > 0
                ? prevYearData?.map((item) => item.cons)
                : [],
          },
          {
            label: `${moment().year() - 2}`,
            backgroundColor: gradient3,
            pointColor: "#fff",
            pointStrokeColor: "#2b74fa",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "#2b74fa",
            data:
              prev2YearData && prev2YearData.length > 0
                ? prev2YearData?.map((item) => item.cons)
                : [],
          },
        ],
      };
    };

    return (
      <div style={{ marginTop: 20, marginBottom: 100 }}>
        <Bar data={data} options={chartOptions} width={1000} height={500} />
        <Legend
          entries={[
            { text: `${moment().year() - 2}`, color: "#acacac" },
            { text: `${moment().year() - 1}`, color: "#1cd3d2" },
            { text: `${moment().year()}`, color: "#3daefa" },
          ]}
        />
      </div>
    );
  }

  _renderNeighborChart(units) {
    const data1 = this.props.data;
    const data2 =
      this.props.neighborData && this.props.neighborData.length > 0
        ? this.props.neighborData?.map((item) => item?.avgCons)
        : [];

    console.log("_renderNeighborChart data1", data1);
    console.log("_renderNeighborChart data2", data2);

    let isEmpty = true;

    if (data1 && data1.length > 0) {
      const pivot = Math.floor(data1.length / 2);

      for (let i = 0, j = data1.length - 1; i < pivot, j >= pivot; i++, j--) {
        if (data1[i].cons > 0 || data1[j].cons > 0) {
          isEmpty = false;
          break;
        }
      }
    }

    const chartOptions = {
      legend: {
        display: false,
      },
      layout: {
        padding: {
          bottom: 100,
        },
      },
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            // data for manipulation
            return Math.round(tooltipItem?.yLabel);
          },
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: !isEmpty ? undefined : 60000,
              callback: function (label, index, labels) {
                return label === 0
                  ? `0 ${units.toLowerCase()}`
                  : label / 1000 + "k";
              },
              fontColor: "#999ea8",
              labelOffset: 20,
            },
            gridLines: {
              color: "rgba(223,223,223, 0.4)",
            },
            stacked: false,
          },
        ],
        xAxes: [
          {
            barPercentage: 1,
            ticks: {
              beginAtZero: true,
              callback: function (label, index, labels) {
                if (data1 && data1.length > 0 && data1[index]?.consDate) {
                  const date = utils
                    .getMonthName(data1[index]?.consDate)
                    .toUpperCase();
                  return date;
                }
              },
              fontColor: "#999ea8",
            },
            gridLines: {
              display: false,
            },
            stacked: false,
          },
        ],
      },
    };

    //let labels = moment.monthsShort('-MMM-');
    let labels = [];
    const startMonth = utils.getMonthDigit(data1[0]?.consDate);
    const endMonth = utils.getMonthDigit(data1[data1.length - 1]?.consDate);
    console.log("DATA!!!!!!!!!!!!!!!!!!!!!", data1);
    for (
      let i = 0;
      i <
      utils.getMonthDiff(data1[0]?.consDate, data1[data1.length - 1]?.consDate);
      i++
    ) {
      labels.push(utils.getMonthName(data1[i]?.consDate));
    }

    // if (startMonth && endMonth) {
    //     labels = labels.slice(startMonth-1, endMonth);
    // }

    const data = (canvas) => {
      const ctx = canvas.getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, 0, 200);
      gradient.addColorStop(0, "#3eb3f9");
      gradient.addColorStop(1, "#2b74fa");

      return {
        labels: labels,
        datasets: [
          {
            label: "My usage",
            backgroundColor: gradient,
            pointColor: "#fff",
            pointStrokeColor: "#2b74fa",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "#2b74fa",
            data:
              data1 && data1.length > 0
                ? data1?.map((item) => (item?.cons ? item.cons : "No Data"))
                : [],
          },
          {
            label: "Household average usage",
            backgroundColor: "#d9d9d9",
            pointColor: "#fff",
            pointStrokeColor: "#2b74fa",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "#2b74fa",
            data: data2,
          },
        ],
      };
    };

    return (
      <div style={{ marginTop: 20, marginBottom: 100 }}>
        <Bar data={data} options={chartOptions} width={1000} height={500} />
        <Legend
          entries={[
            {
              text: "My usage",
              color: globals.COLOR.DODGER_BLUE_2,
            },
            {
              text: "Household average usage",
              color: globals.COLOR.WHITE_THREE,
            },
          ]}
        />
      </div>
    );
  }

  _renderHourlyChart(units) {
    const chartData = this.props.data;
    let isEmpty = true;

    if (chartData && chartData.length > 0) {
      const pivot = Math.floor(chartData.length / 2);

      for (
        let i = 0, j = chartData.length - 1;
        i < pivot, j >= pivot;
        i++, j--
      ) {
        if (chartData[i].cons > 0 || chartData[j].cons > 0) {
          isEmpty = false;
          break;
        }
      }
    }

    const chartOptions = {
      legend: {
        display: false,
      },
      layout: {
        padding: {
          bottom: 100,
        },
      },
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            // data for manipulation
            return Math.round(tooltipItem.yLabel);
          },
        },
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: !isEmpty ? undefined : 160,
              callback: function (label, index, labels) {
                return label === 0
                  ? `0 ${units.toLowerCase()}`
                  : Number.isInteger(label)
                  ? label
                  : label?.toFixed(1);
              },
              fontColor: "#999ea8",
              labelOffset: 20,
            },
            gridLines: {
              color: "rgba(223,223,223, 0.4)",
            },
          },
        ],
        xAxes: [
          {
            barPercentage: 0.4,
            labelOffset: 0,
            ticks: {
              beginAtZero: true,
              callback: function (label, index, labels) {
                if (chartData && chartData.length > 0) {
                  const hour = utils
                    .getHour(chartData[index]?.dateTime)
                    .toUpperCase();
                  if (hour === "12") {
                    return "12PM";
                  } else if (hour === "00") {
                    return "12AM";
                  } else {
                    return hour % 12;
                  }
                }
              },
              fontColor: "#999ea8",
            },
            stacked: false,
            gridLines: {
              display: false,
            },
          },
        ],
      },
    };

    let labels = Array(24);
    for (let i = 0; i < 24; i++) labels[i] = i;

    const data = (canvas) => {
      const ctx = canvas.getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, 0, 200);
      gradient.addColorStop(0, "#3eb3f9");
      gradient.addColorStop(1, "#2b74fa");

      return {
        labels: labels,
        datasets: [
          {
            backgroundColor: gradient,
            pointColor: "#fff",
            pointStrokeColor: "#2b74fa",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "#2b74fa",
            data:
              chartData && chartData.length > 0
                ? chartData?.map((item) => item?.cons && item?.cons?.toFixed(2))
                : [],
          },
        ],
      };
    };

    const chartShape = this.props.chartShape;
    const sum =
      chartData && chartData.length > 0
        ? chartData?.reduce(function (prev, cur) {
            return prev + cur.cons;
          }, 0)
        : 0;

    return chartShape === "bar" ? (
      <div style={{ marginTop: 20, marginBottom: 100 }}>
        <Bar data={data} options={chartOptions} width={1000} height={500} />
        {chartData && chartData.length > 0 && (
          <Statistics
            data={chartData?.map((item) => (item?.cons ? item?.cons : 0))}
          />
        )}
      </div>
    ) : (
      <ul style={{ marginBottom: 100, marginRight: 40 }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              padding: 8,
              backgroundColor: "#cdd3db",
            }}
          >
            <div
              style={{
                flex: 1,
                display: "flex",
                fontSize: 14,
                color: globals.COLOR.DUSK,
              }}
            >
              Data
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                fontSize: 14,
                color: globals.COLOR.DUSK,
              }}
            >
              Consumption
            </div>
          </div>
          {chartData &&
            chartData?.map((item) => {
              return (
                <div
                  style={{
                    backgroundColor: globals.COLOR.WHITE_THREE,
                    borderBottomColor: globals.COLOR.COOL_GRAY,
                    borderBottomWidth: 2,
                  }}
                >
                  <li
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                      padding: 8,
                      backgroundColor: "white",
                      marginBottom: 1,
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        color: globals.COLOR.COOL_GRAY,
                        fontSize: 14,
                      }}
                    >
                      {utils.toDisplayDateString(item?.dateTime, true)}
                    </div>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          width: 80,
                          flexDirection: "column",
                          fontSize: 14,
                          color: globals.COLOR.COOL_GRAY,
                        }}
                      >
                        {item?.cons?.toFixed(2)}
                      </div>
                    </div>
                  </li>
                </div>
              );
            })}
          <div style={{ marginTop: 10 }}>
            <div
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "space-between",
                padding: 8,
                backgroundColor: "white",
              }}
            >
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  color: globals.COLOR.COOL_GRAY,
                }}
              >
                Total
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  color: globals.COLOR.COOL_GRAY,
                }}
              ></div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  color: globals.COLOR.COOL_GRAY,
                }}
              >
                {sum?.toFixed(2)}
              </div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  color: globals.COLOR.COOL_GRAY,
                }}
              />
            </div>
          </div>
        </div>
      </ul>
    );
  }

  _renderDailyChart(units, selectedMeter) {
    let chartData = !Array.isArray(this.props.data) ? [] : this.props.data;
    let isEmpty = true;

    // if (typeof chartData === 'object') {
    //   chartData = [];
    //   chartData.push(this.props.data);
    // }

    console.log(
      "_renderDailyChart data",
      JSON.stringify(chartData),
      chartData && chartData.length > 0
    );

    if (chartData && chartData.length > 0) {
      //if (!utils.isToday(chartData[0].consDate)) {
      if (utils.isArrAscendingByConsDate(chartData)) {
        chartData.reverse();
      }

      chartData = this.props.data.filter((item, index) => index < 90);

      //if (utils.isToday(chartData[0].consDate)) {
      if (!utils.isArrAscendingByConsDate(chartData)) {
        chartData.reverse();
      }

      const pivot = Math.floor(chartData?.length / 2);

      for (
        let i = 0, j = chartData.length - 1;
        i < pivot, j >= pivot;
        i++, j--
      ) {
        if (chartData[i].cons > 0 || chartData[j].cons > 0) {
          isEmpty = false;
          break;
        }
      }
    }

    const chartOptions = {
      legend: {
        display: false,
      },
      layout: {
        padding: {
          bottom: 100,
        },
      },
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            // data for manipulation
            return Math.round(tooltipItem?.yLabel);
          },
        },
      },
      responsive: true,
      maintainAspectRatio: true,
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: !isEmpty ? undefined : 2000,
              callback: function (label, index, labels) {
                if (label < 1 && label !== 0) {
                  return label.toFixed(1);
                }

                return label === 0 ? `0 ${units.toLowerCase()}` : label;
              },
              fontColor: "#999ea8",
              labelOffset: 20,
            },
            gridLines: {
              color: "rgba(223,223,223, 0.4)",
            },
          },
        ],
        xAxes: [
          {
            barPercentage: 0.4,
            labelOffset: 0,
            ticks: {
              beginAtZero: true,
              callback: function (label, index, labels) {
                if (chartData && chartData.length > 0) {
                  if (chartData[index] && chartData[index]?.consDate) {
                    const date = utils
                      .getDayMonth(chartData[index]?.consDate)
                      .toUpperCase();
                    return date;
                  }
                }
              },
              fontSize: 10,
              fontColor: "#999ea8",
            },
            stacked: false,
            gridLines: {
              display: false,
            },
          },
        ],
      },
    };

    const labels =
      chartData && chartData.length > 0
        ? chartData?.map((item) => utils.getDayMonth(item?.consDate))
        : [];

    const chartShape = this.props.chartShape;
    const sum =
      chartData && chartData.length > 0
        ? chartData.reduce(function (prev, cur) {
            return prev + cur.cons;
          }, 0)
        : 0;

    const data = (canvas) => {
      const ctx = canvas.getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, 0, 200);
      gradient.addColorStop(0, "#3eb3f9");
      gradient.addColorStop(1, "#2b74fa");

      return {
        labels: labels,
        datasets: [
          {
            backgroundColor: gradient,
            pointColor: "#fff",
            pointStrokeColor: "#2b74fa",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "#2b74fa",
            data:
              chartData && chartData.length > 0
                ? chartData?.map((item) => item?.cons && item?.cons?.toFixed(2))
                : [],
          },
        ],
      };
    };

    return (
      <Desktop3>
        {(matches) => {
          if (matches)
            return (
              <div
                style={{
                  marginTop: 20,
                  marginBottom: 100,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Bar data={data} options={chartOptions} height={500} />
                {chartData && chartData.length > 0 && (
                  <Statistics
                    data={chartData?.map((item) =>
                      item?.cons ? item.cons : 0
                    )}
                  />
                )}
              </div>
            );
          else
            return chartShape === "bar" ? (
              <div style={{ marginTop: 20, marginBottom: 100 }}>
                <Bar
                  data={data}
                  options={chartOptions}
                  width={1000}
                  height={500}
                />
                {chartData && chartData.length > 0 && (
                  <Statistics
                    data={chartData?.map((item) =>
                      item?.cons ? item.cons : 0
                    )}
                  />
                )}
              </div>
            ) : (
              <ul style={{ marginBottom: 100, marginRight: 40 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                      padding: 8,
                      backgroundColor: "#cdd3db",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        fontSize: 14,
                        color: globals.COLOR.DUSK,
                      }}
                    >
                      Data
                    </div>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        fontSize: 14,
                        color: globals.COLOR.DUSK,
                      }}
                    >
                      Consumption
                    </div>
                    {selectedMeter && (
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          color: globals.COLOR.DUSK,
                          fontSize: 14,
                        }}
                      >
                        Alerts
                      </div>
                    )}
                  </div>
                  {chartData &&
                    chartData?.map((item) => {
                      return (
                        <div
                          key={JSON.stringify(item)}
                          style={{
                            backgroundColor: globals.COLOR.WHITE_THREE,
                            borderBottomColor: globals.COLOR.COOL_GRAY,
                            borderBottomWidth: 2,
                          }}
                        >
                          <li
                            style={{
                              flexDirection: "row",
                              display: "flex",
                              alignItems: "center",
                              padding: 8,
                              backgroundColor: "white",
                              marginBottom: 1,
                            }}
                          >
                            <div
                              style={{
                                flex: 1,
                                display: "flex",
                                color: globals.COLOR.COOL_GRAY,
                                fontSize: 14,
                              }}
                            >
                              {utils.toDisplayDateString(item?.consDate)}
                            </div>
                            <div
                              style={{
                                flex: 1,
                                display: "flex",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                  width: 80,
                                  fontSize: 14,
                                  color: globals.COLOR.COOL_GRAY,
                                }}
                              >
                                {item?.cons?.toFixed(2)}
                              </div>
                            </div>
                            {selectedMeter && (
                              <div
                                style={{
                                  flex: 1,
                                  fontSize: 14,
                                  display: "flex",
                                  color: globals.COLOR.COOL_GRAY,
                                }}
                              >
                                {item?.meterStatusDesc}
                              </div>
                            )}
                          </li>
                        </div>
                      );
                    })}
                  <div style={{ marginTop: 10 }}>
                    <div
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                        padding: 8,
                        backgroundColor: "white",
                      }}
                    >
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          color: globals.COLOR.COOL_GRAY,
                        }}
                      >
                        Total
                      </div>
                      <div
                        style={{
                          flex: 1,
                          display: "flex",
                          color: globals.COLOR.COOL_GRAY,
                        }}
                      >
                        {sum?.toFixed(2)}
                      </div>
                      {selectedMeter && (
                        <div
                          style={{
                            flex: 1,
                            display: "flex",
                            color: globals.COLOR.COOL_GRAY,
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </ul>
            );
        }}
      </Desktop3>
    );
  }

  _renderMonthlyChart(units, presentationMode) {
    let chartData = this.props.data.slice();
    const startDate = this.props.startDate;
    const endDate = this.props.endDate;
    let isEmpty = true;
    let startMonth = null;
    let endMonth = null;

    if (chartData && chartData.length > 0) {
      let startIndex = 0;
      let endIndex = chartData.length - 1;

      startMonth = utils.getMonthDigit(chartData[0]?.consDate);
      endMonth = utils.getMonthDigit(chartData[chartData.length - 1]?.consDate);

      chartData = chartData.slice(startIndex, endIndex + 1);
    }

    if (chartData && chartData.length > 0) {
      const pivot = Math.floor(chartData.length / 2);

      for (
        let i = 0, j = chartData.length - 1;
        i < pivot, j >= pivot;
        i++, j--
      ) {
        if (chartData[i].cons > 0 || chartData[j].cons > 0) {
          isEmpty = false;
          break;
        }
      }
    }

    console.log("chartData", this.props.data, presentationMode);

    let labelOffset = 45;
    if (this.state.width >= 1350) {
      labelOffset = 45;
    } else if (this.state.width >= 1170) {
      labelOffset = 43;
    } else if (this.state.width >= 970) {
      labelOffset = 37;
    } else if (this.state.width >= 770) {
      labelOffset = 32;
    }

    let chartOptions = {
      legend: {
        display: false,
      },
      layout: {
        padding: {
          bottom: 100,
        },
      },
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            // data for manipulation
            return Math.round(tooltipItem?.yLabel);
          },
        },
      },
      // return label === 0 ? `0 ${units.toLowerCase()}` : label;
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              max: !isEmpty ? undefined : 60000,
              callback: function (label, index, labels) {
                return utils.getChartYLabelThousands(label, units);
              },
              fontColor: "#999ea8",
              labelOffset: 20,
            },
            gridLines: {
              color: "rgba(223,223,223, 0.4)",
            },
          },
        ],
        xAxes: [
          {
            barPercentage: 0.4,
            ticks: {
              beginAtZero: true,
              // offset: true,
              // padding: 20,
              // 770 - 32
              // 970 - 37
              // 1170 - 43
              // 1350 - 45
              maxRotation: 0,

              // this.state.width * 0.033333
              // labelOffset: labelOffset,
              callback: function (label, index, labels) {
                if (chartData && chartData.length > 0) {
                  if (chartData[index] && chartData[index].consDate) {
                    if (presentationMode === "DateRange") {
                      const date = utils.getChartRangeDates(chartData[index]?.consDate);
                      // console.log(`date ${date}`);
                      return date;
                    } else {
                      const date = moment(chartData[index]?.consDate).format(
                        "MMM"
                      );
                      // console.log(`date ${date}`);
                      return date;
                    }
                  }
                }
              },
              fontColor: "#999ea8",
            },
            stacked: false,
            gridLines: {
              display: false,
            },
          },
        ],
      },
    };

    let labels = []; //moment.monthsShort("-MMM-");
    for (let i = 0; i < chartData.length; i++) {
      labels.push(moment(chartData[i]?.consDate).format("MMM"));
    }

    const data = (canvas) => {
      const ctx = canvas.getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, 0, 200);
      gradient.addColorStop(0, "#3eb3f9");
      gradient.addColorStop(1, "#2b74fa");

      return {
        labels: labels,
        datasets: [
          {
            backgroundColor: gradient,
            pointColor: "#fff",
            pointStrokeColor: "#2b74fa",
            pointHighlightFill: "#fff",
            pointHighlightStroke: "#2b74fa",
            data:
              chartData && chartData.length > 0
                ? chartData?.map((item) => item?.cons && item?.cons?.toFixed(2))
                : [],
          },
        ],
      };
    };

    const chartShape = this.props.chartShape;
    const sum =
      chartData && chartData.length > 0
        ? chartData?.reduce(function (prev, cur) {
            return prev + cur.cons;
          }, 0)
        : 0;

    return (
      <Desktop2>
        {(matches) => {
          if (matches)
            return (
              <div
                style={{
                  marginTop: 20,
                  marginBottom: 100,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Bar data={data} options={chartOptions} height={500} />
                {chartData && chartData.length > 0 && (
                  <Statistics
                    data={chartData?.map((item) =>
                      item?.cons ? item.cons : 0
                    )}
                  />
                )}
              </div>
            );
          else
            return chartShape === "bar" ? (
              <div style={{ marginTop: 20, marginBottom: 100 }}>
                <Bar
                  data={data}
                  options={chartOptions}
                  width={1000}
                  height={500}
                />
                {chartData && chartData.length > 0 && (
                  <Statistics
                    data={chartData?.map((item) =>
                      item?.cons ? item.cons : 0
                    )}
                  />
                )}
              </div>
            ) : (
              <ul style={{ marginBottom: 100, marginRight: 40 }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                      padding: 8,
                      backgroundColor: "#cdd3db",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        fontSize: 14,
                        color: globals.COLOR.DUSK,
                      }}
                    >
                      Data
                    </div>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                          fontSize: 14,
                          color: globals.COLOR.DUSK,
                        }}
                      >
                        Consumption
                      </div>
                    </div>
                  </div>
                  {chartData?.map((item) => {
                    return (
                      <div
                        style={{
                          backgroundColor: globals.COLOR.WHITE_THREE,
                          borderBottomColor: globals.COLOR.COOL_GRAY,
                          borderBottomWidth: 2,
                        }}
                      >
                        <li
                          style={{
                            flexDirection: "row",
                            display: "flex",
                            alignItems: "center",
                            padding: 8,
                            backgroundColor: "white",
                            marginBottom: 1,
                          }}
                        >
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                              color: globals.COLOR.COOL_GRAY,
                              fontSize: 14,
                            }}
                          >
                            {(presentationMode === "DateRange") ? utils.getChartRangeDatesTable(item?.consDate) : utils.toDisplayDateString(item?.consDate)}
                          </div>
                          <div
                            style={{
                              flex: 1,
                              display: "flex",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                width: 80,
                                fontSize: 14,
                                color: globals.COLOR.COOL_GRAY,
                              }}
                            >
                              {item?.cons ? item?.cons?.toFixed(2) : 0}
                            </div>
                          </div>
                        </li>
                      </div>
                    );
                  })}

                  <div
                    style={{
                      marginTop: 10,
                      flexDirection: "row",
                      display: "flex",
                      alignItems: "center",
                      padding: 8,
                      backgroundColor: "white",
                    }}
                  >
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        color: globals.COLOR.COOL_GRAY,
                      }}
                    >
                      Total
                    </div>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        color: globals.COLOR.COOL_GRAY,
                      }}
                    ></div>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        color: globals.COLOR.COOL_GRAY,
                      }}
                    >
                      {sum?.toFixed(2)}
                    </div>
                    <div
                      style={{
                        flex: 1,
                        display: "flex",
                        color: globals.COLOR.COOL_GRAY,
                      }}
                    />
                  </div>
                </div>
              </ul>
            );
        }}
      </Desktop2>
    );
  }
}
