import React from 'react';
import './Header.css';
import images from "../../images";
import globals from "../../globals";
import {Mobile} from "../../App";
import {Link} from "react-router-dom";

export default class Header extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Mobile>
                {matches => {
                    if (matches)
                        return this._renderMobile();
                    else
                        return this._renderDesktop();
                }}
            </Mobile>
        );
    }

    _renderDesktop() {
        return (
            <div className="header">
                <Link
                    to={'/home'}
                    onClick={() => this.props.additionalFunction && this.props.additionalFunction()}
                >
                    <div style={{justifyContent: 'center'}}>
                        <img className="logoMWAHeader" src={images.logo} width={50} height={50}/>
                    </div>
                </Link>
                <div style={{paddingLeft: 10}}>
                    <div className="headerTitleLabel" style={{margin: 0, padding: 0}}>My Water Advisor <div style={{display: 'inline', fontSize: 16, position: 'relative', top: -7}}>®</div></div>
                    <div className="headerSubtitleLabel" style={{margin: 0, marginTop: 4, padding: 0}}>Online
                        access to Your World of Water™</div>
                </div>
            </div>
        );
    }

    _renderMobile() {
        return (
            <div className="header headerMobile">
                <div style={{justifyContent: 'center'}}>
                    <img src={images.logo} width={50} height={50}/>
                </div>
                <div>
                    <div className="headerTitleLabel" style={{fontSize: 15, margin: 0, padding: 0}}>My Water Advisor <div style={{display: 'inline', fontSize: 10, position: 'relative', top: -4}}>®</div></div>
                    <div className="headerSubtitleLabel" style={{fontSize: 15, margin: 0, marginTop: 4, padding: 0}}>Online
                        access to Your World of Water™</div>
                </div>
            </div>
        );
    }
}
