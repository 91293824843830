import React from 'react';
import './SettingsChangePassword.css';
import images from '../../images';
import '../../App.css';
import * as validators from '../../validators';
import * as api from '../../api';
import Input from 'react-validation/build/input';
import Form from 'react-validation/build/form';
import Button from 'react-validation/build/button';
import globals from '../../globals';
import PersistenceManager from '../../managers/PersistenceManager';
import { Mobile } from '../../App';
import Popup from "reactjs-popup";
import AppPopup from "../../components/AppPopup";

class SettingsChangePassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            currentPass: '',
            pass: '',
            confirmPass: '',
            isSecurePass: true,
            isSecureNewPass: true,
            isSecureConfirmPass: true,
            screenOrientation: window.matchMedia("(orientation: portrait)").matches ? 'portrait' : 'landscape',
            errorMessage: null
        };
    }

    componentDidMount() {
        window.addEventListener('orientationchange', this.setScreenOrientation);
    }

    componentWillUnmount() {
        window.removeEventListener('orientationchange', this.setScreenOrientation);
    }

    setScreenOrientation = () => {
        if (window.matchMedia("(orientation: portrait)").matches) {
            console.log('orientation: portrait');
            this.setState({
                screenOrientation: 'portrait'
            });
        }

        if (window.matchMedia("(orientation: landscape)").matches) {
            console.log('orientation: landscape');
            this.setState({
                screenOrientation: 'landscape'
            });
        }
    };


    render() {
        return (
            <div>
                <Mobile>
                    {matches => {
                        if (matches) return this._renderMobile();
                        else return this._renderDesktop();
                    }}
                </Mobile>
            </div>
        );
    }

    _renderMobile() {
        return (
            <div
                style={{
                    padding: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: '1%'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <img src={images.icBigLock} />
                    <h1 className={'title'} style={{ marginLeft: 10 }}>
                        Change Password
                    </h1>
                </div>
                <Form method={'post'} onSubmit={this.onSubmit.bind(this)}>
                    <div>
                        <h1 className={'choosePassword choosePasswordMob'}>
                            YOUR CURRENT PASSWORD
                        </h1>
                        <div
                            style={{
                                flexDirection: 'row',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Input
                                validations={[validators.required]}
                                placeholder="Password"
                                className="rectangleMobile"
                                name="password"
                                value={this.state.currentPass}
                                onChange={event =>
                                    this.setState({
                                        currentPass: event.target.value
                                    })
                                }
                                type={
                                    this.state.isSecurePass
                                        ? 'password'
                                        : 'text'
                                }
                                style={{
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    display: 'flex',
                                    fontSize: 16
                                }}
                            />
                            <button
                                style={{
                                    float: 'right',
                                    marginLeft: -40,
                                    backgroundColor: 'transparent'
                                }}
                                onClick={event => this.toggleSecurePass(event)}
                            >
                                <img src={images.icOpenEyeGray} />
                            </button>
                        </div>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <h1 className={'choosePassword choosePasswordMob'}>
                            CHOOSE A PASSWORD YOU WISH TO USE
                        </h1>
                        <div
                            style={{
                                flexDirection: 'row',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Input
                                validations={[validators.required]}
                                placeholder="Password"
                                className="rectangleMobile"
                                name="password"
                                value={this.state.pass}
                                onChange={event =>
                                    this.setState({ pass: event.target.value })
                                }
                                type={
                                    this.state.isSecureNewPass
                                        ? 'password'
                                        : 'text'
                                }
                                style={{
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    display: 'flex',
                                    fontSize: 16,
                                    marginTop: '2%'
                                }}
                            />
                            <button
                                style={{
                                    float: 'right',
                                    marginLeft: -40,
                                    backgroundColor: 'transparent'
                                }}
                                onClick={event =>
                                    this.toggleSecureNewPass(event)
                                }
                            >
                                <img src={images.icOpenEyeGray} />
                            </button>
                        </div>
                    </div>
                    <div
                        className="changePassRequirements"
                        style={{ marginTop: '1%' }}
                    >
                        Must contain numbers and letters
                    </div>
                    <div
                        className="changePassRequirements"
                        style={{ marginTop: 2 }}
                    >
                        Must contain 8 characters or more
                    </div>
                    <div style={{ marginTop: '2%' }}>
                        <h1 className={'choosePassword choosePasswordMob'}>
                            TYPE THE PASSWORD AGAIN
                        </h1>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            <Input
                                validations={[validators.required]}
                                placeholder="Password"
                                type={
                                    this.state.isSecureConfirmPass
                                        ? 'password'
                                        : 'text'
                                }
                                className="rectangleMobile"
                                name="Confirm password"
                                onChange={event =>
                                    this.setState({
                                        confirmPass: event.target.value
                                    })
                                }
                                value={this.state.confirmPass}
                                style={{
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    display: 'flex',
                                    fontSize: 16
                                }}
                            />
                            <button
                                style={{
                                    float: 'right',
                                    marginLeft: -40,
                                    backgroundColor: 'transparent'
                                }}
                                onClick={event =>
                                    this.toggleSecureConfirmPass(event)
                                }
                            >
                                <img src={images.icOpenEyeGray} />
                            </button>
                        </div>
                    </div>
                    {this.state.screenOrientation === 'landscape' ?
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                position: 'absolute',
                                bottom: 10,
                                right: 0,
                                backgroundColor: 'transparent'
                            }}
                        >
                            <Button
                                type="submit"
                                style={{
                                    border: 0,
                                    backgroundColor: 'transparent'
                                }}
                            >
                                <img
                                    src={images.btnSaveBlue}
                                    className="btnSavePassword"
                                    style={{ width: 100 }}
                                />
                            </Button>
                            <button
                                style={{
                                    border: 0,
                                    backgroundColor: 'transparent'
                                }}
                            >
                                <img
                                    src={images.btnCancelWhite}
                                    style={{ width: 100 }}
                                    className="btnSavePassword"
                                    onClick={this.closePopup.bind(this)}
                                />
                            </button>
                        </div>
                        :
                        <div
                            style={{
                                marginTop: 10,
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'row',
                                backgroundColor: 'transparent'
                            }}
                        >
                            <Button
                                type="submit"
                                style={{
                                    border: 0,
                                    backgroundColor: 'transparent'
                                }}
                            >
                                <img
                                    src={images.btnSaveBlue}
                                    className="btnSavePassword"
                                    style={{width: 150}}
                                />
                            </Button>
                            <button
                                style={{
                                    border: 0,
                                    backgroundColor: 'transparent'
                                }}
                            >
                                <img
                                    src={images.btnCancelWhite}
                                    style={{width: 150}}
                                    className="btnSavePassword"
                                    onClick={this.closePopup.bind(this)}
                                />
                            </button>
                        </div>
                    }
                </Form>
                <Popup
                    contentStyle={{borderRadius: 5}}
                    open={this.state.errorMessage}
                    onClose={this.closeErrorPopup.bind(this)}
                    lockScroll={true}
                >
                    <AppPopup onClose={this.closeErrorPopup.bind(this)} message={this.state.errorMessage}/>
                </Popup>
            </div>
        );
    }

    _renderDesktop() {
        return (
            <div
                style={{
                    height: 500,
                    padding: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <img src={images.icBigLock} />
                    <h1 className={'title'} style={{ marginLeft: 10 }}>
                        Change Password
                    </h1>
                </div>
                <Form method={'post'} onSubmit={this.onSubmit.bind(this)}>
                    <div style={{ marginTop: 10 }}>
                        <h1 className={'choosePassword'}>
                            YOUR CURRENT PASSWORD
                        </h1>
                        <div
                            style={{
                                flexDirection: 'row',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Input
                                validations={[validators.required]}
                                placeholder="Password"
                                className="rectangle"
                                name="password"
                                value={this.state.currentPass}
                                onChange={event =>
                                    this.setState({
                                        currentPass: event.target.value
                                    })
                                }
                                type={
                                    this.state.isSecurePass
                                        ? 'password'
                                        : 'text'
                                }
                                style={{
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    display: 'flex',
                                    fontSize: 16
                                }}
                            />
                            <button
                                style={{
                                    float: 'right',
                                    marginLeft: -40,
                                    backgroundColor: 'transparent'
                                }}
                                onClick={event => this.toggleSecurePass(event)}
                            >
                                <img src={images.icOpenEyeGray} />
                            </button>
                        </div>
                    </div>
                    <div style={{ marginTop: 10, justifyContent: 'center' }}>
                        <h1 className={'choosePassword'}>
                            CHOOSE A PASSWORD YOU WISH TO USE
                        </h1>
                        <div
                            style={{
                                flexDirection: 'row',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <Input
                                validations={[validators.required]}
                                placeholder="Password"
                                className="rectangle"
                                name="password"
                                value={this.state.pass}
                                onChange={event =>
                                    this.setState({ pass: event.target.value })
                                }
                                type={
                                    this.state.isSecureNewPass
                                        ? 'password'
                                        : 'text'
                                }
                                style={{
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    display: 'flex',
                                    fontSize: 16
                                }}
                            />
                            <button
                                style={{
                                    float: 'right',
                                    marginLeft: -40,
                                    backgroundColor: 'transparent'
                                }}
                                onClick={event =>
                                    this.toggleSecureNewPass(event)
                                }
                            >
                                <img src={images.icOpenEyeGray} />
                            </button>
                        </div>
                    </div>
                    <div
                        className="changePassRequirements"
                        style={{ marginTop: 10 }}
                    >
                        Must contain numbers and letters
                    </div>
                    <div
                        className="changePassRequirements"
                        style={{ marginTop: 2 }}
                    >
                        Must contain 8 characters or more
                    </div>
                    <div style={{ marginTop: 20, justifyContent: 'center' }}>
                        <h1 className={'choosePassword'}>
                            TYPE THE PASSWORD AGAIN
                        </h1>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>
                            <Input
                                validations={[validators.required]}
                                placeholder="Password"
                                type={
                                    this.state.isSecureConfirmPass
                                        ? 'password'
                                        : 'text'
                                }
                                className="rectangle"
                                name="Confirm password"
                                onChange={event =>
                                    this.setState({
                                        confirmPass: event.target.value
                                    })
                                }
                                value={this.state.confirmPass}
                                style={{
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    display: 'flex',
                                    fontSize: 16
                                }}
                            />
                            <button
                                style={{
                                    float: 'right',
                                    marginLeft: -40,
                                    backgroundColor: 'transparent'
                                }}
                                onClick={event =>
                                    this.toggleSecureConfirmPass(event)
                                }
                            >
                                <img src={images.icOpenEyeGray} />
                            </button>
                        </div>
                    </div>
                    <div
                        style={{
                            marginTop: 10,
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'row',
                            backgroundColor: 'transparent'
                        }}
                    >
                        <Button
                            type="submit"
                            style={{
                                border: 0,
                                backgroundColor: 'transparent'
                            }}
                        >
                            <img
                                src={images.btnSaveBlue}
                                className="btnSavePassword"
                            />
                        </Button>
                        <button
                            style={{
                                border: 0,
                                backgroundColor: 'transparent'
                            }}
                        >
                            <img
                                src={images.btnCancelWhite}
                                className="btnSavePassword"
                                onClick={this.closePopup.bind(this)}
                            />
                        </button>
                    </div>
                </Form>
                <Popup
                    contentStyle={{borderRadius: 5}}
                    open={this.state.errorMessage}
                    onClose={this.closeErrorPopup.bind(this)}
                    lockScroll={true}
                >
                    <AppPopup onClose={this.closeErrorPopup.bind(this)} message={this.state.errorMessage}/>
                </Popup>
            </div>
        );
    }

    closeErrorPopup() {
        this.setState({errorMessage: null});
    }

    toggleSecurePass(event) {
        event.preventDefault();
        if (event.detail !== 0) {
            this.setState({isSecurePass: !this.state.isSecurePass});
        }
    }

    toggleSecureNewPass(event) {
        event.preventDefault();
        this.setState({ isSecureNewPass: !this.state.isSecureNewPass });
    }

    toggleSecureConfirmPass(event) {
        event.preventDefault();
        this.setState({ isSecureConfirmPass: !this.state.isSecureConfirmPass });
    }

    onSubmit = event => {
        event.preventDefault();

        if (this.state.pass !== this.state.confirmPass) {
            this.setState({errorMessage: 'Passwords do not match.'});
        } else {
            //const isCharsValid = /^\w+$/.test(pass);
            const pass = this.state.pass;
            const isCharsValid = /^(?=.*[a-zA-Z])(?=.*[0-9])/.test(pass);
            const isNumCharsValid = pass.length >= 8;

            if (isCharsValid && isNumCharsValid) {
                api.changePassword(
                    this.state.currentPass,
                    this.state.pass,
                    res => {
                        if (res.error) {
                            this.setState({errorMessage: res.error});
                        } else {
                            this.setState({errorMessage: 'Password changed successfully'});

                            this.closePopup();
                        }
                    }
                );
            } else {
                this.setState({errorMessage: 'Password must contain numbers and letters.\nPassword must contain 8 characters or more.'});
            }
        }
    };

    closePopup = () => {
        this.props.onClose();
    };
}

export default (SettingsChangePassword)