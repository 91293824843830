import React from 'react';
import * as api from '../../api';
import {Desktop, Mobile} from '../../App';
import './Alerts.css';
import utils from '../../utils';
import globals from '../../globals';
import images from '../../images';
import DataManager from '../../managers/DataManager';
import {ClipLoader} from "react-spinners";
import {ALERTS_INTERVAL} from "../../const";
import Popup from "reactjs-popup";
import AppPopup from "../../components/AppPopup";

/*
{
                    "meterCount": 37706,
                    "description": 'description',
                    "notificationType": null,
                    "alertTypeName": "Leak",
                    "alertTypeId": "23",
                    "alertTime": "2017-07-26T17:40:22",
                    "logId": "232199"
                    }
*/

class Alerts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            previousAlerts: [],
            currentItem: null,
            isLoading: false,
            description: DataManager.sharedInstance.muni && DataManager.sharedInstance.muni.description ? DataManager.sharedInstance.muni.description : '',
            phoneNumber: DataManager.sharedInstance.muni && DataManager.sharedInstance.muni.phoneNumber ? DataManager.sharedInstance.muni.phoneNumber : '',
            errorMessage: null,
            alert
        };
    }

    componentWillMount() {
        //Get municipal customer service info
        api.getMunicipalCustomerService((res, err) => {
            //this._checkFirstDataFetchCounter();
            if (err) {
                console.log('Unknown error has occurred.');
            } else {
                if (res.error) {
                    console.log(res.error);
                } else {
                    DataManager.sharedInstance.muni = res;
                    this.setState({description: DataManager.sharedInstance.muni.description, phoneNumber: DataManager.sharedInstance.muni.phoneNumber})
                }
            }
        });

        api.getAlerts((res, err) => {
            if (err) {
                this.setState({errorMessage: 'Unknown error has occurred.'});
            } else {
                if (res.error) {
                    this.setState({errorMessage: res.error});
                } else {
                    this.props.onAlerts(res.length);
                    this.setState({previousAlerts: res});
                }
            }
        });
        this.alertsTimers = setInterval(() => {
            api.getAlerts((res, err) => {
                if (err) {
                    // this.setState({errorMessage: 'Unknown error has occurred.'});
                } else {
                    if (res.error) {
                        console.log('setInterval error Alerts.js', res.error);
                        this.setState({errorMessage: res.error});
                    } else {
                        this.props.onAlerts(res.length);
                        this.setState({previousAlerts: res});
                    }
                }
            });
        }, ALERTS_INTERVAL);
    }

    getAlertDetails = (item) => {
        this.setState({alert: item});
        api.getAlertDetails(
          item.alertTypeId,
          item.logId,
          (res, err) => {
            if (err) {
                this.setState({errorMessage: 'Cannot load alert details.'});
            } else {
              if (res.error) {
                this.setState({errorMessage: 'Cannot load alert details.'});
              } else if (res.length > 0) {
                const icon = item.icon;
                res[0].icon = icon;
                this.setState({alert: res[0]});
              }
            }
          },
        );
      }

    componentWillUnmount() {
        clearInterval(this.alertsTimers);
    }

    render() {
        return (
            <div>
                <Mobile>
                    {matches => {
                        if (matches) return this._renderMobile();
                        else return this._renderDesktop();
                    }}
                </Mobile>
            </div>
        );
    }

    _renderNewAlert(item) {
        let icon = utils.getSmallIconForAlertType(item.alertTypeId);
        let bigIcon = utils.getLargeIconForAlertType(item.alertTypeId);

        item.icon = icon;

        return (
            <Mobile>
                {matches => {
                    if (matches)
                        return (
                            <button
                                onClick={() =>
                                    this.props.history.push({
                                        pathname: `/alerts/${item.logId}`,
                                        state: {alert: item}
                                    })
                                }
                                style={{
                                    flexDirection: 'column',
                                    display: 'flex',
                                    backgroundColor: globals.COLOR.BLUSH,
                                    paddingLeft: 20,
                                    marginTop: 2,
                                    paddingTop: 8
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                >
                                    <img
                                        src={icon}
                                        style={{
                                            width: 30,
                                            height: 30,
                                            resizeMode: 'contain'
                                        }}
                                    />
                                    <div style={{marginLeft: 16, flex: 3}}>
                                        <div className="alertTypeLabel">
                                            {item.alertTypeName}
                                        </div>
                                        <div className="alertDescription">{`${
                                            item.meterSn
                                            }, ${
                                            item.address
                                                ? item.address
                                                : globals.METER_DEFAULT_ADDRESS
                                            }`}</div>
                                        <div className="alertDate">
                                            {utils.toAlertDate(item.alertTime)}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        backgroundColor: globals.COLOR.ICE_BLUE,
                                        height: 1,
                                        width: '100%'
                                    }}
                                />
                            </button>
                        );
                    else
                        return (
                            <button
                                onClick={() => {
                                    this.getAlertDetails(item);
                                    this.setState({currentItem: item})
                                }
                                    
                                }
                                style={{
                                    flexDirection: 'column',
                                    display: 'flex',
                                    backgroundColor: globals.COLOR.BLUSH,
                                    paddingLeft: 20,
                                    marginTop: 2,
                                    paddingTop: 8
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center'
                                    }}
                                >
                                    <img
                                        src={icon}
                                        style={{
                                            width: 30,
                                            height: 30,
                                            resizeMode: 'contain'
                                        }}
                                    />
                                    <div style={{marginLeft: 16, flex: 3}}>
                                        <div className="alertTypeLabel">
                                            {item.alertTypeName}
                                        </div>
                                        <div className="alertDescription">{`${
                                            item.meterSn
                                            }, ${
                                            item.address
                                                ? item.address
                                                : globals.METER_DEFAULT_ADDRESS
                                            }`}</div>
                                        <div className="alertDate">
                                            {utils.toAlertDate(item.alertTime)}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        backgroundColor: globals.COLOR.ICE_BLUE,
                                        height: 1,
                                        width: '100%'
                                    }}
                                />
                            </button>
                        );
                }}
            </Mobile>
        );
    }

    _renderPreviousAlert(item) {
        let icon = utils.getSmallIconForAlertType(item.alertTypeId);
        let bigIcon = utils.getLargeIconForAlertType(item.alertTypeId);

        item.icon = bigIcon;

        return (
            <Mobile>
                {matches => {
                    if (matches) {
                        return (
                            <button
                                onClick={() =>
                                    this.props.history.push({
                                        pathname: `/alerts/${item.logId}`,
                                        state: {alert: item}
                                    })
                                }
                                style={{
                                    flexDirection: 'column',
                                    display: 'flex',
                                    backgroundColor: 'white',
                                    paddingLeft: 20,
                                    marginTop: 2,
                                    paddingTop: 8,
                                    paddingBottom: 0
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        width: '100%'
                                    }}
                                >
                                    {this.state.isLoading && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                bottom: 0,
                                                right: 0,
                                                left: 0,
                                                flex: 1,
                                                backgroundColor: 'black',
                                                opacity: 0.3
                                            }}
                                        />
                                    )}
                                    <img
                                        src={icon}
                                        style={{
                                            width: 30,
                                            height: 30,
                                            resizeMode: 'contain'
                                        }}
                                    />
                                    <div
                                        style={{
                                            marginLeft: 16,
                                            flex: 3,
                                            alignItems: 'flex-start',
                                            width: '100%'
                                        }}
                                    >
                                        <div className="alertTypeLabel">
                                            {item.alertTypeName}
                                        </div>
                                        <div className="alertDescription">{`${
                                            item.meterSn
                                            }, ${
                                            item.address
                                                ? item.address
                                                : globals.METER_DEFAULT_ADDRESS
                                            }`}</div>
                                        <div className="alertDate">
                                            {utils.toAlertDate(item.alertTime)}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        backgroundColor: globals.COLOR.ICE_BLUE,
                                        height: 1,
                                        width: '100%',
                                        display: 'flex'
                                    }}
                                />
                            </button>
                        );
                    } else {
                        return (
                            <button
                                onClick={() => {
                                    this.getAlertDetails(item);
                                    this.setState({currentItem: item})
                                }
                                    
                                }
                                style={{
                                    flexDirection: 'column',
                                    display: 'flex',
                                    backgroundColor: 'white',
                                    paddingLeft: 20,
                                    marginTop: 2,
                                    paddingTop: 8,
                                    paddingBottom: 0
                                }}
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        width: '100%'
                                    }}
                                >
                                    <img
                                        src={icon}
                                        style={{
                                            width: 30,
                                            height: 30,
                                            resizeMode: 'contain'
                                        }}
                                    />
                                    <div
                                        style={{
                                            marginLeft: 16,
                                            flex: 3,
                                            alignItems: 'flex-start',
                                            width: '100%'
                                        }}
                                    >
                                        <div className="alertTypeLabel">
                                            {item.alertTypeName}
                                        </div>
                                        <div className="alertDescription">{`${
                                            item.meterSn
                                            }, ${
                                            item.address
                                                ? item.address
                                                : globals.METER_DEFAULT_ADDRESS
                                            }`}</div>
                                        <div className="alertDate">
                                            {utils.toAlertDate(item.alertTime)}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        backgroundColor: globals.COLOR.ICE_BLUE,
                                        height: 1,
                                        width: '100%',
                                        display: 'flex'
                                    }}
                                />
                            </button>
                        );
                    }
                }}
            </Mobile>
        );
    }

    _renderDesktop() {
        return (
            <div
                style={{
                    display: 'flex',
                    margin: 60,
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    flex: 1,
                    width: '100%'
                }}
            >
                {this.state.isLoading && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            right: 0,
                            left: 0,
                            flex: 1,
                            backgroundColor: 'black',
                            opacity: 0.3
                        }}
                    />
                )}
                <div className="title">Real Time Alerts</div>
                <div
                    style={{
                        flexDirection: 'row',
                        marginTop: 20,
                        display: 'flex',
                        width: '80%'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            width: 400,
                            flexDirection: 'column'
                        }}
                    >
                        {/*<div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>*/}
                        {/*<h4 className="headerTitle">New</h4>*/}
                        {/*<div style={{backgroundColor: globals.COLOR.ICE_BLUE, height: 1, width: '100%'}}/>*/}
                        {/*{this.state.newAlerts.map(item => {*/}
                        {/*return this._renderNewAlert(item);*/}
                        {/*})}*/}
                        {/*</div>*/}
                        <div
                            style={{
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <h4 className="headerTitle">Alerts</h4>
                            <div
                                style={{
                                    backgroundColor: globals.COLOR.ICE_BLUE,
                                    height: 1,
                                    width: '100%'
                                }}
                            />
                            {this.state.previousAlerts.map(item => {
                                return this._renderPreviousAlert(item);
                            })}
                        </div>
                    </div>
                    {this.state.currentItem &&
                    this._renderAlertInfo(this.state.currentItem)}
                </div>
                {this.state.isLoading && <div style={{
                    position: 'absolute',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignSelf: 'center'
                }}>
                    <ClipLoader
                        style={{justifyContent: 'center', alignItems: 'center'}}
                        color={'white'}
                        loading={this.state.isLoading}
                    />
                </div>}
                <Popup
                    contentStyle={{borderRadius: 5}}
                    open={this.state.errorMessage !== null}
                    onClose={this.closePopup.bind(this)}
                    lockScroll={true}
                >
                    <AppPopup onClose={this.closePopup.bind(this)} message={this.state.errorMessage}/>
                </Popup>
            </div>
        );
    }

    closePopup() {
        this.setState({errorMessage: null});
    }

    _renderMobile() {
        return (
            <div
                style={{
                    display: 'flex',
                    marginTop: 40,
                    flexDirection: 'column',
                    width: '100%'
                }}
            >
                <div className="title" style={{alignSelf: 'center'}}>
                    Real Time Alerts
                </div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: 20,
                        marginRight: 20
                    }}
                >
                    {/*<div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>*/}
                    {/*<h4 className="headerTitle">New</h4>*/}
                    {/*<div style={{backgroundColor: globals.COLOR.ICE_BLUE, height: 1}}/>*/}
                    {/*{this.state.newAlerts.map(item => {*/}
                    {/*return this._renderNewAlert(item);*/}
                    {/*})}*/}
                    {/*</div>*/}
                    <div
                        style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <h4 className="headerTitle">Alerts</h4>
                        <div
                            style={{
                                backgroundColor: globals.COLOR.ICE_BLUE,
                                height: 1
                            }}
                        />
                        {this.state.previousAlerts.map(item => {
                            return this._renderPreviousAlert(item);
                        })}
                    </div>
                </div>
                <ClipLoader
                    style={{marginTop: 10}}
                    color={'white'}
                    loading={this.state.isLoading}
                />
                <Popup
                    contentStyle={{borderRadius: 5}}
                    open={this.state.errorMessage}
                    onClose={this.closePopup.bind(this)}
                    lockScroll={true}
                >
                    <AppPopup onClose={this.closePopup.bind(this)} message={this.state.errorMessage}/>
                </Popup>
            </div>
        );
    }

    _renderAlertInfo(item) {
        let icon = utils.getSmallIconForAlertType(item.alertTypeId);
        return (
            <div
                style={{
                    flex: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    marginLeft: 50,
                    position: 'fixed',
                    left: 500,
                    right: 240,
                    top: 200
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}
                    >
                        <img
                            src={icon}
                            style={{
                                width: 60,
                                height: 60,
                                resizeMode: 'contain'
                            }}
                        />
                        <div
                            className="alertInfoType"
                            style={{marginLeft: 10}}
                        >
                            {item.alertTypeName}
                        </div>
                    </div>
                    <div className="alertInfoDate" style={{marginLeft: 30}}>
                        {utils.toAlertDate(item.alertTime)}
                    </div>
                </div>
                <div className="alertInfoWaterMeter" style={{marginTop: 20}}>
                    Water Meter: {item.meterSn}
                </div>
                <div className="alertInfoAddress">
                    {item.address
                        ? item.address
                        : globals.METER_DEFAULT_ADDRESS}
                </div>
                <div
                    className="alertInfoText"
                    style={{
                        marginTop: 10,
                        width: 400
                    }}
                >
                    {this.state.alert.description ? this.state.alert.description : ''}
                </div>
                <div className="alertInfoText" style={{marginTop: 10}}>
                    Call us if you need any help
                </div>
                <div
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        display: 'flex'
                    }}
                >
                    <div className="alertInfoCall">{this.state.description}</div>
                    <div className="alertSep"/>
                    <div className="alertInfoCall">{this.state.phoneNumber}</div>
                </div>
                <button
                    onClick={() => this._confirmAlert(item)}
                    style={{
                        alignSelf: 'flex-start',
                        backgroundColor: 'transparent',
                        marginLeft: -28,
                    }}
                >
                    <img src={images.btnConfirm}/>
                </button>
                <Popup
                    contentStyle={{borderRadius: 5}}
                    open={this.state.errorMessage !== null}
                    onClose={this.closePopup.bind(this)}
                    lockScroll={true}
                >
                    <AppPopup onClose={this.closePopup.bind(this)} message={this.state.errorMessage}/>
                </Popup>
            </div>
        );
    }

    _confirmAlert(alertItem) {
        this.setState({isLoading: true});
        api.confirmAlert(alertItem.logId, (res, err) => {
            //this.setState({isLoading: false});
            if (err) {
                this.setState({isLoading: false});
                this.setState({errorMessage: 'Could not confirm alert'});
            } else {
                if (res.error) {
                    this.setState({isLoading: false});
                    this.setState({errorMessage: res.error});
                } else {
                    api.getAlerts((res, err) => {
                        if (err) {
                            this.setState({isLoading: false});
                            this.setState({errorMessage: 'Unknown error has occurred.'});
                        } else {
                            this.setState({isLoading: false});
                            if (res.error) {
                                this.setState({errorMessage: res.error});
                            } else {
                                console.log('previousAlerts', res);
                                this.setState({errorMessage: 'Alert confirmed successfully'});
                                this.setState({previousAlerts: res, currentItem: null});
                            }
                        }
                    });
                }
            }
        });
    }
}

export default (Alerts)